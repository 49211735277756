import React, { useState, FC, useEffect, forwardRef, useImperativeHandle } from 'react';
import CloseIcon from '@mui/icons-material/Close';
import Button from '../button';

interface Props {
    visible: boolean,
    onConfirm?: () => void,
    onCancel?: () => void,
    title: string,
    text: string,
    confirmText?: string,
    cancelText?: string,
    warning?: boolean,
    defaultStyle?: boolean
}

const Alert : FC <Props> = (props, ref) => {
    return (
        props.visible ?
            <div className='alert-wrapper d-flex align-items-center align-items-lg-start justify-content-center'>
                <div>
                    <div className='block'>
                        <div className="close-icon" onClick={() => props.onCancel && props.onCancel()}>
                            <CloseIcon/>
                        </div>
                        <span className='title'>
                            {props.title}
                        </span>
                        <span className='text'>
                            {props.text}
                        </span>
                        <div className='btn-container d-flex justify-content-between'>
                            {props.confirmText ?
                                <Button
                                    title={props.confirmText}
                                    defaultStyle={props.defaultStyle ? true : false}
                                    warningStyle={props.warning ? true : false}
                                    fullwidth
                                    onPress={() => props.onConfirm && props.onConfirm()}
                                />
                            : <div></div>}

                            {props.cancelText ?
                            <>
                                <div className='btn-spacing'/>
                                <Button
                                    title={props.cancelText}
                                    cancelStyle
                                    fullwidth
                                    onPress={() => props.onCancel && props.onCancel()}
                                />
                            </>
                            : <div></div>}
                        </div>
                    </div>
                </div>
            </div>
        : null
    );
}
export default Alert;
