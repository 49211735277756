import React, { FC } from 'react';
import I18n from '../../helpers/i18n';
import { Link, useLocation } from 'react-router-dom';
import Logo from '../../images/new-logo.png';
//menu icons
import CollectionsIcon from '@mui/icons-material/Collections';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import StackedBarChartIcon from '@mui/icons-material/StackedBarChart';
import SettingsIcon from '@mui/icons-material/Settings';
import HomeIcon from '@mui/icons-material/Home';
import CorporateFareIcon from '@mui/icons-material/CorporateFare';
import ScienceIcon from '@mui/icons-material/Science';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import TableChartIcon from '@mui/icons-material/TableChart';
import BarChartIcon from '@mui/icons-material/BarChart';
import EuroSymbolIcon from '@mui/icons-material/EuroSymbol';
import AccountBalanceIcon from '@mui/icons-material/AccountBalance';
import FactoryIcon from '@mui/icons-material/Factory';
import PeopleOutlineIcon from '@mui/icons-material/PeopleOutline';
import PublicIcon from '@mui/icons-material/Public';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import CategoryIcon from '@mui/icons-material/Category';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
interface Props {}

const SideBar: FC<Props> = (props) => {
    const location = useLocation();

    const isActiveSubPath = (path: string, subpath: string) => {
        var activePath = false;

        var pathArray = window.location.pathname.split('/');
        var detailPathMain = '/' + pathArray[pathArray.length - 2];

        let cleanPath = location.pathname;

        if (location.pathname.split('/').length === 4) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            detailPathMain = '/' + pathArray[pathArray.length - 3];
        }
        if (location.pathname.split('/').length === 5) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            detailPathMain = '/' + pathArray[pathArray.length - 4] + '/' + pathArray[pathArray.length - 3];
        }

        if (subpath == cleanPath || (pathArray.length > 2 && detailPathMain == subpath)) {
            activePath = true;
        }

        return activePath;
    };

    const isActiveMainPath = (path: string) => {
        var activePath = false;

        var pathArray = window.location.pathname.split('/');
        var subPathMain = '/' + pathArray[pathArray.length - 2];
        var detailPathMain = '/' + pathArray[pathArray.length - 3];
        var subDetailPathMain = '/' + pathArray[pathArray.length - 4];

        if (
            path == location.pathname ||
            (pathArray.length > 2 && subPathMain == path) ||
            (pathArray.length > 3 && detailPathMain == path) ||
            (pathArray.length > 4 && subDetailPathMain == path)
        ) {
            activePath = true;
        }

        return activePath;
    };

    const menuArray = [
        {
            name: I18n.t('NAV_HOME'),
            path: '/',
            icon: <HomeIcon />,
        },
        {
            name: I18n.t('NAV_ORDERS'),
            path: '/orders',
            icon: <CollectionsIcon />,
            submenu: [
                {
                    name: I18n.t('NAV_SUPPLIER_ORDERS'),
                    path: '/orders/suppliers',
                    icon: <Inventory2Icon />,
                },
                {
                    name: I18n.t('NAV_PURCHASE_ORDERS'),
                    path: '/orders/factories',
                    icon: <CorporateFareIcon />,
                },
            ],
        },
        {
            name: I18n.t('NAV_PRODUCTS'),
            path: '/products',
            icon: <SanitizerIcon />,
        },
        {
            name: I18n.t('NAV_SETTINGS'),
            path: '/settings',
            icon: <SettingsIcon />,
            submenu: [
                {
                    name: I18n.t('NAV_PRODUCT_GROUPS'),
                    path: '/settings/product-groups',
                    icon: <CategoryIcon />,
                },
                {
                    name: I18n.t('NAV_SUPPLIERS'),
                    path: '/settings/suppliers',
                    icon: <LocalShippingIcon />,
                },
                {
                    name: I18n.t('NAV_FACTORIES'),
                    path: '/settings/factories',
                    icon: <FactoryIcon />,
                },
                {
                    name: I18n.t('NAV_CONTACTS'),
                    path: '/settings/contacts',
                    icon: <PeopleOutlineIcon />,
                },
                {
                    name: I18n.t('NAV_COUNTRIES'),
                    path: '/settings/countries',
                    icon: <PublicIcon />,
                },
                {
                    name: I18n.t('NAV_CONFIGURATION'),
                    path: '/settings/configuration',
                    icon: <MoreHorizIcon />,
                },
            ],
        },
    ];

    const showSubmenu = (path: string) => {
        var pathArray = window.location.pathname.split('/');
        var submenuPath = pathArray[pathArray.length - 1];
        let cleanPath = location.pathname;

        if (location.pathname.split('/').length === 4) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            submenuPath = pathArray[pathArray.length - 2];
        }

        if (location.pathname.split('/').length === 5) {
            let normalized = location.pathname.split('/');
            normalized.pop();
            cleanPath = normalized.join('/');
            submenuPath = pathArray[pathArray.length - 3] + '/' + pathArray[pathArray.length - 2];
        }
        if (cleanPath == path || cleanPath == path + '/' + submenuPath) {
            return true;
        } else {
            return false;
        }
    };

    return (
        <div id="sidebar">
            <div className="logo">
                <img src={Logo} alt="logo DrLeenarts" />
            </div>
            <ul>
                {menuArray.length > 0 &&
                    menuArray.map((item: any, index: any) => {
                        return (
                            <li className={isActiveMainPath(item.path) ? 'active' : ''} key={index}>
                                <div className="d-flex align-items-center item">
                                    <Link to={{ pathname: item.path }}>
                                        <div className={isActiveMainPath(item.path) ? 'icon active' : 'icon'}>
                                            {item.icon}
                                        </div>
                                        {item.name}
                                    </Link>
                                </div>
                                {showSubmenu(item.path) && (
                                    <ul className="submenu">
                                        {item.submenu &&
                                            item.submenu.length > 0 &&
                                            item.submenu.map((subitem: any, index: any) => {
                                                return (
                                                    <li
                                                        className={
                                                            isActiveSubPath(item.path, subitem.path) ? 'active' : ''
                                                        }
                                                        key={index}
                                                    >
                                                        <div className="d-flex align-items-center">
                                                            <Link to={{ pathname: subitem.path }}>
                                                                <div
                                                                    className={
                                                                        isActiveSubPath(item.path, subitem.path)
                                                                            ? 'icon active'
                                                                            : 'icon'
                                                                    }
                                                                >
                                                                    {subitem.icon}
                                                                </div>
                                                                {subitem.name}
                                                            </Link>
                                                        </div>
                                                    </li>
                                                );
                                            })}
                                    </ul>
                                )}
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};
export default SideBar;
