import React, { useState, FC, useEffect, useRef } from 'react';
import I18n from '../../helpers/i18n';
import Input from '../../components/input';
import Button from '../../components/button';
import Logo from '../../images/new-logo.png';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import Snackbar from '../../components/snackbar';
interface Props {}

const Login: FC<Props> = (props) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [password, setPassword] = useState('');
    const [passwordError, setPasswordError] = useState(false);
    const [passwordErrorText, setPasswordErrorText] = useState('');

    const [showOtp, setShowOtp] = useState(false);
    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [otpErrorText, setOtpErrorText] = useState('');

    const snackBar = useRef();

    const checkFields = () => {
        setPasswordError(false);
        setPasswordErrorText('');
        setEmailError(false);
        setEmailErrorText('');
        setOtpError(false);
        setOtpErrorText('');

        DirectusManager.shared
            .login({
                email: email,
                password: password,
                ...(otp !== '' && { otp }),
            })
            .then((result) => {
                navigate('/');
            })
            .catch((error) => {
                if (error?.errors[0]?.extensions?.code === 'INVALID_PAYLOAD') {
                    setEmailError(true);
                    setEmailErrorText(I18n.t('FORM_INVALID_EMAIL'));
                } else if (error?.errors[0]?.extensions?.code === 'INVALID_CREDENTIALS') {
                    setEmailError(true);
                    setEmailErrorText(I18n.t('FORM_INVALID_USER'));
                } else if (error?.errors[0]?.extensions?.code === 'INVALID_OTP') {
                    if (showOtp) {
                        setOtpError(true);
                        setOtpErrorText(I18n.t('2FA_ERROR'));
                    } else {
                        setShowOtp(true);
                    }
                } else if (error.message !== 'Network Error') {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('LOGIN_UNKNOWN_ERROR'));
                } else {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('LOGIN_UNKNOWN_ERROR'));
                }
            });
    };

    const navigateForgotPassword = () => {
        navigate('/forgot-password');
    };

    return (
        <div>
            <section className='login d-flex justify-content-center align-items-center'>
                <div className='login-block text-align-center'>
                    <div className='logo mt-3 mb-5'>
                        <img src={Logo} alt='logo DrLeenarts' />
                    </div>
                    <Input
                        label={I18n.t('FORM_EMAIL')}
                        value={email}
                        error={emailError}
                        errorText={emailErrorText}
                        onChange={(val: any) => setEmail(val)}
                    />
                    {emailError && <span className='error-field'>{emailErrorText}</span>}
                    <div className='mt-3' />
                    <Input
                        label={I18n.t('FORM_PASSWORD')}
                        value={password}
                        error={passwordError}
                        errorText={passwordErrorText}
                        onChange={(val: any) => setPassword(val)}
                        secure
                    />
                    {showOtp && (
                        <>
                            <div className='mt-3' />
                            <Input
                                label={I18n.t('2FA_OTP')}
                                value={otp}
                                error={otpError}
                                errorText={otpErrorText}
                                onChange={(val: any) => setOtp(val)}
                                secure
                            />
                        </>
                    )}

                    {passwordError && <span className='error-field'>{passwordErrorText}</span>}
                    {otpError && <span className='error-field'>{otpErrorText}</span>}
                    <div className='forgot-password' onClick={() => navigateForgotPassword()}>
                        {I18n.t('FORGOT_PASSWORD_LOGIN')}
                    </div>
                    <Button defaultStyle fullwidth title={I18n.t('LOGIN')} onPress={() => checkFields()} />
                </div>
            </section>
            <Snackbar ref={snackBar} noSidebar />
        </div>
    );
};

export default Login;
