import React, { FC, useEffect, useState } from 'react';
import I18n from '../../../helpers/i18n';
import Button from '../../button';
import moment from 'moment';
import Select from '../../select';
import InputField from '../../input';
import DatePicker from '../../date-picker';
interface Props {
    title?: string;
    rows: any;
    save?: () => void;
    saveButtonText?: string;
    //if no size is specified, will use the fullwidth option
    midSize?: boolean;
    largeSize?: boolean;
    midLargeSize?: boolean;
    autoHeight?: boolean;
    loading?: boolean;
    hideMandatory?: boolean;
    disabledButton?: boolean;
    emailSendingLoader?: boolean;
}

const DetailTable: FC<Props> = (props) => {
    return (
        <div
            className={`table-wrapper ${props.midSize ? 'mid-size' : ''} ${props.largeSize ? 'large-size' : ''} ${
                props.midLargeSize ? 'mid-large-size' : ''
            } ${props.autoHeight ? 'auto-height' : ''}`}
        >
            {props.title && (
                <div className="table-header filled d-flex justify-content-between align-items-center">
                    <span className="title">{props.title}</span>
                </div>
            )}
            {!props.loading && (
                <>
                    <table className="table">
                        <thead></thead>
                        <tbody>
                            {props.rows.length > 0 &&
                                props.rows.map((item: any) => {
                                    if (item.condition !== null && item.condition !== undefined && !item.condition) {
                                        return null;
                                    } else if (item.title == undefined) {
                                        return <div className="empty-space"></div>;
                                    } else {
                                        return (
                                            <tr key={item.id}>
                                                <td className={`td-heading ${item.verAlignTop ? 'vAlignTop' : ''}`}>
                                                    {item.title}
                                                    {item.mandatory && <span className="mandatory-star">*</span>}
                                                    {item.error && (
                                                        <span className="error-field">{item.errorText}</span>
                                                    )}
                                                </td>
                                                {item.customComponent ? (
                                                    <td className="td-inner">{item.customComponent}</td>
                                                ) : item.input ? (
                                                    <td className="td-inner">
                                                        <InputField
                                                            disableActiveLabel
                                                            value={item.value}
                                                            onChange={(val) => item.onChange(val)}
                                                            disabled={item.disabled ? true : false}
                                                            error={item.error}
                                                            price={item.price ? true : false}
                                                            autocomplete={item.autocomplete}
                                                            secure={false}
                                                            email={false}
                                                        />
                                                    </td>
                                                ) : item.select ? (
                                                    <td className="td-inner">
                                                        <Select
                                                            useValue={item.useValue ?? false}
                                                            value={item.value}
                                                            defaultValue={item.value}
                                                            array={item.array}
                                                            onChange={(id: any) => item.onChange(id)}
                                                        />
                                                    </td>
                                                ) : item.datePicker ? (
                                                    <td className="td-inner datepicker-width">
                                                        <DatePicker
                                                            showSingleValue={
                                                                item.value !== false
                                                                    ? moment(item.value).format('DD-MM-YYYY')
                                                                    : ''
                                                            }
                                                            filterDateStart={item.value}
                                                            changedDateStart={(date: any) => item.onChange(date)}
                                                            minDate={new Date()}
                                                        />
                                                    </td>
                                                ) : (
                                                    <td className="td-inner text-only">{item.value}</td>
                                                )}
                                            </tr>
                                        );
                                    }
                                })}
                        </tbody>
                    </table>
                    {props.saveButtonText && props.save && (
                        <div className="d-flex justify-content-between align-items-center save-order-row">
                            {!props.hideMandatory ? (
                                <div className="mantadory-explanation">
                                    <span className="mandatory-star">*</span> {I18n.t('THESE_FIELDS_ARE_MANDATORY')}
                                </div>
                            ) : (
                                <div></div>
                            )}
                            <Button
                                defaultStyle
                                disabled={props.disabledButton ? true : false}
                                title={props.emailSendingLoader ? I18n.t('SENDING_EMAIL') : props.saveButtonText}
                                onPress={() => props.save && props.save()}
                            />
                        </div>
                    )}
                </>
            )}
        </div>
    );
};
export default DetailTable;
