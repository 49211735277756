import React, { FC, useState, useEffect } from 'react';
import I18n from '../../helpers/i18n';
import i18n from "i18next";
import LogoutIcon from '@mui/icons-material/Logout';
import {
    Link,
    useNavigate
} from "react-router-dom";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp';
import DirectusManager from '../../helpers/directusManager';
import ToolTip from '../../components/tooltip';
import LanguageIcon from '@mui/icons-material/Language';

interface Props {
    title?: string
}

const Header : FC<Props> = (props) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const [userName, setUserName] = useState('----');

    const doLogout = () => {
        DirectusManager.shared.logout().then(() => {
            navigate('/login');
        }).catch( error => {
            navigate('/login');
            console.log(error.message);
        });
    }

    const toggleLang = () => {
        if(i18n.language == 'nl'){
            i18n.changeLanguage('en');
        } else {
            i18n.changeLanguage('nl');
        }
        window.location.reload();
    }

    useEffect(() => {
        if(userName !== '----') {
            return;
        }
        setLoader(true);
        DirectusManager.shared.directus.users.me.read().then( user => {
            setUserName(user.first_name);
            setLoader(false);
        }).catch( error => {
            setLoader(false);
        });
    }, []);

    return (
        <div className="page-header d-flex justify-content-between align-items-center">
            <h1 className="h3 title">
                {props.title}
            </h1>
            {!loader &&
            <div className='d-flex align-items-center'>
                <ToolTip
                    text={I18n.t('SWITCH_LANG_TO')}
                    bottom
                >
                    <div className='lang-switch' onClick={() => toggleLang()}>
                        <div className='d-flex align-items-center'>
                            <LanguageIcon className='lang-icon'/> {I18n.t('THIS_LANG')}
                        </div>
                    </div>
                </ToolTip>
                <ToolTip
                    text={I18n.t('LOGOUT_HOVER')}
                    bottom
                >
                    <div className="d-flex align-items-center user-block" onClick={() => doLogout()}>
                        <span className="name">
                            {I18n.t('LOGGED_IN_AS')}: {userName}
                        </span>
                    </div>
                </ToolTip>
            </div>
            }
        </div>
    );
}
export default Header;