import React, { FC, useEffect, useState } from 'react';
import DirectusManager from '../../../../helpers/directusManager';
import I18n from '../../../../helpers/i18n';
import moment from 'moment';

interface Props {
    data: any
}

const StatusInfo : FC<Props> = (props) => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        var orderId = null;
        var filter: any = null;
        
        if(props.data.tube_order_id) {
            orderId = props.data.tube_order_id;
            filter = {order_id: {"_eq": props.data.tube_order_id}};
        }

        if(orderId === null) {
            return;
        }

        var availableStatus = DirectusManager.shared.directus.items('tubes_status');
        availableStatus.readMany({fields:['stube_id', 'status', 'date_created'], limit: -1, filter}).then( (statusHistory: any) => {
            if(statusHistory !== undefined && statusHistory.data !== undefined && statusHistory.data !== null) {
                let mapped = statusHistory.data.map((x: any) => {
                    return ({
                        id: x.stube_id,
                        status_text: I18n.t("STATUS_" + x.status.toUpperCase()),
                        date: x.date_created ? moment(x.date_created).format('DD-MM-YYYY HH:mm') : "-"
                    })
                });  
                mapped.sort((x: any, y: any) => x.date > y.date ? 1 : -1);
                setData(orderArray(mapped));
            }
        }).catch( error => {
            console.log(error.message);
        });
    }, [props.data]);

    const orderArray = (data: any) => {
        var orderedArray = [] as any;
        
        if(data.length > 0){
            orderedArray = data.sort((a: any, b: any) =>
            b.date.split('/').reverse().join().localeCompare(a.date.split('/').reverse().join())); 
        }
       
        return orderedArray;
    }

    return (
        <div className="table-wrapper detail">
            <div className="table-header filled d-flex justify-content-between align-items-center">
                <span className="title">
                    {I18n.t('STATUS_INFO')}
                </span>
            </div>
            <table className='table'>
                <thead></thead>
                <tbody>
                    {data.length > 0 && data.map((item: any, index: any) => {
                        return (
                            <tr key={index}>
                                <td>
                                    {item.status_text}
                                </td>
                                <td className='td-heading'>
                                    {item.date}
                                </td>
                            </tr>
                        )
                    })}
                </tbody>
            </table>
        </div>
    );
}
export default StatusInfo;