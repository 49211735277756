import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '../../../components/tables/order-detail';
import DirectusManager from '../../../helpers/directusManager';
import * as Sentry from '@sentry/react';

const TubeOrderDetail: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams<{ id: any }>();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        reloadOrder();
    }, []);

    const reloadOrder = () => {
        let orders = DirectusManager.shared.directus.items('tubes_order');
        orders
            .readOne(params.id, { fields: ['*.*.*'] })
            .then((order) => {
                setData(order);
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });
    };

    const confirmDelete = (id: number) => {
        var tubesOrders = DirectusManager.shared.directus.items('tubes_order');
        tubesOrders
            .updateOne(id, { deleted: true })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmArchive = (id: number) => {
        var tubesOrders = DirectusManager.shared.directus.items('tubes_order');
        tubesOrders
            .updateOne(id, { status: 5 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmClose = (id: number) => {
        var tubesOrders = DirectusManager.shared.directus.items('tubes_order');
        tubesOrders
            .updateOne(id, { status: 4 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const returnMain = () => {
        navigate('/orders/tubes');
    };

    return (
        <Wrapper title={I18n.t('TUBES_ORDER') + ' ' + (data.order_number ? data.order_number : '')}>
            <Table
                data={data}
                tubes={true}
                confirmDelete={(id: number) => confirmDelete(id)}
                confirmArchive={(id: number) => confirmArchive(id)}
                confirmClose={(id: number) => confirmClose(id)}
                reloadOrder={() => reloadOrder()}
            />
        </Wrapper>
    );
};

export default TubeOrderDetail;
