
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Snackbar from '../../../components/snackbar';
import { useParams, useLocation } from "react-router-dom";
import Table from '../../../components/tables/detail-rows-single-col';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';

const SupplierDetail : FC = (props) => {
    const [loader, setLoader] = useState(false);
    const params = useParams<{ id: any }>();

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');

    const [address, setAddress] = useState('');
    const [addressError, setAddressError] = useState(false);
    const [addressErrorText, setAddressErrorText] = useState('');

    const [postalCode, setPostalCode] = useState('');
    const [postalCodeError, setPostalCodeError] = useState(false);
    const [postalCodeErrorText, setPostalCodeErrorText] = useState('');

    const [city, setCity] = useState('');
    const [cityError, setCityError] = useState(false);
    const [cityErrorText, setCityErrorText] = useState('');

    const [country, setCountry] = useState('');
    const [countryError, setCountryError] = useState(false);
    const [countryErrorText, setCountryErrorText] = useState('');

    const [officeEmail, setOfficeEmail] = useState('');

    const [ordersEmail, setOrdersEmail] = useState('');
    const [ordersEmailError, setOrdersEmailError] = useState(false);
    const [ordersEmailErrorText, setOrdersEmailErrorText] = useState('');

    const [contactPerson, setContactPerson] = useState('');
    const [status, setStatus] = useState(0);

    const snackBar = useRef();

    useEffect(() => {
        setLoader(true);
        let supplier = DirectusManager.shared.directus.items('supplier');
        supplier.readOne(params.id, {fields: ['*.*.*']}).then((data: any) => {
            if(data !== undefined && data !== null) {
                if(data.company){
                    setName(data.company);
                }
                if(data.address){
                    setAddress(data.address);
                } 
                if(data.city){
                    setCity(data.city);
                } 
                if(data.post_code){
                    setPostalCode(data.post_code);
                } 
                if(data.address){
                    setAddress(data.address);
                } 
                if(data.country){
                    setCountry(data.country);
                } 
                if(data.office_email){
                    setOfficeEmail(data.office_email);
                } 
                if(data.orders_email){
                    setOrdersEmail(data.orders_email);
                } 
                if(data.address){
                    setAddress(data.address);
                }
                if(data.contact_person){
                    setContactPerson(data.contact_person);
                }
                if(data.status){
                    setStatus(data.status == 'active' ? 0 : 1);
                }
                setLoader(false);
            }
        }).catch(error => {
            setLoader(false);
        });
    },[]);

    const save = () => {
        setNameError(false);
        setAddressError(false);
        setPostalCodeError(false);
        setCityError(false);
        setCountryError(false);
        setOrdersEmailError(false);

        if(name == ''){
            setNameError(true);
            setNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if(address == ''){
            setAddressError(true);
            setAddressErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if(city == ''){
            setCityError(true);
            setCityErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if(postalCode == ''){
            setPostalCodeError(true);
            setPostalCodeErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if(country == ''){
            setCountryError(true);
            setCountryErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if(ordersEmail == ''){
            setOrdersEmailError(true);
            setOrdersEmailErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if(name !== '' &&
            address !== '' &&
            city !== '' &&
            postalCode !== '' &&
            country !== '' &&
            ordersEmail !== ''
        ){
            var supplier = DirectusManager.shared.directus.items('supplier');
            supplier.updateOne(params.id, {
                company: name,
                status: status == 0 ? 'active' : 'inactive',
                address: address,
                city: city,
                post_code: postalCode,
                country: country,
                office_email: officeEmail,
                orders_email: ordersEmail,
                contact_person: contactPerson
            }).then(data => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('SUPPLIER_UPDATED'));
            }).catch((error: any) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
        }
    }

    return (
        <Wrapper
            title={I18n.t("SUPPLIER_EDIT")}
        >
            <Table
                midSize
                loading={loader}
                title={I18n.t('SUPPLIER_INFO')}
                save={() => save()}
                saveButtonText={I18n.t('SAVE_CHANGES')}
                rows={[
                    {
                        title: I18n.t('TABLE_NAME'),
                        value: name,
                        onChange: (val: any) => setName(val),
                        input: true,
                        mandatory: true,
                        error: nameError,
                        errorText: nameErrorText
                    },
                    {
                        title: I18n.t('TABLE_ADDRESS'),
                        value: address,
                        onChange: (val: any) => setAddress(val),
                        input: true,
                        mandatory: true,
                        error: addressError,
                        errorText: addressErrorText
                    },
                    {
                        title: I18n.t('TABLE_POSTALCODE'),
                        value: postalCode,
                        onChange: (val: any) => setPostalCode(val),
                        input: true,
                        mandatory: true,
                        error: postalCodeError,
                        errorText: postalCodeErrorText
                    },
                    {
                        title: I18n.t('TABLE_CITY'),
                        value: city,
                        onChange: (val: any) => setCity(val),
                        input: true,
                        mandatory: true,
                        error: cityError,
                        errorText: cityErrorText
                    },
                    {
                        title: I18n.t('TABLE_COUNTRY'),
                        value: country,
                        onChange: (val: any) => setCountry(val),
                        input: true,
                        mandatory: true,
                        error: countryError,
                        errorText: countryErrorText
                    },
                    {
                        title: I18n.t('TABLE_MAIL_OFFICE'),
                        value: officeEmail,
                        onChange: (val: any) => setOfficeEmail(val),
                        input: true,
                    },
                    {
                        title: I18n.t('TABLE_MAIL_ORDERS'),
                        value: ordersEmail,
                        onChange: (val: any) => setOrdersEmail(val),
                        input: true,
                        mandatory: true,
                        error: ordersEmailError,
                        errorText: ordersEmailErrorText
                    },
                    {
                        title: I18n.t('TABLE_ORDER_CONTACTPERSON'),
                        value: contactPerson,
                        onChange: (val: any) => setContactPerson(val),
                        input: true
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        select: true,
                        array: [
                            {
                                id: 0,
                                name: 'Active'
                            },
                            {
                                id: 1,
                                name: 'Inactive'
                            }
                        ]
                    },
                ]}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default SupplierDetail;