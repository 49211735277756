import React, { useState, FC, useEffect, useRef } from 'react';
import I18n from '../../helpers/i18n';
import Input from '../../components/input';
import Button from '../../components/button';
import Logo from '../../images/new-logo.png';
import { useNavigate, useLocation } from 'react-router-dom';
import DirectusManager from '../../helpers/directusManager';
import Snackbar from '../../components/snackbar';
interface Props {
}

const Login : FC <Props> = (props) => {
    const navigate = useNavigate();

    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [emailSent, setEmailSent] = useState(false);

    const snackBar = useRef();

    const checkFields = () => {
        setEmailError(false);
        setEmailErrorText('');

        //API CALL TO RESET PASSWORD AND SET STATE
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ email:  email })
        };

        fetch(DirectusManager.shared.baseUrl+'/password-reset/forgot', requestOptions)
            .then(response => {
                if(response.status === 200) {
                    setEmailSent(true)
                } else {
                    setEmailSent(false)
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                }
            })
            .catch(error => {
                console.log(error);
            });
    }

    const navigateBack = () => {
        navigate('/login');
    }

    return (
        <div>
            <section className="login d-flex justify-content-center align-items-center">
                <div className="login-block text-align-center">
                    <div className="logo mt-3 mb-5">
                        <img src={Logo} alt="logo DrLeenarts"/>
                    </div>
                    <Input
                        label={I18n.t('FORM_EMAIL')}
                        value={email}
                        error={emailError}
                        errorText={emailErrorText}
                        onChange={(value: any) => setEmail(value)}
                    />
                    {emailError &&
                        <span className="error-field">
                            {emailErrorText}
                        </span>
                    }
                    <div className='mt-3'/>

                    {emailSent &&
                        <div>
                            <div>
                                {'An email has been sent. Please check your email to continue.'}
                            </div>
                            <div className='mt-3'/>
                        </div>
                    }
                    <Button
                        defaultStyle
                        fullwidth
                        title={I18n.t('RESETPASSWORD_SEND')}
                        onPress={() => checkFields()}
                    />
                    <div className='mt-3'/>
                    <Button
                        defaultStyle
                        fullwidth
                        title={I18n.t('BACK')}
                        onPress={() => navigateBack()}
                    />
                </div>
            </section>
            <Snackbar ref={snackBar} noSidebar/>
        </div>
    );
}

export default Login;