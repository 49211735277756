import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/order-new';
import { useNavigate, useLocation } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import Snackbar from '../../../components/snackbar';
import moment from 'moment';
import * as Sentry from '@sentry/react';

/**
 *
 * New order allows the customer to create a new factory order by defining the desired delivery date, the factory that will process this order, contact person and the products to be ordered.
 * Every time the factory it's changed, the products will be reloaded as well since this is a strong relation inbetween.
 *
 * An order has multiple steps inbetween until you can consider it completed(can be seen as status)
 *
 * 1. Open
 * 2. Order to factory (by pressing on the email icon)
 * 3. Confirmed by supplier (by tapping on Confirm)
 * 4. Archived (by tapping on the archive icon)
 */

const NewOrder: FC = (props) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const snackBar = useRef();

    const [factories, setFactories] = useState<any>([]);
    const [selectedFactory, setSelectedFactory] = useState<any>(1);
    const [selectedFactoryName, setSelectedFactoryName] = useState<string>('Delarange');
    const [contacts, setContacts] = useState<any>([]);
    const [selectedContact, setSelectedContact] = useState<any>(1);

    const [allProducts, setAllProducts] = useState<any>([]); //all data
    const [availableProducts, setAvailableProducts] = useState<any>([]); //available only after adding
    const [productsAdded, setProductsAdded] = useState<any>([]); //selected/added products
    const [desiredDeliveryDate, setDesiredDeliveryDate] = useState<any>(null);

    useEffect(() => {
        setLoader(true);
        let sortContacts: any = ['contact_name'];
        //Load all the available contact persons
        var contacts = DirectusManager.shared.directus.items('contact_person');
        contacts
            .readMany({ limit: -1, sort: sortContacts, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        name: x.contact_name,
                        id: x.contact_id,
                    }));
                    setContacts(mapped);
                    setSelectedContact(parseInt(mapped[0].id));
                }
            })
            .catch((error) => {});
        let sortFactories: any = ['name'];
        //Load all the available factories
        var factories = DirectusManager.shared.directus.items('factories');
        factories
            .readMany({ limit: -1, sort: sortFactories, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        name: x.name,
                        id: x.id,
                    }));
                    setFactories(mapped);
                    setSelectedFactory(mapped[0].id);
                    setSelectedFactoryName(mapped[0].name);
                    loadProducts(mapped[0].id);
                }
            })
            .catch((error) => {
                console.error(error);
            });
    }, []);

    const loadProducts = (factoryId: number) => {
        let sortProducts: any = ['name'];
        var products = DirectusManager.shared.directus.items('products');
        console.log('loadProducts', factoryId);
        products
            .readMany({
                limit: -1,
                sort: sortProducts,
                filter: { deleted: false, status: 'active', factory: { id: factoryId } },
                fields: ['id', 'name', 'article_code', 'packaging_code_MT', 'minimum_order_amount', 'country.*'],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.id,
                        name: x.name + ' - ' + x.article_code + (x.country?.code ? ' (' + x.country?.code + ')' : ''),
                        country: x.country?.code ? x.country?.code : '-',
                        article_code: x.article_code,
                        packaging_code: x.packaging_code_MT,
                        min_amount: x.minimum_order_amount,
                    }));
                    setAllProducts(mapped);
                    setAvailableProducts(mapped);
                    setLoader(false);
                }
            })
            .catch((error) => {
                console.error(error);
                Sentry.captureException(error);
                setLoader(false);
            });
    };

    const saveOrder = (order: any) => {
        if (productsAdded.length == 0) {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('PRODUCT_REQUIRED'));
            return;
        }

        if (desiredDeliveryDate === null) {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('DATE_REQUIRED'));
            return;
        }

        var formattedDate = moment(desiredDeliveryDate).format('YYYY-MM-DD');
        let orderData = {
            factory: selectedFactory,
            contact_id: parseInt(selectedContact),
            deleted: false,
            status: 1,
            address_setting: 1,
            desired_delivery_date: new Date(formattedDate),
        };

        //Status 1 it's OPEN in the CMS
        var factoryOrder = DirectusManager.shared.directus.items('factory_order');
        factoryOrder
            .createOne(orderData)
            .then((data: any) => {
                if (data) {
                    if (productsAdded !== undefined && productsAdded.length > 0) {
                        saveProductsInOrder(data.factory_order_id);
                    } else {
                        navigate('/orders/factories', { state: 'new' });
                    }
                }
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const saveProductsInOrder = (orderId: number) => {
        let productToSave = productsAdded.pop();

        var type = 'factory_order_products';
        var orderProducts = DirectusManager.shared.directus.items(type);
        orderProducts
            .createOne({
                factory_order_id: orderId,
                amount: productToSave.col6,
                static_product_name: productToSave.col1,
                product_id: productToSave.id,
                deleted: false,
            })
            .then((data) => {
                if (productsAdded === undefined || productsAdded.length == 0) {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                    navigate('/orders/factories', { state: 'new' });
                } else {
                    saveProductsInOrder(orderId);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const updateProducts = (newArray: any, deletedProduct?: any) => {
        setProductsAdded(newArray);

        if (deletedProduct) {
            for (var a = 0; a < allProducts.length; a++) {
                if (allProducts[a].id == deletedProduct.id) {
                    var updatedArray: any = [].concat(availableProducts);
                    updatedArray.push(allProducts[a]);
                    updatedArray.sort((x: any, y: any) => (x.name > y.name ? 1 : -1));
                    setAvailableProducts(updatedArray);
                }
            }
        } else {
            for (var n = 0; n < newArray.length; n++) {
                var newProduct = newArray[n];

                var index = availableProducts.findIndex((el: any) => el.id === newProduct.id);

                if (index !== -1) {
                    var updatedArray: any = [].concat(availableProducts);
                    updatedArray.splice(index, 1);
                    setAvailableProducts(updatedArray);
                }
            }
        }
    };

    return (
        <Wrapper title={I18n.t('NEW_ORDER')}>
            <Table
                title={I18n.t('NEW_ORDER_PURCHASE')}
                type="factories"
                factories={factories}
                selectedFactory={selectedFactory}
                setSelectedFactory={(id: number, name: string) => {
                    setSelectedFactory(id);
                    setSelectedFactoryName(name);
                    loadProducts(id);
                    setProductsAdded([]);
                }}
                contacts={contacts}
                selectedContact={selectedContact}
                setSelectedContact={(id: number) => setSelectedContact(id)}
                availableProducts={availableProducts}
                productsAdded={productsAdded}
                updateProducts={(array, deletedProduct) => updateProducts(array, deletedProduct)}
                setDesiredDeliveryDate={(date: any) => setDesiredDeliveryDate(date)}
                data={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            Type: 'name',
                        },
                        {
                            Header: I18n.t('ARTICLE_CODE'),
                            accessor: 'col2',
                            Type: 'code',
                        },
                        {
                            Header: I18n.t('FACTORY_CODE'),
                            accessor: 'col3',
                            Type: 'code',
                        },
                        {
                            Header: I18n.t('AMOUNT'),
                            accessor: 'col6',
                            Type: 'input',
                        },
                    ],
                }}
                saveOrder={(order) => saveOrder(order)}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default NewOrder;
