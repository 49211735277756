import React, { FC, useState, useEffect } from 'react';
import I18n from '../../helpers/i18n';
import { Link, useNavigate } from 'react-router-dom';
import Table from '../../components/tables/detail-rows-single-col';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ToolTip from '../../components/tooltip';
import DirectusManager from '../../helpers/directusManager';

interface Props {
    title: string;
    emailText: string;
    setEmailText: (val: any) => void;
    emailFrom: string;
    emailFromError: boolean;
    emailFromErrorText: string;
    emailFromChange: (val: any) => void;
    emailTo: string;
    emailToError: boolean;
    emailToErrorText: string;
    emailToChange: (val: any) => void;
    attachment: string;
    data: any;
    keys: any;
    orderID: string;
    sendEmail: () => void;
    loader: boolean;
}

const MailTemplate: FC<Props> = (props) => {
    const viewFile = () => {
        if (props.data === undefined) {
            return;
        }
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                order_number: props.data.order_number,
                auth_token: DirectusManager.shared.directus.auth.token,
            }),
        };
        fetch(DirectusManager.shared.baseUrl + '/pdf-generator', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (
                    response.data !== undefined &&
                    response.data.url !== undefined
                ) {
                    window.open(
                        response.data.url +
                            '?download=&access_token=' +
                            DirectusManager.shared.directus.auth.token
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    return (
        <div className="mail-template">
            <Table
                midLargeSize
                title={props.title}
                save={() => props.sendEmail()}
                saveButtonText={I18n.t('SEND_EMAIL')}
                emailSendingLoader={props.loader}
                disabledButton={props.loader ? true : false}
                rows={[
                    {
                        title: I18n.t('TABLE_TO'),
                        value: props.emailTo,
                        onChange: (val: any) => props.emailToChange(val),
                        input: true,
                        mandatory: true,
                        error: props.emailToError,
                        errorText: props.emailToErrorText,
                    },
                    {
                        title: I18n.t('TABLE_FROM'),
                        value: props.emailFrom,
                        onChange: (val: any) => props.emailFromChange(val),
                        input: true,
                        mandatory: true,
                        error: props.emailFromError,
                        errorText: props.emailFromErrorText,
                    },
                    {
                        title: I18n.t('TABLE_ATTACHMENT'),
                        customComponent: (
                            <div
                                className="attachment-wrapper d-flex align-items-center"
                                onClick={() => viewFile()}
                            >
                                {props.attachment}
                                <ToolTip text={I18n.t('VIEW_FILE')}>
                                    <PictureAsPdfIcon className="icon" />
                                </ToolTip>
                            </div>
                        ),
                    },
                    {
                        title: I18n.t('TABLE_EMAIL'),
                        verAlignTop: true,
                        mandatory: true,
                        customComponent: (
                            <>
                                <div className="mail-variables">
                                    <span className="d-block title">
                                        {I18n.t('EMAIL_USABLE_KEYS') + ': '}
                                    </span>
                                    {props.keys.length > 0 &&
                                        props.keys.map(
                                            (item: any, index: any) => {
                                                return (
                                                    <span key={index}>
                                                        {item.key}
                                                        {props.keys.length !==
                                                        index + 1
                                                            ? ', '
                                                            : ''}
                                                    </span>
                                                );
                                            }
                                        )}
                                </div>
                                <textarea
                                    rows={9}
                                    className="text-area"
                                    defaultValue={props.emailText}
                                    onChange={(event) =>
                                        props.setEmailText(event?.target.value)
                                    }
                                ></textarea>
                            </>
                        ),
                    },
                ]}
            />
        </div>
    );
};
export default MailTemplate;
