import React, { FC } from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';

import PageBehaviour from './page_behaviour';

import Four0Four from '../pages/404';
import LoginPage from '../pages/login';
import HomePage from '../pages/home';
import ForgotPassword from '../pages/forgotpassword';
import ResetPassword from '../pages/resetpassword';

import OrderTubes from '../pages/orders/tubes/overview';
import OrderTubeDetail from '../pages/orders/tubes/detail';
import NewOrderTubes from '../pages/orders/tubes/new';
import OrderTubesMail from '../pages/orders/tubes/mail';

import OrderFactories from '../pages/orders/factories/overview';
import OrderFactoryDetail from '../pages/orders/factories/detail';
import NewOrderFactory from '../pages/orders/factories/new';
import OrderFactoryMail from '../pages/orders/factories/mail';

import OrderSuppliers from '../pages/orders/suppliers/overview';
import OrderSupplierDetail from '../pages/orders/suppliers/detail';
import NewOrderSupplier from '../pages/orders/suppliers/new';

import ProductOverview from '../pages/products/overview';
import ProductEdit from '../pages/products/edit';
import NewProduct from '../pages/products/new';

import Configuration from '../pages/settings/configuration';

import InsightsStockProducts from '../pages/insights/stock-products/overview';
import InsightsStockProductDetail from '../pages/insights/stock-products/detail';

import InsightsStockTubes from '../pages/insights/stock-tubes/overview';
import InsightsStockTubeDetail from '../pages/insights/stock-tubes/detail';

import InsightsPlanning from '../pages/insights/planning/overview';
import InsightsSales from '../pages/insights/sales/overview';
import InsightsSalesProduct from '../pages/insights/sales/product';
import InsightsSalesNewImport from '../pages/insights/sales/new_import';
import InsightsSalesImport from '../pages/insights/sales/import';
import InsightsKPI from '../pages/insights/kpi/overview';
import InsightsFinance from '../pages/insights/finance/overview';

import ProductGroupsOverview from '../pages/settings/product-groups/overview';
import ProductGroupsDetail from '../pages/settings/product-groups/detail';
import ProductGroupsNew from '../pages/settings/product-groups/new';

import SuppliersOverview from '../pages/settings/suppliers/overview';
import SuppliersDetail from '../pages/settings/suppliers/detail';
import SuppliersProducts from '../pages/settings/suppliers/products';
import SuppliersNew from '../pages/settings/suppliers/new';

import FactoriesOverview from '../pages/settings/factories/overview';
import FactoriesDetail from '../pages/settings/factories/detail';
import FactoriesNew from '../pages/settings/factories/new';

import DRLContactsOverview from '../pages/settings/drl-contacts/overview';
import DRLContactsNew from '../pages/settings/drl-contacts/new';
import DRLContactsDetail from '../pages/settings/drl-contacts/detail';

import CountriesOverview from '../pages/settings/countries/overview';
import CountryForm from '../pages/settings/countries/form';

const Navigation: FC = (props) => {
    return (
        <BrowserRouter>
            <PageBehaviour />
            <Routes>
                <Route path="/" element={<HomePage />} />
                <Route path="/login" element={<LoginPage />} />
                <Route path="/forgot-password" element={<ForgotPassword />} />
                <Route path="/reset-password/:token" element={<ResetPassword />} />

                <Route path="/orders" element={<Navigate to="/orders/suppliers" />} />

                <Route path="/orders/tubes" element={<OrderTubes />} />
                <Route path="/orders/tubes/new" element={<NewOrderTubes />} />
                <Route path="/orders/tubes/:id" element={<OrderTubeDetail />} />
                <Route path="/orders/tubes/mail" element={<OrderTubesMail />} />

                <Route path="/orders/factories" element={<OrderFactories />} />
                <Route path="/orders/factories/:id" element={<OrderFactoryDetail />} />
                <Route path="/orders/factories/new" element={<NewOrderFactory />} />
                <Route path="/orders/factories/mail" element={<OrderFactoryMail />} />

                <Route path="/orders/suppliers" element={<OrderSuppliers />} />
                <Route path="/orders/suppliers/:id" element={<OrderSupplierDetail />} />
                <Route path="/orders/suppliers/new" element={<NewOrderSupplier />} />

                <Route path="/products" element={<ProductOverview />} />
                <Route path="/products/new" element={<NewProduct />} />
                <Route path="/products/:id" element={<ProductEdit />} />

                <Route path="/insights" element={<Navigate to="/insights/stock-product" />} />
                <Route path="/insights/stock-product" element={<InsightsStockProducts />} />
                <Route path="/insights/stock-product/:id" element={<InsightsStockProductDetail />} />
                <Route path="/insights/stock-tubes" element={<InsightsStockTubes />} />
                <Route path="/insights/stock-tubes/:id" element={<InsightsStockTubeDetail />} />
                <Route path="/insights/sales" element={<InsightsSales />} />
                <Route path="/insights/sales/product/:id" element={<InsightsSalesProduct />} />
                <Route path="/insights/sales/import/:id" element={<InsightsSalesImport />} />
                <Route path="/insights/sales/new-import" element={<InsightsSalesNewImport />} />
                <Route path="/insights/planning" element={<InsightsPlanning />} />
                <Route path="/insights/kpi" element={<InsightsKPI />} />
                <Route path="/insights/finance" element={<InsightsFinance />} />

                <Route path="/settings" element={<Navigate to="/settings/product-groups" />} />

                <Route path="/settings/product-groups" element={<ProductGroupsOverview />} />
                <Route path="/settings/product-groups/:id" element={<ProductGroupsDetail />} />
                <Route path="/settings/product-groups/new" element={<ProductGroupsNew />} />

                <Route path="/settings/suppliers" element={<SuppliersOverview />} />
                <Route path="/settings/suppliers/:id" element={<SuppliersDetail />} />
                <Route path="/settings/suppliers/:id/products" element={<SuppliersProducts />} />
                <Route path="/settings/suppliers/new" element={<SuppliersNew />} />

                <Route path="/settings/factories" element={<FactoriesOverview />} />
                <Route path="/settings/factories/:id" element={<FactoriesDetail />} />
                <Route path="/settings/factories/new" element={<FactoriesNew />} />

                <Route path="/settings/contacts" element={<DRLContactsOverview />} />
                <Route path="/settings/contacts/new" element={<DRLContactsNew />} />
                <Route path="/settings/contacts/:id" element={<DRLContactsDetail />} />

                <Route path="/settings/countries" element={<CountriesOverview />} />
                <Route path="/settings/countries/:id" element={<CountryForm />} />
                <Route path="/settings/countries/new" element={<CountryForm />} />

                <Route path="/settings/configuration" element={<Configuration />} />

                <Route path="*" element={<Four0Four />} />
            </Routes>
        </BrowserRouter>
    );
};

export default Navigation;
