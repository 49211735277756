import DirectusManager from './directusManager';
import I18n from '../helpers/i18n';
import moment from 'moment';

export default class RelationManager { 
    static shared: RelationManager = new RelationManager();

    //Product Group Relations:
    productGroups(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('products');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, product_group: { group_id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        var relations: any = data.data;
                        var text : any = I18n.t('NAV_PRODUCTS') + ':\n';
                        for (var a = 0; a < relations.length; a++) {
                            if(a == 0){
                                text = text + relations[a].name;
                            } else {
                                text = text + ',\n' + relations[a].name;
                            }
                        }
                        resolve([relations, text]);
                    } else {
                        resolve([[],'']);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }

    //Factory Relations:
    factories(id: number){
        var that: any = this;
        return new Promise(
            function(resolve, reject) {
                var text : any = '';
                var products: any = [];
                var orders: any = [];
                that.factoryProducts(id).then((data: any) => {
                    if(data !== undefined && data.length > 0){
                        products = data;
                        text = I18n.t('NAV_PRODUCTS') + ':\n';
                        for (var a = 0; a < products.length; a++) {
                            if(a == 0){
                                text = text + products[a].name;
                            } else {
                                text = text + '\n' + products[a].name;
                            }
                        }
                    }
                    that.factoryOrders(id).then((data: any) => {
                        if(data !== undefined && data.length > 0){
                            orders = data;
                            if(products.length > 0){
                                text = text + '\n\n';
                            }
                            text = text + I18n.t('FACTORY_ORDERS') + ':\n';
                            for (var a = 0; a < orders.length; a++) {
                                var orderPreFix = 'F-' + moment(orders[a].date_created).format('YYYY')+'-';
                                if(a == 0){
                                    text = text + orderPreFix + orders[a].factory_order_id;
                                } else {
                                    text = text + '\n' + orderPreFix + orders[a].factory_order_id;
                                }
                            }
                        }
                        resolve([products, text, orders]);
                    }).catch((error: any) => {
                        reject([]);
                    });
                }).catch((error: any) => {
                    reject([]);
                });
            }
        );
    }
    factoryProducts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('products');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, factory: { id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    factoryOrders(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('factory_order');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, factory: { id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    //Contacts Relations:
    contacts(id: number){
        var that: any = this;
        return new Promise(
            function(resolve, reject) {
                var text : any = '';
                var tubeOrders: any = [];
                var factoryOrders: any = [];
                var supplierOrders: any = [];
                that.tubeOrderContacts(id).then((data: any) => {
                    if(data !== undefined && data.length > 0){
                        tubeOrders = data;
                        if(tubeOrders.length > 0){
                            text = I18n.t('TUBES_ORDERS') + ':\n';
                            for (var a = 0; a < tubeOrders.length; a++) {
                                var orderPreFix = 'PR-MT-' + moment(tubeOrders[a].date_created).format('YYYY')+'-';
                                if(a == 0){
                                    text = text + orderPreFix + tubeOrders[a].tube_order_id;
                                } else {
                                    text = text + '\n' + orderPreFix + tubeOrders[a].tube_order_id;
                                }
                            }
                        }
                    }
                    that.factoryOrderContacts(id).then((data2: any) => {
                        if(data2 !== undefined && data2.length > 0){
                            factoryOrders = data2;
                            if(factoryOrders.length > 0){
                                if(tubeOrders.length > 0){
                                    text = text + '\n\n';
                                }
                                text = text + I18n.t('FACTORY_ORDERS') + ':\n';
                                for (var a = 0; a < factoryOrders.length; a++) {
                                    var orderPreFix = 'F-' + moment(factoryOrders[a].date_created).format('YYYY')+'-';
                                    if(a == 0){
                                        text = text + orderPreFix + factoryOrders[a].factory_order_id;
                                    } else {
                                        text = text + '\n' + orderPreFix + factoryOrders[a].factory_order_id;
                                    }
                                }
                            }
                        }
                        that.supplierOrderContacts(id).then((data3: any) => {
                            if(data3 !== undefined && data3.length > 0){
                                supplierOrders = data3;
                                if(supplierOrders.length > 0){
                                    if(tubeOrders.length > 0 || factoryOrders.length > 0){
                                        text = text + '\n\n';
                                    }
                                    text = text + I18n.t('SUPPLIER_ORDERS') + ':\n';
                                    for (var a = 0; a < supplierOrders.length; a++) {
                                        var orderPreFix = 'S-';
                                        var append = '-' + moment(supplierOrders[a].date_created).format('YYYY');
                                        if(a == 0){
                                            text = text + orderPreFix + supplierOrders[a].supplier_orders_id + append;
                                        } else {
                                            text = text + '\n' + orderPreFix + supplierOrders[a].supplier_orders_id + append;
                                        }
                                    }
                                }
                            }
                            resolve([tubeOrders, text, factoryOrders, supplierOrders]);
                        }).catch((error: any) => {
                            reject([]);
                        });
                    }).catch((error: any) => {
                        reject([]);
                    });
                }).catch((error: any) => {
                    reject([]);
                });
            }
        );
    }
    tubeOrderContacts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('tubes_order');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, contact_id: { contact_id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    factoryOrderContacts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('factory_order');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, contact_id: { contact_id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    supplierOrderContacts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('supplier_orders');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, contact_id: { contact_id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }

    //Supplier Relations:
    suppliers(id: number){
        var that: any = this;
        return new Promise(
            function(resolve, reject) {
                var text : any = '';
                var factoryOrdersProducts: any = [];
                var supplierOrders: any = [];
                that.supplierFactoryOrderProducts(id).then((data2: any) => {
                    if(data2 !== undefined && data2.length > 0){
                        factoryOrdersProducts = data2;
                        if(factoryOrdersProducts.length > 0){
                            text = text + I18n.t('FACTORY_ORDERS') + ':\n';
                            for (var a = 0; a < factoryOrdersProducts.length; a++) {
                                var orderPreFix = 'F-' + moment(factoryOrdersProducts[a].date_created).format('YYYY')+'-';
                                if(a == 0){
                                    text = text + orderPreFix + factoryOrdersProducts[a].factory_order_id;
                                } else {
                                    text = text + '\n' + orderPreFix + factoryOrdersProducts[a].factory_order_id;
                                }
                            }
                        }
                    }
                    that.supplierOrders(id).then((data3: any) => {
                        if(data3 !== undefined && data3.length > 0){
                            supplierOrders = data3;
                            if(supplierOrders.length > 0){
                                if(factoryOrdersProducts.length > 0){
                                    text = text + '\n\n';
                                }
                                text = text + I18n.t('SUPPLIER_ORDERS') + ':\n';
                                for (var a = 0; a < supplierOrders.length; a++) {
                                    var orderPreFix = 'S-';
                                    var append = '-' + moment(supplierOrders[a].date_created).format('YYYY');
                                    if(a == 0){
                                        text = text + orderPreFix + supplierOrders[a].supplier_orders_id + append;
                                    } else {
                                        text = text + '\n' + orderPreFix + supplierOrders[a].supplier_orders_id + append;
                                    }
                                }
                            }
                        }
                        resolve([factoryOrdersProducts, text, supplierOrders]);
                    }).catch((error: any) => {
                        reject([]);
                    });
                }).catch((error: any) => {
                    reject([]);
                });
            }
        );
    }
    supplierFactoryOrderProducts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('factory_order_products');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, product_supplier: id }}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    supplierOrders(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('supplier_orders');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, supplier_id: { supplier_id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }

     //Product Relations:
     products(id: number){
        var that: any = this;
        return new Promise(
            function(resolve, reject) {
                var text : any = '';
                var tubeOrders: any = [];
                var factoryOrders: any = [];
                var supplierOrders: any = [];
                that.tubeOrderProducts(id).then((data: any) => {
                    if(data !== undefined && data.length > 0){
                        tubeOrders = data;
                        if(tubeOrders.length > 0){
                            text = I18n.t('TUBES_ORDERS') + ':\n';
                            for (var a = 0; a < tubeOrders.length; a++) {
                                var orderPreFix = 'PR-MT-' + moment(tubeOrders[a].date_created).format('YYYY')+'-';
                                if(a == 0){
                                    text = text + orderPreFix + tubeOrders[a].tube_order_id;
                                } else {
                                    text = text + '\n' + orderPreFix + tubeOrders[a].tube_order_id;
                                }
                            }
                        }
                    }
                    that.factoryOrderProducts(id).then((data2: any) => {
                        if(data2 !== undefined && data2.length > 0){
                            factoryOrders = data2.filter((x: any) => x.factory_order_id !== null);
                            if(factoryOrders.length > 0){
                                if(tubeOrders.length > 0){
                                    text = text + '\n\n';
                                }
                                text = text + I18n.t('FACTORY_ORDERS') + ':\n';
                                for (var a = 0; a < factoryOrders.length; a++) {
                                    var orderPreFix = 'F-' + moment(factoryOrders[a].date_created).format('YYYY')+'-';
                                    if(a == 0){
                                        text = text + orderPreFix + factoryOrders[a].factory_order_id;
                                    } else {
                                        text = text + '\n' + orderPreFix + factoryOrders[a].factory_order_id;
                                    }
                                }
                            }
                        }
                        that.supplierOrderProducts(id).then((data3: any) => {
                            if(data3 !== undefined && data3.length > 0){
                                supplierOrders = data3.filter((x: any) => x.supplier_orders_id !== null && x.supplier_orders_id !== undefined);
                                if(supplierOrders.length > 0){
                                    if(tubeOrders.length > 0 || factoryOrders.length > 0){
                                        text = text + '\n\n';
                                    }
                                    text = text + I18n.t('SUPPLIER_ORDERS') + ':\n';
                                    for (var a = 0; a < supplierOrders.length; a++) {
                                        var orderPreFix = 'S-';
                                        var append = '-' + moment(supplierOrders[a].date_created).format('YYYY');
                                        if(a == 0){
                                            text = text + orderPreFix + supplierOrders[a].supplier_orders_id + append;
                                        } else {
                                            text = text + '\n' + orderPreFix + supplierOrders[a].supplier_orders_id + append;
                                        }
                                    }
                                }
                            }
                            resolve([tubeOrders, text, factoryOrders, supplierOrders]);
                        }).catch((error: any) => {
                            reject([]);
                        });
                    }).catch((error: any) => {
                        reject([]);
                    });
                }).catch((error: any) => {
                    reject([]);
                });
            }
        );
    }
    tubeOrderProducts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('tubes_order_products');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, product_id: { id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        let filtered = data.data.filter((x: any) => x.tube_order_id !== null);
                        resolve(filtered);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    factoryOrderProducts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('factory_order_products');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, product_id: { id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
    supplierOrderProducts(id: number){
        return new Promise(
            function(resolve, reject) {
                var checkForConnections = DirectusManager.shared.directus.items('supplier_products');
                checkForConnections.readMany({limit: -1, filter: { deleted: false, product_id: { id: id }}}).then( data => {
                    if(data.data !== null && data.data !== undefined && data.data.length > 0){
                        resolve(data.data);
                    } else {
                        resolve([]);
                    }
                }).catch( error => {
                    reject([]);
                });
            }
        );
    }
}
