import React, { FC, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import I18n from '../../../../helpers/i18n';
import { useNavigate, useLocation } from 'react-router-dom';
import Inputfield from '../../../input';
import Selectfield from '../../../select';
import SearchIcon from '@mui/icons-material/Search';
import DirectusManager from '../../../../helpers/directusManager';
import SortIcon from '@mui/icons-material/Sort';
import ToolTip from '../../../tooltip';
import DatePicker from '../../../date-picker';
interface Props {
    allowDateFilter: boolean;
    filterDateStart?: any;
    filterDateEnd?: any;
    changedDateStart?: (start: any) => void;
    changedDateEnd?: (start: any, end: any) => void;
    filterDateText?: string;

    allowStatusFilter: boolean;
    filterStatus?: string;
    changedStatus?: (value: string) => void;

    allowSearchFilter: boolean;
    filterSearch?: string;
    changedSearchValue?: (value: string) => void;
    searchPlaceholder?: string;

    supplierFilter: boolean;
    filterSupplier?: string;
    changedSupplier?: (value: string) => void;

    factoryFilter: boolean;
    filterFactory?: string;
    changedFactory?: (value: string) => void;

    countryFilter: boolean;
    filterCountry?: string;
    changedCountry?: (value: string) => void;

    filterProductType?: string;
    changedProductType?: (value: string) => void;

    type: string;
    allowSorting?: boolean;
    toggleSort: () => void;
    sortingEnabled: boolean;
}

const Filters: FC<Props> = (props) => {
    const [loader, setLoader] = useState(false);
    const [statuses, setStatuses] = useState<any>([]);
    const [dates, setDates] = useState<any>([]);
    const [factories, setFactories] = useState<any>([]);
    const [suppliers, setSuppliers] = useState<any>([]);
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState<any>('');
    const [productTypes, setProductTypes] = useState<any>([]);
    const [countries, setCountries] = useState<any>([]);

    const [ranges, setRanges] = useState<any>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    const getStatusName = (val: string) => {
        var name = '';
        name = I18n.t('STATUS_' + val.toUpperCase());
        return name;
    };

    useEffect(() => {
        //get available statuses and set data
        setDates([
            {
                id: 1,
                name: 'Feb 2020',
            },
        ]);
        setProductTypes([
            {
                id: 'semi-finished',
                name: I18n.t('TYPE_HALF'),
            },
            {
                id: 'final-product',
                name: I18n.t('TYPE_END'),
            },
        ]);

        let sort: any = ['name'];
        var status_table =
            props.type == 'orders-tubes'
                ? 'tubes_statuses'
                : props.type == 'orders-factories'
                ? 'factories_statuses'
                : 'supplier_statuses';
        var statuses = DirectusManager.shared.directus.items(status_table);
        statuses
            .readMany({ limit: -1, sort: sort })
            .then((data: any) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        name: getStatusName(x.name),
                        id: x.id,
                    }));
                    setStatuses(mapped);
                }
            })
            .catch((error: any) => {});

        if (props.factoryFilter) {
            let sort: any = ['name'];
            var factories = DirectusManager.shared.directus.items('factories');
            factories
                .readMany({
                    limit: -1,
                    sort: sort,
                    filter: { status: 'active' },
                })
                .then((data: any) => {
                    if (data !== undefined && data.data !== undefined && data.data !== null) {
                        let mapped = data.data.map((x: any) => ({
                            name: x.name,
                            id: x.id,
                        }));
                        setFactories(mapped);
                    }
                })
                .catch((error: any) => {
                    setFactories([]);
                });
        }

        if (props.countryFilter) {
            let sort: any = ['name'];
            var countries = DirectusManager.shared.directus.items('countries');
            countries
                .readMany({
                    limit: -1,
                    sort: sort,
                })
                .then((data: any) => {
                    if (data !== undefined && data.data !== undefined && data.data !== null) {
                        let mapped = data.data.map((x: any) => ({
                            uuid: x.uuid,
                            name: x.name,
                        }));
                        setCountries(mapped);
                    }
                })
                .catch((error: any) => {
                    setCountries([]);
                });
        }

        if (props.supplierFilter) {
            let sort: any = ['company'];
            var suppliers = DirectusManager.shared.directus.items('supplier');
            suppliers
                .readMany({
                    limit: -1,
                    sort: sort,
                    filter: { status: 'active' },
                })
                .then((data: any) => {
                    if (data !== undefined && data.data !== undefined && data.data !== null) {
                        let mapped = data.data.map((x: any) => ({
                            name: x.company,
                            id: x.supplier_id,
                        }));
                        setSuppliers(mapped);
                    }
                })
                .catch((error: any) => {
                    setSuppliers([]);
                });
        }
    }, []);

    const changeStatus = (id: any) => {
        if (id == 'reset' && props.changedStatus) {
            props.changedStatus('reset');
        } else if (statuses.length > 0) {
            for (var i = 0; i < statuses.length; i++) {
                var val = statuses[i];
                if (val.id == id && props.changedStatus) {
                    props.changedStatus(val.id);
                }
            }
        }
    };

    const changeFactory = (id: any) => {
        if ((id == '' || id == 'reset') && props.changedFactory) {
            props.changedFactory('reset');
        }

        if (factories.length > 0) {
            for (var i = 0; i < factories.length; i++) {
                var val = factories[i];
                if (val.id == id && props.changedFactory) {
                    props.changedFactory(val.id);
                }
            }
        }
    };

    const changeCountry = (uuid: any) => {
        if ((uuid == '' || uuid == 'reset') && props.changedCountry) {
            props.changedCountry('reset');
        }

        if (countries.length > 0) {
            for (var i = 0; i < countries.length; i++) {
                var val = countries[i];
                if (val.uuid == uuid && props.changedCountry) {
                    props.changedCountry(val.uuid);
                }
            }
        }
    }

    const changeProductType = (id: any) => {
        if (id == 'reset' && props.changedProductType) {
            props.changedProductType('reset');
        } else if (productTypes.length > 0) {
            for (var i = 0; i < productTypes.length; i++) {
                var val = productTypes[i];
                if (val.id == id && props.changedProductType) {
                    props.changedProductType(val.id);
                }
            }
        }
    };

    const changeSupplier = (id: any) => {
        if ((id == '' || id == 'reset') && props.changedSupplier) {
            props.changedSupplier('reset');
        }

        if (suppliers.length > 0) {
            for (var i = 0; i < suppliers.length; i++) {
                var val = suppliers[i];
                if (val.id == id && props.changedSupplier) {
                    props.changedSupplier(val.id);
                }
            }
        }
    };

    return (
        <div className="filters d-flex align-items-center">
            {props.allowSorting && (
                <div>
                    <ToolTip text={I18n.t('FILTER_SORT_DRAG_DROP')}>
                        <div className={props.sortingEnabled ? 'sorting active' : 'sorting'}>
                            <SortIcon onClick={() => props.toggleSort()} />
                        </div>
                    </ToolTip>
                </div>
            )}
            {props.allowStatusFilter && (
                <Selectfield
                    value={props.filterStatus}
                    label={I18n.t('TABLE_STATUS')}
                    array={statuses}
                    onChange={(val: any) => changeStatus(val)}
                    disabled={props.sortingEnabled ? true : false}
                />
            )}
            {props.factoryFilter && (
                <Selectfield
                    value={props.filterFactory}
                    label={I18n.t('TABLE_FACTORY')}
                    array={factories}
                    onChange={(val: any) => changeFactory(val)}
                    disabled={props.sortingEnabled ? true : false}
                />
            )}
            {props.countryFilter && (
                <Selectfield
                    value={props.filterCountry}
                    label={I18n.t('TABLE_COUNTRY')}
                    array={countries.map((x: any) => ({ id: x.uuid, name: x.name }))}
                    onChange={(val: any) => changeCountry(val)}
                    disabled={props.sortingEnabled ? true : false}
                />
            )}
            {props.factoryFilter && (
                <Selectfield
                    value={props.filterProductType}
                    label={I18n.t('TABLE_TYPE')}
                    array={productTypes}
                    onChange={(val: any) => changeProductType(val)}
                    disabled={props.sortingEnabled ? true : false}
                />
            )}
            {props.supplierFilter && (
                <Selectfield
                    value={props.filterSupplier}
                    label={I18n.t('TABLE_SUPPLIER')}
                    array={suppliers}
                    onChange={(val: any) => changeSupplier(val)}
                    disabled={props.sortingEnabled ? true : false}
                />
            )}
            {props.allowDateFilter && (
                <DatePicker
                    range
                    filterDateStart={props.filterDateStart}
                    filterDateEnd={props.filterDateEnd}
                    changedDateStart={(newDates) => props.changedDateStart && props.changedDateStart(newDates)}
                    changedDateEnd={(start, end) => props.changedDateEnd && props.changedDateEnd(start, end)}
                    filterDateText={props.filterDateText}
                    fromFilter
                />
            )}
            {props.allowSearchFilter && (
                <div className="search-filter">
                    <Inputfield
                        disableActiveLabel
                        value={props.filterSearch}
                        label={props.searchPlaceholder}
                        onChange={(val: any) => props.changedSearchValue && props.changedSearchValue(val)}
                        icon={<SearchIcon />}
                        disabled={props.sortingEnabled ? true : false}
                    />
                </div>
            )}
        </div>
    );
};
export default Filters;
