import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import MailTemplate from '../../../components/mail-template';
import Snackbar from '../../../components/snackbar';
import Helpers from '../../../helpers/functions';

/**
 *
 * Allows the user to send an email to multitubes with the order data. This is one of the steps in the flow for a tubes order in order to be completed.
 */

const TubeMail: FC = (props) => {
    const [emailText, setEmailText] = useState<any>('');
    const [keys, setKeys] = useState<any>('');

    const [emailFrom, setEmailFrom] = useState('');
    const [emailFromName, setEmailFromName] = useState('');
    const [emailFromError, setEmailFromError] = useState(false);
    const [emailFromErrorText, setEmailFromErrorText] = useState('');

    const [emailTo, setEmailTo] = useState('');
    const [emailToName, setEmailToName] = useState('');
    const [emailToError, setEmailToError] = useState(false);
    const [emailToErrorText, setEmailToErrorText] = useState('');

    const [attachment, setAttachment] = useState<any>();
    const [orderData, setOrderData] = useState<any>(null);
    const [orderID, setOrderID] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const snackBar = useRef();

    const [loader, setLoader] = useState(false);

    const Validators = Helpers.instance;

    useEffect(() => {
        if (searchParams.get('order') !== undefined && searchParams.get('order') !== null) {
            loadOrder();
        } else {
            navigate('/orders');
        }
    }, []);

    const loadOrder = () => {
        let orderId = searchParams.get('order');
        let orders = DirectusManager.shared.directus.items('tubes_order');
        if (orderId !== null) {
            orders
                .readOne(orderId, { fields: ['*.*.*.*'] })
                .then((data: any) => {
                    if (data !== undefined && data !== null) {
                        loadContact(data.contact_id.contact_email);
                        setEmailFrom(data.contact_id.contact_email ?? 'Invalid');
                        setEmailFromName(data.contact_id.contact_name ?? 'Invalid');
                        setAttachment(data.order_number + '.pdf');
                        setOrderData(data);
                        if (data.order_number !== null) {
                            setOrderID(data.order_number);
                            let text = I18n.t('EMAIL_TUBES');
                            let replaced = text.replace('ORDER_NR', data.order_number);
                            let visualText = replaced.replaceAll('<br>', '\n');
                            setEmailText(visualText);
                        }

                        loadConfig();
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const loadContact = (email: string) => {
        let filter: any = { filter: { contact_email: { _eq: email } } };
        let contact = DirectusManager.shared.directus.items('contact_person');
        contact
            .readMany(filter)
            .then((data: any) => {
                if (data !== undefined && data !== null && data.data !== undefined && data.data.length == 1) {
                    let contact = data.data[0];
                    setKeys([
                        {
                            key: '[naam contactpersoon]',
                            value: contact.contact_name,
                        },
                        {
                            key: '[telefoon contactpersoon]',
                            value: contact.phone_number,
                        },
                    ]);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const loadConfig = () => {
        var config = DirectusManager.shared.directus.items('config');
        config
            .readMany({ limit: -1 })
            .then((data: any) => {
                var this_data = data.data[0];
                if (this_data !== undefined && this_data !== null) {
                    setEmailTo(this_data.email_multitubes);
                    setEmailToName('Multitubes');
                }
            })
            .catch((error) => {});
    };

    const checkFields = () => {
        if (emailTo == '') {
            setEmailToError(true);
            setEmailToErrorText(I18n.t('ERROR_REQUIRED'));
        } else {
            setEmailToError(false);
            if (!Validators.validateEmail(emailTo)) {
                setEmailToError(true);
                setEmailToErrorText(I18n.t('EMAIL_INVALID'));
            }
        }

        if (emailFrom == '') {
            setEmailFromError(true);
            setEmailFromErrorText(I18n.t('ERROR_REQUIRED'));
        } else {
            setEmailFromError(false);
            if (!Validators.validateEmail(emailFrom)) {
                setEmailFromError(true);
                setEmailFromErrorText(I18n.t('EMAIL_INVALID'));
            }
        }

        if (
            emailTo !== '' &&
            Validators.validateEmail(emailTo) &&
            emailFrom !== '' &&
            Validators.validateEmail(emailFrom)
        ) {
            sendEmail();
        }
    };

    const sendEmail = () => {
        setLoader(true);
        var text = emailText.replaceAll('\n', '<br>');

        if (keys.length > 0) {
            for (var i = 0; i < keys.length; i++) {
                var val = keys[i];
                text = text.replace(val.key, val.value);
            }
        }

        // The flow to send an email it's based on the following steps:
        // 1. Create PDF export
        // 2. Create email body
        // 3. Send email using the email send endpoint through the backend

        if (orderID !== '') {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    order_number: orderID,
                    auth_token: DirectusManager.shared.directus.auth.token,
                }),
            };
            fetch(DirectusManager.shared.baseUrl + '/pdf-generator', requestOptions)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (response.data !== undefined && response.data.filename !== undefined) {
                        var pdfUrl = response.data.url.split('/');
                        pdfUrl = pdfUrl[pdfUrl.length - 1];
                        var pdfName = response.data.filename.split('/');
                        pdfName = pdfName[pdfName.length - 1];

                        let body = JSON.stringify({
                            emailTo: emailTo,
                            emailToName: emailToName,
                            emailFrom: emailFrom,
                            emailFromName: emailFromName,
                            emailAttach: 'pdfs/' + pdfName,
                            emailAttachName: pdfName,
                            emailContent: '<html>' + text + '</html>',
                        });

                        //use text to send the email here with the correct API call
                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: body,
                        };
                        fetch(DirectusManager.shared.baseUrl + '/emailing/send', requestOptions)
                            .then((response) => {
                                markEmailSent();
                                setLoader(false);
                            })
                            .catch((error) => {
                                console.log(error);
                                setLoader(false);
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                            });
                    } else {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    }
                })
                .catch((error) => {
                    setLoader(false);
                    console.log(error);
                });
        } else {
            setLoader(false);
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
        }
    };

    const markEmailSent = () => {
        let tubesOrder = DirectusManager.shared.directus.items('tubes_order');
        let orderId = searchParams.get('order');
        if (orderId !== null) {
            // Status 6 it's 'order_to_multitubes' in the CMS
            tubesOrder.updateOne(orderId, { order_email_sent: true, status: 6 }).then((data: any) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ORDER_SENT'));

                setTimeout(() => {
                    navigate('/orders/tubes/' + orderId);
                }, 1000);
            });
        }
    };

    return (
        <Wrapper title={I18n.t('ORDERS')}>
            <MailTemplate
                title={I18n.t('TUBE_MAIL_TITLE') + ' ' + orderID}
                emailText={emailText}
                setEmailText={(event) => setEmailText(event)}
                orderID={orderID}
                emailFrom={emailFrom}
                emailFromError={emailFromError}
                emailFromErrorText={emailFromErrorText}
                emailFromChange={(value) => setEmailFrom(value)}
                emailTo={emailTo}
                emailToError={emailToError}
                emailToErrorText={emailToErrorText}
                emailToChange={(value) => setEmailTo(value)}
                data={orderData}
                attachment={attachment}
                keys={keys}
                sendEmail={() => checkFields()}
                loader={loader}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default TubeMail;
