import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import SimpleTable from '../../../components/tables/overview-table-simple';
import TableRow from '../../../components/tables/detail-rows-single-col';
import Helpers from '../../../helpers/functions';
import { useParams } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import { format } from 'date-fns';

const SalesProductDetail: FC = (props) => {
    const Validators = Helpers.instance;
    const { id } = useParams();
    const [productData, setProductData] = useState<any>([]);

    const [totalVolume, setTotalVolume] = useState(0);
    const [totalSales, setTotalSales] = useState(0);
    const [importDate, setImportDate] = useState('');

    useEffect(() => {
        if (id === undefined) return;
        fetchData(id);
    }, []);

    const fetchData = async (id: string) => {
        const [week, channel] = id.split('&');

        const salesManager = DirectusManager.shared.directus.items('sales');
        const salesInfoManager = DirectusManager.shared.directus.items('sales_info');
        const sales: any = await salesManager
            .readMany({
                filter: { channel: channel },
                limit: -1,
                fields: ['id', 'date_created', 'sale', 'week', 'sale_id', 'volume'],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    const sales = data.data.filter((x: any) => x.week === week);
                    return sales;
                }
            })
            .catch((error) => console.log(error));

        if (sales === undefined) return;
        // Get keys and remove duplicated
        const productKeys = sales.map((sale: any) => sale.id);
        const unique = productKeys.filter((item: any, index: any) => {
            return productKeys.indexOf(item) === index;
        });
        if (unique.length === 0) {
            return;
        }

        const products: any = await salesInfoManager
            .readMany({ filter: { id: { _in: unique } }, limit: -1 })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    const salesInfo = data.data;
                    return salesInfo;
                }
            })
            .catch((error) => console.log(error));
        if (products === undefined) return;

        var mapped = sales.map((sale: any) => {
            return {
                col1: products?.find((prod: any) => prod.id === sale.id).item_desc,
                col2: sale.volume !== null && sale.volume !== undefined ? Validators.amountDisplay(sale.volume) : 0,
                col3: sale.sale !== null && sale.sale !== undefined ? Math.round(sale.sale * 100) / 100 : 0,
            };
        });

        // Accumulators of the volumes and sales in the group
        const volume = sales.reduce((partialSum: number, a: any) => {
            const checkValue = a.volume !== null && a.volume !== undefined ? +a.volume : 0;
            return partialSum + checkValue;
        }, 0);

        const sale = sales.reduce((partialSum: number, a: any) => {
            const checkValue = a.sale !== null && a.sale !== undefined ? +a.sale : 0;
            return partialSum + checkValue;
        }, 0);

        mapped = mapped.sort((x: any, y: any) => (parseInt(x.col2) < parseInt(y.col2) ? 1 : -1));

        setProductData(mapped);
        setTotalSales(Math.round(sale * 100) / 100);
        setTotalVolume(Math.round(volume * 100) / 100);
        setImportDate(format(new Date(sales[0].date_created), 'dd-MM-yyyy HH:mm'));
    };

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <div className="d-flex sales-import-overview">
                <SimpleTable
                    title={I18n.t('SALES_OVERVIEW_PRODUCTS')}
                    type="insights-sales-products"
                    columns={{
                        headings: [
                            {
                                Header: I18n.t('PRODUCT'),
                                accessor: 'col1',
                                type: 'name',
                                Link: false,
                            },
                            {
                                Header: I18n.t('TABLE_VOLUME'),
                                accessor: 'col2',
                                type: 'volume',
                                Link: false,
                            },
                            {
                                Header: I18n.t('TABLE_SALES'),
                                accessor: 'col3',
                                type: 'price',
                                Link: false,
                            },
                        ],
                    }}
                    data={productData}
                />
                <TableRow
                    autoHeight
                    rows={[
                        {
                            title: I18n.t('TOTAL_VOLUME'),
                            value: Validators.amountDisplay(totalVolume),
                        },
                        {
                            title: I18n.t('TOTAL_SALES'),
                            value: Validators.priceDisplay(totalSales),
                        },
                        {},
                        {
                            title: I18n.t('IMPORT_DATE'),
                            value: importDate,
                        },
                    ]}
                />
            </div>
        </Wrapper>
    );
};

export default SalesProductDetail;
