import React, { FC, useEffect, useState } from 'react';
import I18n from '../../../../helpers/i18n';
import { useTable } from 'react-table';
import Select from '../../../select';
import InputField from '../../../input';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import Tooltip from '../../../tooltip';
import Button from '../../../button';
import moment from 'moment';
import Alert from '../../../alert';
import DirectusManager from "../../../../helpers/directusManager";
interface Props {
    availableProducts: any,
    productsAdded: any,
    updateProducts: (array: any) => void,
    deleteProduct: (id: number) => void,
    saveProducts: () => void,
    changeAllowed: boolean,
    tubes?: boolean,
    factory?: boolean,
    supplier?: boolean,
    availableSuppliers?: any
}

const ProductInfo : FC<Props> = (props) => {
    const [changeDetected, setChangeDetected] = useState(false);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRow, setDeleteRow] = useState<any>();

    var headings = [] as any;
    if(props.tubes){
        headings = [
            {
                Header: I18n.t('PRODUCT'),
                accessor: 'col1',
                Type: 'text'
            },
            {
                Header: I18n.t('PACKAGING_CODE'),
                accessor: 'col2',
                Type: 'text'
            },
            {
                Header: I18n.t('AMOUNT'),
                accessor: 'col3',
                Type: 'input'
            },
        ];
    } else if(props.factory){
        headings = [
            {
                Header: I18n.t('PRODUCT'),
                accessor: 'col1',
                Type: 'text'
            },
            {
                Header: I18n.t('ARTICLE_CODE'),
                accessor: 'col2',
                Type: 'text'
            },
            {

                Header: I18n.t('FACTORY_CODE'),
                accessor: 'col3',
                Type: 'text'
            },
            {
                Header: I18n.t('PURCHASE_PRICE'),
                accessor: 'col4',
                Type: 'text'
            },
            {
                Header: I18n.t('AMOUNT'),
                accessor: 'col5',
                Type: 'input'
            },
            {
                Header: I18n.t('PURCHASE_PRICE_TOTAL'),
                accessor: 'col6',
                Type: 'text'
            },
        ];
    } else if(props.supplier){
        headings = [
            {
                Header: I18n.t('PRODUCT'),
                accessor: 'col1',
                Type: 'text'
            },
            {
                Header: I18n.t('ARTICLE_CODE'),
                accessor: 'col2',
                Type: 'text'
            },
            {
                Header: I18n.t('FACTORY_CODE'),
                accessor: 'col3',
                Type: 'text'
            },
            {
                Header: I18n.t('AMOUNT'),
                accessor: 'col4',
                Type: 'input'
            },
        ];
    }

    const columns = React.useMemo(() => headings, []) as any;
    const [data, setData] = useState<any>([]);

    const [dropdownSelectedProductDefault, setDropdownSelectedProductDefault] = useState<any>('none');
    var dropdownSelectedProduct = 0;

    useEffect(() => {
        setData(props.productsAdded);
    }, []);

    const addProduct = () => {
        var products = props.availableProducts;
        var newArray = props.productsAdded.slice(0);
        var product = {};

        for (var i = 0; i < products.length; i++) { 
            if(products[i].id == dropdownSelectedProduct){
                if(props.tubes) {
                    product = {
                        col1: products[i].name,
                        col2: products[i].packaging_code,
                        col3: products[i].min_amount !== '-' ? products[i].min_amount : 1,
                        id: products[i].id,
                        min_amount: products[i].min_amount,
                        isNew: true
                    };
                } else if(props.factory) {
                    product = {
                        col1: products[i].name,
                        col2: products[i].article_code,
                        col3: products[i].factory_code,
                        col4: -1,
                        col5: products[i].min_amount !== '-' ? products[i].min_amount : 1,
                        id: products[i].id,
                        min_amount: products[i].min_amount,
                        isNew: true
                    };
                } else if(props.supplier) {
                    product = {
                        col1: products[i].name,
                        col2: products[i].article_code,
                        col3: products[i].factory_code,
                        col4: products[i].min_amount !== '-' ? products[i].min_amount : 1,
                        id: products[i].id,
                        min_amount: products[i].min_amount,
                        isNew: true
                    };
                }
                
                newArray.push(product);
                if(props.updateProducts){
                    setChangeDetected(true);
                    props.updateProducts(newArray);
                }
            }
        }
    }

    const updateAmount = (amount: number, id: any) => {
        var productsAdded = props.productsAdded;

        if(productsAdded[id].min_amount !== undefined && amount < productsAdded[id].min_amount) {
            return;
        }
        if(props.factory) {
            productsAdded[id].col5 = amount;
        } else if(props.supplier) {
            productsAdded[id].col4 = amount;
        } else {
            productsAdded[id].col3 = amount;
        }
        setChangeDetected(true);
        props.updateProducts(productsAdded);
    }

    const updateSupplier = (supplier: string, id: any) => {
        var productsAdded = props.productsAdded;

        productsAdded[id].supplier = supplier;
        productsAdded[id].col4 = parseInt(supplier);
        setChangeDetected(true);
        props.updateProducts(productsAdded);
    }

    const showConfirmModalDelete = (rowkey: any) => {
        setDeleteRow(rowkey);
        setShowDeleteModal(true);
    }

    const deleteProduct = () => {
        setShowDeleteModal(false);
        
        const index = deleteRow.charAt(deleteRow.length - 1);
        if(props.deleteProduct) {
            props.deleteProduct(props.productsAdded[index].id);
        }
    }

    function ProductTable ({columns, data} : {columns: any, data: any}){
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            rows,
            prepareRow,
        } = useTable(
            {
                columns,
                data,
            },
        );  

        useEffect(() => {
            setData(props.productsAdded);
        }, [props.productsAdded]);

        return (
            <table {...getTableProps()} className="table">
                <thead>
                    {headerGroups.map(headerGroup => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map(column => (
                            <th {...column.getHeaderProps()}>
                                {column.render('Header')}
                            </th>
                        ))}
                        {props.changeAllowed &&
                            <th className='actions'></th>
                        }
                    </tr>
                    ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                    {rows.map(row => { prepareRow(row)
                        var rowkey = row.getRowProps().key;
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    if(cell.render('Type') == 'input' && props.changeAllowed){
                                        return (
                                            <td {...cell.getCellProps()} className='minp'>
                                                <InputField
                                                    defaultValue={cell.value}
                                                    disableActiveLabel
                                                    onChange={(value: any) => updateAmount(value, row.index)}
                                                    minValue={cell.row.original?.min_amount ?? 0}
                                                />
                                                {(cell.row?.original?.min_amount !== 0) &&
                                                    <span className='min-amount'>
                                                        {I18n.t('MIN_AMOUNT') + ' ' + cell?.row?.original?.min_amount}
                                                    </span>
                                                }
                                            </td>
                                        )
                                    } else if(cell.render('Type') == 'select' && props.changeAllowed){
                                        return (
                                            <td {...cell.getCellProps()} className='minp'>
                                                <Select
                                                    value={cell.value}
                                                    defaultValue={cell.value}
                                                    array={props.availableSuppliers}
                                                    label={I18n.t('TABLE_SELECT')}
                                                    onChange={(value: any) => updateSupplier(value, row.index)}
                                                />
                                            </td>
                                        )
                                    } else {
                                        return (
                                            <td {...cell.getCellProps()}>
                                                {cell.render('Cell')}
                                            </td>
                                        )
                                    }
                                })}
                                {props.changeAllowed &&
                                    <td className='actions'>
                                        <div className='d-flex align-items-center justify-content-center' onClick={() => showConfirmModalDelete(rowkey)}>
                                            <Tooltip
                                                text={I18n.t('DELETE_ROW')}
                                            >
                                                <RemoveCircleIcon/>
                                            </Tooltip>
                                        </div>
                                    </td>
                                }
                            </tr>
                        )
                    })}
                    {(props.availableProducts && props.changeAllowed) &&
                        <tr>
                            <td colSpan={props.tubes ? 2 : props.factory ? 4 : 3} className='minp'>
                                <Select
                                    label={I18n.t('CHOOSE_PRODUCT')}
                                    value={dropdownSelectedProductDefault}
                                    array={props.availableProducts}
                                    onChange={(value: any) => dropdownSelectedProduct = value}
                                />
                            </td>
                            <td colSpan={2} className='minp btn'>
                                <Button
                                    altStyle
                                    icon={<AddIcon/>}
                                    title={I18n.t('ADD_PRODUCT')}
                                    onPress={() => addProduct()}
                                />
                            </td>
                        </tr>
                    }
                </tbody>
            </table>
        )
    };

    const getStyle = () => {
        var style = "table-wrapper detail";

        if(props.factory){
            style = style + " adjust-width";
        }
        return style;
    }

    return (
        <div className={getStyle()}>
            <div className="table-header filled d-flex justify-content-between align-items-center">
                <span className="title">
                    {I18n.t('PRODUCT_INFO')}
                </span>
            </div>
            <ProductTable
                columns={columns}
                data={data}
            />
            {props.changeAllowed &&
                <div className='d-flex justify-content-end save-order-row'>
                    <Button
                        defaultStyle
                        disabled={changeDetected ? false : true}
                        title={I18n.t('SAVE_PRODUCTS')}
                        onPress={() => {setChangeDetected(false); props.saveProducts && props.saveProducts()}}
                    />
                </div>
            }
            <Alert
                visible={showDeleteModal}
                onConfirm={() => deleteProduct()}
                onCancel={() => setShowDeleteModal(false)}
                title={I18n.t('DELETE_PRODUCT')}
                text={I18n.t('DELETE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_DELETE')}
                cancelText={I18n.t('ALERT_CANCEL_DELETE')}
                warning
            />
        </div>
    );
}
export default ProductInfo;