import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import translationEN from "../languages/en.json";
import translationNL from "../languages/nl.json";
import detector from "i18next-browser-languagedetector";

const resources = {
    en: {
        translation: translationEN
    },
    nl: {
        translation: translationNL
    }
  };
  
  i18n
    .use(detector)
    .use(initReactI18next as any) // passes i18n down to react-i18next
    .init({
        resources,
        fallbackLng: "en", // use en if detected lng is not available

        keySeparator: false, // we do not use keys in form messages.welcome

        interpolation: {
        escapeValue: false // react already safes from xss
        }
});
  
export default i18n;