import React, { FC, useEffect, useState } from 'react';
import I18n from '../../../helpers/i18n';
import Button from '../../button';
import moment from 'moment';
import Select from '../../select';
import InputField from '../../input';
import UploadField from '../../upload-field';
import ToolTip from '../../../components/tooltip';
import CloseIcon from '@mui/icons-material/Close';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import DirectusManager from '../../../helpers/directusManager';
import Alert from '../../../components/alert';
interface Props {
    title: string,
    save?: () => void,
    saveButtonText?: string,
    //if no size is specified, will use the fullwidth option
    midSize?: boolean,
    largeSize?: boolean,
    doubleColumn?: boolean,

    leftRows?: any,
    rightRows?: any,

    loading?: boolean,
    fileSelected?: (file: any) => void,
    deleteFile?:() => void
}

const DetailTable : FC<Props> = (props) => {
    const [showDeleteFileModal, setShowDeleteFileModal] = useState(false);

    const openFile = (fileUrl: any) => {
        if(fileUrl !== null) {
            window.open(DirectusManager.shared.baseUrl+'/assets/'+fileUrl.filename_disk+'?access_token='+DirectusManager.shared.directus.auth.token);
        }
    }

    const deleteFile = () => {
        if(props.deleteFile) {
            props.deleteFile();
        }
    }

    const table = (rows: any) => {
        return (
            <table className='table'>
                <thead></thead>
                <tbody>
                    {rows.length > 0 && rows.map((item: any) => {
                        if(item.condition !== null && item.condition !== undefined && !item.condition){
                            return null;
                        } else if(item.title == undefined){
                            return (
                                <div className="empty-space"></div>
                            )
                        } else {
                            return (
                                <tr key={item.id}>
                                    <td className='td-heading'>
                                        {item.title}{(item.mandatory) && <span className="mandatory-star">*</span>}
                                        {item.error &&
                                            <span className='error-field'>
                                                {item.errorText}
                                            </span>
                                        }
                                    </td>
                                    {
                                    item.customComponent ?
                                        <td className='td-inner'>
                                            {item.customComponent}
                                        </td>
                                    :
                                    item.input ?
                                        <td className='td-inner'>
                                            <InputField
                                                disableActiveLabel
                                                value={item.value}
                                                onChange={(val) => item.onChange(val)}
                                                numeric={item.number ? true : false}
                                                disabled={item.disabled ? true : false}
                                                error={item.error}
                                                price={item.price? true: false}
                                            />
                                        </td>   
                                    :
                                    item.select ?
                                        <td className='td-inner'>
                                            {item.array.length > 0 ?
                                                <Select
                                                    value={item.value}
                                                    array={item.array}
                                                    defaultValue={item.value}
                                                    onChange={(id: any) => item.onChange(id)}
                                                    label={item.label}
                                                />
                                            : 
                                                item.emptyMessage
                                            }
                                        </td>
                                    :
                                    item.upload ?
                                        <td className='td-inner'>
                                            {item.value ?
                                                <div className="d-flex justify-content-between">
                                                    <div className='file-wrapper' onClick={() => openFile(item.value)}>
                                                        <ToolTip
                                                            text={I18n.t('VIEW_FILE')}
                                                        >
                                                            <div className='d-flex align-items-center'>
                                                                <InsertDriveFileIcon/>
                                                                <span className='file-name'>{item.fileName}</span>
                                                            </div>
                                                        </ToolTip>
                                                        
                                                    </div>
                                                    <div className='remove-file-icon' onClick={() => setShowDeleteFileModal(true)}>
                                                        <ToolTip
                                                            text={I18n.t('DELETE_FILE')}
                                                        >
                                                            <CloseIcon/>
                                                        </ToolTip>
                                                    </div>
                                                </div>
                                            :
                                                <div className='d-flex align-items-start'>
                                                    <UploadField fileSelected={props.fileSelected}/>
                                                </div>
                                            }
                                        </td>
                                    :
                                        <td className='td-inner text-only'>
                                            {item.value}
                                        </td>
                                    }
                                </tr>
                            )
                        }
                    })}
                </tbody>
            </table>
        )
    }
    return (
        <div className="pb-5">
            <div className={`table-wrapper ${props.midSize ? "mid-size" : ''} ${props.largeSize ? "large-size" : ''}`}>
                <div className="table-header filled d-flex justify-content-between align-items-center">
                    <span className="title">
                        {props.title}
                    </span>
                </div>
                {!props.loading &&
                <>
                    <div className='d-flex justify-content-between double-rows align-items-start'>
                        {table(props.leftRows)}
                        {table(props.rightRows)}
                    </div>
                    {(props.saveButtonText && props.save) &&
                        <div className='d-flex justify-content-between align-items-center save-order-row'>
                             <div className='mantadory-explanation'>
                                <span className="mandatory-star">*</span> {I18n.t('THESE_FIELDS_ARE_MANDATORY')}
                            </div>
                            <Button
                                defaultStyle
                                title={props.saveButtonText}
                                onPress={() => props.save && props.save()}
                            />
                        </div>
                    }
                </>
                }
            </div>
            <Alert
                visible={showDeleteFileModal}
                onConfirm={() => {setShowDeleteFileModal(false); deleteFile()}}
                onCancel={() => setShowDeleteFileModal(false)}
                title={I18n.t('DELETE_FILE')}
                text={I18n.t('DELETE_FILE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_DELETE')}
                cancelText={I18n.t('ALERT_CANCEL_DELETE')}
                warning
            />
        </div>
    );
}
export default DetailTable;