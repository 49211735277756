import React, { FC, useEffect } from 'react';
import Sidebar from '../sidebar';
import Footer from '../footer';
import PageHeader from '../page-header';
import I18n from '../../helpers/i18n';
interface Props {
    title?: string
}

const Wrapper : FC<Props> = (props) => {
    useEffect(() => {
        document.title = I18n.t('SITE_NAME')
    }, []);

    return (
        <div>
            <div className="d-flex" id="main">
                <Sidebar/>
                <div id="content-wrapper">
                    <PageHeader
                        title={props.title}
                    />
                    {props.children}
                </div>
            </div>
            <Footer/>
        </div>
    );
}
export default Wrapper;