import React, { FC, useState } from 'react';
import I18n from '../../helpers/i18n';
import FileUploadIcon from '@mui/icons-material/FileUpload';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';

interface Props {
    fileSelected?: (file: any) => void,
}
const InputField : FC <Props> = (props) => {
    const [changeTriggered, setChangeTriggered] = useState(false);
    const [file, setFile] = useState(null);
    const [fileName, setFileName] = useState('');

    const onChange = (event: any) => {
        setChangeTriggered(true);
        
        if(event.target.files.length > 0) {
            setFile(event.target.files[0]);
            setFileName(event.target.files[0].name);

            if(props.fileSelected) {
                props.fileSelected(event.target.files[0]);
            }
        }
    }

    return (
        <div className='upload-field'>
            <input 
                type='file'
                name="upload"
                id="upload"
                accept="image/png, image/jpeg, pdf"
                onChange={onChange}
            />
            <label htmlFor="upload">
                
                {file == null ?
                    <>
                        <FileUploadIcon className='upload-icon'/>
                        {I18n.t('SELECT_FILE')}
                    </>
                :
                    <>
                        <InsertDriveFileIcon className='file-icon'/>
                        {fileName}
                    </>
                }
            </label>
        </div>
      );
}
export default InputField;