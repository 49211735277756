
import React, { useState, FC, useEffect } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Button from '../../../components/button';
import InputFile from '../../../components/inputFile';
import DirectusManager from '../../../helpers/directusManager';
import { useNavigate } from 'react-router-dom';
import Select from '../../../components/select';

const NewImport : FC = (props) => {
    const navigate = useNavigate();
    const [file, setFile] = useState<File | null>(null);
    const [channelsAvailables, setChannelsAvaibales] = useState<{id: number, name: string}[]>([]);
    const [channel, setChannel] = useState(0);
    const [disabledButton, setDisabledButton] = useState(true);
    const [errorForm, setErrorForm] = useState(false);

    useEffect(() => {
        if (file !== null && channel !== 0) {
            setDisabledButton(false);
        } else {
            setDisabledButton(true);
        }
    }, [file, channel])

    useEffect(() => {
        const channels = DirectusManager.shared.directus.items('channels');
        channels.readMany({limit: -1})
            .then((data: any) => {
                if(data !== undefined && data.data !== undefined && data.data !== null) {
                    const mapped = [{id: 0, name: I18n.t('INPUT_SELECT_CHANNEL')}]
                                    .concat(data.data.map((x: any) => {
                                            return {
                                                id: x.id,
                                                name: x.name
                                            }
                                        })
                                    )
                    
                    setChannelsAvaibales(mapped);
                }
            })
            .catch( (error: any) => {
            });
    }, [])

    const handleUpload = () => {
        if (file === null || channel === 0) return
        setErrorForm(false);

        const myHeaders = new Headers();
        myHeaders.append('Authorization', `Bearer ${DirectusManager.shared.directus.storage.auth_token}`);

        const formdata = new FormData();
        formdata.append('file', file, file.name);
        formdata.append('channel_id', channel.toString());

        const requestOptions = {
            method: 'POST',
            headers: myHeaders,
            body: formdata,
        };

        fetch(`${DirectusManager.shared.baseUrl}/xlsx-parser/parse`, requestOptions)
            .then(response => {
                if (response.status === 200) {
                    setErrorForm(false);
                    navigate('/insights/sales');
                } else {
                    setErrorForm(true);
                }
            })
            .catch(error => {
                setErrorForm(true);
                console.log('error', error);
            });
    }

    return (
        <Wrapper
            title={I18n.t("INSIGHTS")}
        >
            <div className="d-flex sales-new-import">
                <div className="pb-5">
                    <div className="table-wrapper min-width">
                        <div className="table-header filled d-flex justify-content-between align-items-center">
                            <span className="title">
                                {I18n.t('SALES_NEW_FILE')}
                            </span>
                        </div>
                        <div className='d-flex justify-content-between flex-column double-rows align-items-start save-order-row'>
                            <InputFile 
                                fileTypes={[
                                    {
                                        name: '.xlsx',
                                        format: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
                                    }
                                ]} 
                                setFile={(file) => setFile(file)}
                                file={file}
                            />
                        </div>
                        <div className='d-flex justify-content-between align-items-center save-order-row'>
                            <div className='mantadory-explanation'>
                                {
                                    errorForm &&
                                    <span>{I18n.t('ERROR_UNKNOWN')}</span>
                                }
                            </div>
                            <div className='d-flex'>
                                <div className="me-3">
                                    <Select
                                        label={I18n.t('INPUT_SELECT_CHANNEL')}
                                        value={channel}
                                        array={channelsAvailables}
                                        onChange={(value: any) => setChannel(parseInt(value.target.value))}
                                    />
                                </div>
                                <Button
                                    defaultStyle
                                    title={I18n.t('SALES_UPLOAD')}
                                    onPress={handleUpload}
                                    disabled={disabledButton}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Wrapper>
    );
}

export default NewImport;
