import React, { useState, FC, useEffect, forwardRef, useImperativeHandle } from 'react';
import DoneIcon from '@mui/icons-material/Done';
import CloseIcon from '@mui/icons-material/Close';

interface Props {
    noSidebar?: boolean
}

const SnackBar : FC <Props> = (props, ref) => {
    const [visible, setVisible] = useState(false);
    const [text, setText] = useState('');

    let timer: any = null;

    useEffect(() => {
        if(visible){
            clearTimeout(timer);
            timer = setTimeout(() => {
                setVisible(false);
            }, 2000);
        }
    }, [visible]);

    useImperativeHandle(
        ref,
        () => ({
            show(text: string) {
                setText(text);
                clearTimeout(timer);
                timer = setTimeout(() => {
                    setVisible(true);
                }, 200);
            }
        })
    )

    const getStyle = () => {
        if(props.noSidebar){
            return visible ? 'snackbar max-width open' : 'snackbar max-width';
        } else {
            return visible ? 'snackbar open' : 'snackbar';
        }
    }

    return (
        <div className={getStyle()}>
            <div className='wrapper d-flex justify-content-between'>
                <div className='content d-flex align-items-center'>
                    <DoneIcon/>
                    <span className="text">
                        {text}
                    </span>
                </div>
                <div className="close-icon d-flex align-items-center" onClick={() => setVisible(false)}>
                    <CloseIcon/>
                </div>
            </div>
        </div>
    );
}
 // @ts-ignore:next-line
export default forwardRef(SnackBar);