import React, { FC, useState, useRef } from 'react';
import UploadLogo from '../../images/file_upload.svg';
import I18n from '../../helpers/i18n';
import { FileDrop } from 'react-file-drop';

interface Props {
    fileTypes: {name: string, format: string}[]
    setFile: (file: File | null) => void
    file: File | null
}
const InputFile: FC <Props> = (props) => {
    const fileInputRef = useRef(null);
    const [errorAllowed, setErrorAllowed] = useState(false);

    const onFileInputChange = (e: any) => {
        props.setFile(e.target.files[0])
    }

    const dropFile = (files: File[]) => {
        setErrorAllowed(false)
        const alloweds = props.fileTypes.map(type => type.name)
        const found = alloweds.find(allowed => files[0].name.includes(allowed))

        if (found !== undefined) {
            props.setFile(files[0])
        } else {
            props.setFile(null)
            setErrorAllowed(true)
        }
    }

    const onTargetClick = () => {
        // @ts-ignore
        fileInputRef.current.click()
    }

    return (
        <div className='input-field file-upload'>
            <input
                onChange={onFileInputChange}
                ref={fileInputRef}
                type="file"
                className="hidden"
                accept={props.fileTypes.map(type => type.format).toString()}
            />
            <FileDrop
                onDrop={(files: any, event: any) => dropFile(files)}
                onTargetClick={onTargetClick}
            >
                <img 
                    src={UploadLogo} 
                    alt="Upload"
                    className='icon-upload'
                />
                <p>{I18n.t('INPUT_FILE_CLICK_OR_DROP')}</p>
                <p className={`${errorAllowed ? 'error-allowed' : ''}`}>
                    {I18n.t('INPUT_FILE_ALLOWED')}:{' '}
                    {
                        props.fileTypes.map((type, index) => {
                            return index === 0 ?
                                <span>{type.name}</span> :
                                <span>, {type.name}</span>
                        })
                    }
                </p>
                {
                    props.file === null ?
                    <p>{I18n.t('INPUT_NO_FILE_SELECTED')}</p>:
                    <p>
                        {I18n.t('INPUT_FILE_SELECTED')}: {' '}
                        <span className='selected-file'>{props.file.name}</span>
                    </p>
                    
                }
            </FileDrop>
        </div>
      );
}
export default InputFile;