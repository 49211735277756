
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../components/wrapper';
import DirectusManager from '../../helpers/directusManager';
import {
    useNavigate
} from "react-router-dom";
import I18n from '../../helpers/i18n';
import Table from '../../components/tables/overview-table-full';

const HomePage : FC = (props) => {
    const navigate = useNavigate();

    useEffect(() => {
        if(DirectusManager.shared.isLoggedIn() == false) {
            navigate('/login');
        };

        var factoryOrders = DirectusManager.shared.directus.items('factory_order');
        factoryOrders.readMany({fields:['*'], meta: 'total_count'}).then( orders => {
            
        }).catch( error => {
            if(error !== undefined && error.message === 'Token expired.') {
                navigate('/login');
            }
        });
    }, []);

    return (
        <Wrapper
            title={I18n.t('HOME_DASHBOARD')}
        >
            
        </Wrapper>
    );
}

export default HomePage;