import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import SimpleTable from '../../../components/tables/overview-table-simple';
import TableRow from '../../../components/tables/detail-rows-single-col';
import Helpers from '../../../helpers/functions';
import { useParams } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import { format } from 'date-fns';

const SalesProductDetail: FC = (props) => {
    const { id } = useParams();
    const Validators = Helpers.instance;
    const [productData, setProductData] = useState<any>([]);

    const [totalVolume, setTotalVolume] = useState(0);
    const [totalSales, setTotalSales] = useState(0);

    useEffect(() => {
        const salesManager = DirectusManager.shared.directus.items('sales');
        salesManager
            .readMany({
                filter: { id: id },
                limit: -1,
                fields: ['channel.name', 'volume', 'sale', 'date_created', 'week'],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    const sales = data.data;
                    var mapped = sales.map((sale: any) => {
                        const week = `${sale.week.substr(4)}-${sale.week.substr(0, 4)}`;
                        return {
                            col1: sale.channel.name,
                            col2: sale.volume ?? '0',
                            col3: sale.sale ?? '0',
                            col4: format(new Date(sale.date_created), 'dd-MM-yyyy kk:mm'),
                            col5: week,
                        };
                    });

                    mapped = mapped.sort((x: any, y: any) => (x.col2 > y.col2 ? 1 : -1));
                    setProductData(mapped);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        var volume = 0;
        var sales = 0;

        for (var i = 0; i < productData.length; i++) {
            volume = volume + parseInt(productData[i].col2);
            sales = sales + parseFloat(productData[i].col3);
        }

        setTotalSales(sales);
        setTotalVolume(volume);
    }, [productData]);

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <div className="d-flex sales-product-overview">
                <SimpleTable
                    title={I18n.t('SALES_OVERVIEW_PRODUCTS')}
                    type="insights-sales-products"
                    columns={{
                        headings: [
                            {
                                Header: I18n.t('TABLE_CHANNEL'),
                                accessor: 'col1',
                                type: 'name',
                                Link: false,
                            },
                            {
                                Header: I18n.t('TABLE_VOLUME'),
                                accessor: 'col2',
                                type: 'volume',
                                Link: false,
                            },
                            {
                                Header: I18n.t('TABLE_SALES'),
                                accessor: 'col3',
                                type: 'price',
                                Link: false,
                            },
                            {
                                Header: I18n.t('ADDED'),
                                accessor: 'col4',
                                type: 'date',
                                Link: false,
                            },
                            {
                                Header: I18n.t('TABLE_WEEK'),
                                accessor: 'col5',
                                type: 'week',
                                Link: false,
                            },
                        ],
                    }}
                    data={productData}
                />
                <TableRow
                    autoHeight
                    rows={[
                        {
                            title: I18n.t('TOTAL_VOLUME'),
                            value: Validators.amountDisplay(totalVolume),
                        },
                        {
                            title: I18n.t('TOTAL_SALES'),
                            value: Validators.priceDisplay(totalSales),
                        },
                    ]}
                />
            </div>
        </Wrapper>
    );
};

export default SalesProductDetail;
