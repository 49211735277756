import React, { FC, useState } from 'react';
import ReactSelect from 'react-select'
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import CloseIcon from '@mui/icons-material/Close';
import Tooltip from '../tooltip';
import I18n from '../../helpers/i18n';
import DateRangeIcon from '@mui/icons-material/DateRange';
import EventIcon from '@mui/icons-material/Event';

interface Props {
    onChange?: (event: any) => void;
    onBlur?: (event: any) => void;
    value: any;
    secure?: boolean;
    email?: boolean;
    error?: boolean;
    errorText?: string;
    label?: string;
    maxLength?: number;
    textArea?: boolean;
    disabled?: boolean;
    array?: any;
    clickable?: boolean;
    onClick?: () => void;
    resetFilter?: () => void;
    canReset?: boolean;
    onClose?: () => void;
    defaultValue?: string;
    useValue?: boolean;
    dateRange?: boolean;
    dateSingle?: boolean;
}
const Select: FC<Props> = (props) => {
    const options = props.array?.map((option: {id: string, name: string}) => {
        return {label: option.name, value: option.id};
    });

    return (
        <div className="select-field">
            <div
                className={
                    props.error ? 'select-wrapper error' : 'select-wrapper'
                }
            >
                {props.clickable ? (
                    <div className="select clickable" onClick={props.onClick}>
                        <div className="label">
                            {props.label}
                            {props.canReset && (
                                <div
                                    className="reset-filter"
                                    onClick={() =>
                                        props.resetFilter && props.resetFilter()
                                    }
                                >
                                    <Tooltip text={I18n.t('RESET_FILTER')}>
                                        <CloseIcon />
                                    </Tooltip>
                                </div>
                            )}
                        </div>
                    </div>
                ) : (options?.length > 0 &&
                        <ReactSelect
                            onChange={(newValue) => {
                                if (!props.onChange) {
                                    return;
                                }

                                if (typeof newValue !== 'object' || !newValue.hasOwnProperty('value')) {
                                    props.onChange(newValue)
                                }

                                props.onChange(newValue.value)
                            }}
                            isClearable={props.canReset}
                            isDisabled={props.disabled}
                            onBlur={props.onBlur}
                            options={options}
                            defaultValue={options.find((option: {value: string}) => option.value === props.value)}
                            value={options.find((option: {value: string}) => option.value === props.value)}
                        />
                )}
            </div>
            {props.error && (
                <span className="error-text d-block mb-3">
                    {props.errorText}
                </span>
            )}
        </div>
    );
};
export default Select;
