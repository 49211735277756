import React, {useState, FC, useRef, useEffect} from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/detail-rows-single-col';
import {useNavigate, useParams} from "react-router-dom";
import DirectusManager from '../../../helpers/directusManager';
import Snackbar from '../../../components/snackbar';

const CountryForm: FC = (props) => {
    const {id} = useParams<{ id: string | undefined }>();

    const navigate = useNavigate();

    const [loader, setLoader] = useState(false);

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');

    const [code, setCode] = useState('');
    const [codeError, setCodeError] = useState(false);
    const [codeErrorText, setCodeErrorText] = useState('');

    const snackBar = useRef();


    useEffect(() => {
        if (id !== undefined) {
            setLoader(true);
            DirectusManager.shared.directus.items('countries')
                .readOne(id, {fields: ['*']}).then((data: any) => {
                if (data !== undefined && data !== null) {
                    setName(data.name);
                    setCode(data.code);
                    setLoader(false);
                }
            }).catch(error => {
                setLoader(false);
            });
        }
    }, [id]);

    const save = () => {
        setNameError(false);
        setCodeError(false);

        if (name === '') {
            setNameError(true);
            setNameErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if (code === '') {
            setCodeError(true);
            setCodeErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if (name !== '' && code !== '') {
            const countryRepo = DirectusManager.shared.directus.items('countries');
            let action;
            if (id !== undefined) {
                // id, params
                action = countryRepo.updateOne(id, {name, code});
            } else {
                // params
                action = countryRepo.createOne({name, code});
            }

            action
                .then(data => {
                    navigate('/settings/countries', {state: id ? 'updated' : 'new'});
                }).catch((error: any) => {
                if (error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE') {
                    setCodeError(true);
                    setCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_CODE'));
                } else {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                }
            });
        }
    }

    return (
        <Wrapper
            title={id ? I18n.t("EDIT_COUNTRY") : I18n.t("NEW_COUNTRY")}
        >
            <Table
                midSize
                title={I18n.t('COUNTRY_INFO')}
                save={() => save()}
                saveButtonText={id ? I18n.t('UPDATE_COUNTRY') : I18n.t('ADD_COUNTRY')}
                rows={[
                    {
                        title: I18n.t('TABLE_NAME'),
                        value: name,
                        onChange: (val: any) => setName(val),
                        input: true,
                        mandatory: true,
                        error: nameError,
                        errorText: nameErrorText
                    },
                    {
                        title: I18n.t('TABLE_CODE'),
                        value: code,
                        onChange: (val: any) => setCode(val),
                        input: true,
                        mandatory: true,
                        error: codeError,
                        errorText: codeErrorText
                    },
                ]}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default CountryForm;
