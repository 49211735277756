
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import { Link } from "react-router-dom";
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';
import { useNavigate, useLocation } from "react-router-dom";
import Helpers from '../../../helpers/functions';
import Snackbar from '../../../components/snackbar';

const ContactOverview : FC = (props) => {
    const Validators = Helpers.instance;
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);

    const location = useLocation() as any;
    const navigate = useNavigate() as any;
    const snackBar = useRef();

    useEffect(() => {
        getData();

        if(location?.state == 'new'){
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_CONTACT_CREATED'));
        }
    },[]);

    const getData = (query?: string) => {
        var filter: any = {};

        if(query) {
            filter = Validators.searchStringFilter('contact_name', query);
        }
        setLoader(true);
        let sort: any = ['contact_name'];
        var contacts = DirectusManager.shared.directus.items('contact_person');
        contacts.readMany({limit: -1, filter, sort: sort}).then( data => {
            if(data !== undefined && data.data !== undefined && data.data !== null) {
                let mapped = data.data.map((x: any) => 
                ({
                    col1: x.contact_name,
                    col2: I18n.t("STATUS_" + x.status.toUpperCase()),
                    col3: x.date_updated ? moment(x.date_updated).format('DD-MM-YYYY HH:mm') : "-",
                    id: x.contact_id
                }));
                setData(mapped);
                setLoader(false);
            }
        }).catch( error => {
            setLoader(false);
        });
    }

    const confirmDelete = (id: number) => {
        var toDelete = DirectusManager.shared.directus.items('contact_person');
        toDelete.deleteOne(id).then(data => {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ITEM_DELETED'));
            getData();
        }).catch((error: any) => {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
        });
    }

    const filterText = (value: any) => {
        getData(value);
    }

    return (
        <Wrapper
            title={I18n.t("SETTINGS")}
        >
            <Table
                title={I18n.t("NAV_CONTACTS")}
                showActions={true}
                type="contacts"
                allowNew={true}
                loading={loader}
                newTitle={I18n.t('NEW_CONTACT')}
                columns={{
                    "headings": [
                        {
                            Header: I18n.t('TABLE_NAME'),
                            accessor: 'col1',
                            type: 'name',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_STATUS'),
                            accessor: 'col2',
                            type: 'status',
                            Link: false
                        },
                        {
                            Header: I18n.t('TABLE_CHANGED'),
                            accessor: 'col3',
                            type: 'changed',
                            Link: false
                        },
                    ]
                }}
                data={data}
                confirmDelete={(id: number) => confirmDelete(id)}
                filterText={(val: string) => filterText(val)}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default ContactOverview;