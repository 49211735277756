import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '../../../components/tables/order-detail';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';
import * as Sentry from '@sentry/react';

const TubeOrderDetail: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams<{ id: any }>();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        reloadOrder();
    }, []);

    const reloadOrder = () => {
        let orders = DirectusManager.shared.directus.items('supplier_orders');
        orders
            .readOne(params.id, { fields: ['*.*.*'] })
            .then((order) => {
                setData(order);
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const confirmDelete = (id: number) => {
        //Soft delete when user confirms a delete of the order
        var factoryOrders = DirectusManager.shared.directus.items('supplier_orders');
        factoryOrders
            .updateOne(id, { deleted: true })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmArchive = (id: number) => {
        //We change the status to the ID 4, which is archived(check status table to see all the possible id's on the CMS)
        var factoryOrders = DirectusManager.shared.directus.items('supplier_orders');
        factoryOrders
            .updateOne(id, { status: 4 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmClose = (id: number) => {
        //We change the status to the ID 8, which is closed(check status table to see all the possible id's on the CMS)
        var factoryOrders = DirectusManager.shared.directus.items('supplier_orders');
        factoryOrders
            .updateOne(id, { status: 8 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const createOrderToMisi = () => {
        if (!window.confirm('Weet je zeker dat je deze order naar Misi wilt versturen?')) {
            return;
        }

        const orderData = getOrderData();

        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                auth_token: DirectusManager.shared.directus.auth.token,
                order_data: orderData,
            }),
        };

        // confirm
        fetch(DirectusManager.shared.baseUrl + '/create-misi-order', requestOptions)
            .then((response) => {

            })
            .catch((error) => {
                console.error(error);
                window.alert('Er is iets misgegaan bij het versturen van de order naar Misi. Neem contact op met Coddin.')
            });
    }

    const getOrderData = () => {
        let orderProducts = [];
        var index = 1;
        for (let product of data.so_product_id) {
            orderProducts.push({
                Description: product.static_product_name,
                NumberOrdered: product.amount,
                Price: product.product_id.price_supplier,
                ProductBarcode: product.product_id.EAN,
                ProductNumber: product.product_id.article_code,
                OrderLineId: index,
            });
            index += 1;
        }

        return {
            OrderDate: moment(data.date_created).format('YYYY-MM-DDTHH:mm:ss'),
            OrderNumber: 'SCS-' + params.id,
            OrderNumberCustomer: params.id,
            CustomerInfoDelivery: {
                CompanyName1: data.supplier_id.company,
                CustomerNumber: data.supplier_id.supplier_id,

                StreetAddress: {
                    Street: data.supplier_id.address,
                    ZipCode: data.supplier_id.post_code,
                    City: data.supplier_id.city,
                    CountryCodeIso2: 'NL',
                },
                VatNumber: 'string',
            },
            Currency: 'EUR',
            EmailConfirmationExtra: 'info@tecdam.io',
            WebshopName: 'Drs Leenarts',
            OrderLinesInfo: orderProducts,
        };
    };

    const returnMain = () => {
        navigate('/orders/suppliers');
    };

    return (
        <Wrapper title={I18n.t('SUPPLIER_ORDER') + ' ' + (data.order_number ? data.order_number : '')}>
            <Table
                data={data}
                supplier={true}
                confirmDelete={(id: number) => confirmDelete(id)}
                confirmArchive={(id: number) => confirmArchive(id)}
                confirmClose={(id: number) => confirmClose(id)}
                reloadOrder={() => reloadOrder()}
                createOrderToMisi={() => createOrderToMisi()}
            />
        </Wrapper>
    );
};

export default TubeOrderDetail;
