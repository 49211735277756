import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/detail-rows-single-col';
import { useNavigate } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import * as Sentry from '@sentry/react';

const NewFactory: FC = (props) => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');
    const [contactPersonName, setContactPersonName] = useState('');
    const [contactPersonNameError, setContactPersonNameError] = useState(false);
    const [contactPersonNameErrorText, setContactPersonNameErrorText] = useState('');

    const [status, setStatus] = useState(0);

    useEffect(() => {}, []);

    const save = () => {
        setNameError(false);
        if (name == '') {
            setNameError(true);
            setNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
        setEmailError(false);
        if (email == '') {
            setEmailError(true);
            setEmailErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if (name !== '' && email !== '') {
            var factory = DirectusManager.shared.directus.items('factories');
            factory
                .createOne({
                    name: name,
                    email: email,
                    contact_person_name: contactPersonName,
                    status: status == 0 ? 'active' : 'inactive',
                })
                .then((data) => {
                    navigate('/settings/factories', { state: 'new' });
                })
                .catch((error: any) => {
                    Sentry.captureException(error);
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                });
        }
    };

    return (
        <Wrapper title={I18n.t('NEW_FACTORY')}>
            <Table
                midSize
                title={I18n.t('FACTORY_INFO')}
                save={() => save()}
                saveButtonText={I18n.t('ADD_FACTORY')}
                rows={[
                    {
                        title: I18n.t('TABLE_NAME'),
                        value: name,
                        onChange: (val: any) => setName(val),
                        input: true,
                        mandatory: true,
                        error: nameError,
                        errorText: nameErrorText,
                    },
                    {
                        title: I18n.t('TABLE_EMAIL'),
                        value: email,
                        onChange: (val: any) => setEmail(val),
                        input: true,
                        mandatory: true,
                        error: emailError,
                        errorText: emailErrorText,
                    },
                    {
                        title: I18n.t('TABLE_ORDER_CONTACTPERSON'),
                        value: contactPersonName,
                        onChange: (val: any) => setContactPersonName(val),
                        input: true,
                        // mandatory: true,
                        error: contactPersonNameError,
                        errorText: contactPersonNameErrorText,
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        select: true,
                        array: [
                            {
                                id: 0,
                                name: 'Active',
                            },
                            {
                                id: 1,
                                name: 'Inactive',
                            },
                        ],
                    },
                ]}
            />
        </Wrapper>
    );
};

export default NewFactory;
