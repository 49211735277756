import React, {useState, FC, useEffect, useRef} from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import DirectusManager from '../../../helpers/directusManager';
import {useNavigate, useLocation} from "react-router-dom";
import Helpers from '../../../helpers/functions';
import Snackbar from '../../../components/snackbar';

const CountriesOverview: FC = (props) => {
    const Validators = Helpers.instance;
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);

    const location = useLocation() as any;
    const navigate = useNavigate() as any;
    const snackBar = useRef();

    const getData = (query?: string) => {
        let filter: any = {};

        if (query) {
            filter = Validators.searchStringFilter('contact_name', query);
        }
        setLoader(true);
        let sort: any = ['code'];
        const countryRepository = DirectusManager.shared.directus.items('countries');
        countryRepository.readMany({limit: -1, filter, sort: sort}).then(data => {
            if (data !== undefined && data.data !== undefined && data.data !== null) {
                let mapped = data.data.map((x: any) =>
                    ({
                        col1: x.code,
                        col2: x.name,
                        id: x.uuid
                    }));
                setData(mapped);
                setLoader(false);
            }
        }).catch(() => {
            setLoader(false);
        });
    }


    useEffect(() => {
        getData();

        if (location?.state === 'new') {
            navigate('', {state: []});
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_COUNTRY_CREATED'));
        } else if (location?.state === 'updated') {
            navigate('', {state: []});
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('COUNTRY_UPDATED'));
        }
    }, []);

    const confirmDelete = (id: number) => {
        const productRepository = DirectusManager.shared.directus.items('products');
        productRepository.readMany(
            {
                limit: -1,
                filter: {
                    country: id
                }
            }
        ).then(result => {
            if (result.data !== null && result.data !== undefined && result.data.length > 0) {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('COUNTRY_HAS_PRODUCTS'));
                return;
            }

            const countryRepository = DirectusManager.shared.directus.items('countries');
            countryRepository.deleteOne(id).then(data => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ITEM_DELETED'));
                getData();
            }).catch((error: any) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
        });
    }

    const filterText = (value: any) => {
        getData(value);
    }

    return (
        <Wrapper
            title={I18n.t("SETTINGS")}
        >
            <Table
                title={I18n.t("NAV_COUNTRIES")}
                showActions={true}
                type="countries"
                allowNew={true}
                loading={loader}
                newTitle={I18n.t('NEW_COUNTRY')}
                columns={{
                    "headings": [
                        {
                            Header: I18n.t('TABLE_CODE'),
                            accessor: 'col1',
                            type: 'name',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_NAME'),
                            accessor: 'col2',
                            type: 'name',
                            Link: true,
                        },
                    ]
                }}
                data={data}
                confirmDelete={(id: number) => confirmDelete(id)}
                filterText={(val: string) => filterText(val)}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default CountriesOverview;
