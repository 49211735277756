
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/detail-rows-single-col';
import { useNavigate } from "react-router-dom";
import DirectusManager from '../../../helpers/directusManager';
import Snackbar from '../../../components/snackbar';

const NewContact : FC = (props) => {
    const navigate = useNavigate();

    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    
    const [email, setEmail] = useState('');
    const [emailError, setEmailError] = useState(false);
    const [emailErrorText, setEmailErrorText] = useState('');

    const [phone, setPhone] = useState('');
    const [status, setStatus] = useState(0);

    const snackBar = useRef();

    const save = () => {
        setNameError(false);
        setEmailError(false);

        if(name == '') {
            setNameError(true);
            setNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if(email == '') {
            setEmailError(true);
            setEmailErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if(name !== '' && email !== ''){
            var drlContact = DirectusManager.shared.directus.items('contact_person');
            drlContact.createOne({
                contact_name: name,
                phone_number: phone,
                contact_email: email,
                status: status == 0 ? 'active' : 'inactive'
            }).then(data => {
                navigate('/settings/contacts', {state: 'new'});
            }).catch((error: any) => {
                if(error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE'){
                    setEmailError(true);
                    setEmailErrorText(I18n.t('ERROR_NOT_UNIQUE_EMAIL'));
                } else {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                }
            });
        }
    }

    return (
        <Wrapper
            title={I18n.t("CONTACTS_NEW")}
        >
             <Table
                midSize
                title={I18n.t('CONTACT_INFO')}
                save={() => save()}
                saveButtonText={I18n.t('ADD_CONTACT')}
                rows={[
                    {
                        title: I18n.t('TABLE_NAME'),
                        value: name,
                        onChange: (val: any) => setName(val),
                        input: true,
                        mandatory: true,
                        error: nameError,
                        errorText: nameErrorText
                    },
                    {
                        title: I18n.t('TABLE_EMAIL'),
                        value: email,
                        onChange: (val: any) => setEmail(val),
                        input: true,
                        mandatory: true,
                        error: emailError,
                        errorText: emailErrorText
                    },
                    {
                        title: I18n.t('TABLE_PHONE'),
                        value: phone,
                        onChange: (val: any) => setPhone(val),
                        input: true
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        select: true,
                        array: [
                            {
                                id: 0,
                                name: 'Active'
                            },
                            {
                                id: 1,
                                name: 'Inactive'
                            }
                        ]
                    },
                ]}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default NewContact;