import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Snackbar from '../../../components/snackbar';
import Table from '../../../components/tables/detail-rows-single-col';
import { useParams, useLocation } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';
import * as Sentry from '@sentry/react';

const ProductGroupDetail: FC = (props) => {
    const [loader, setLoader] = useState(false);
    const params = useParams<{ id: any }>();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [status, setStatus] = useState(0);
    const [addedDate, setAddedDate] = useState('');
    const [changedDate, setChangedDate] = useState('');

    const snackBar = useRef();

    useEffect(() => {
        setLoader(true);
        let productgroup = DirectusManager.shared.directus.items('product_group');
        productgroup
            .readOne(params.id, { fields: ['*.*.*'] })
            .then((data: any) => {
                if (data !== undefined && data !== null) {
                    if (data.name) {
                        setName(data.name);
                    }
                    if (data.status) {
                        setStatus(data.status == 'active' ? 0 : 1);
                    }
                    if (data.date_updated) {
                        setChangedDate(data.date_updated ? moment(data.date_updated).format('DD-MM-YYYY HH:mm') : '-');
                    }
                    if (data.date_created) {
                        setAddedDate(data.date_created ? moment(data.date_created).format('DD-MM-YYYY HH:mm') : '-');
                    }
                    setLoader(false);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });
    }, []);

    const save = () => {
        setNameError(false);
        if (name !== '') {
            var productgroups = DirectusManager.shared.directus.items('product_group');
            productgroups
                .updateOne(params.id, {
                    name: name,
                    status: status == 0 ? 'active' : 'inactive',
                })
                .then((data) => {
                    setLoader(false);
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('GROUP_UPDATED'));
                })
                .catch((error: any) => {
                    Sentry.captureException(error);
                    setLoader(false);
                    if (error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE') {
                        setNameError(true);
                        setNameErrorText(I18n.t('ERROR_NOT_UNIQUE_PRODUCT_GROUP'));
                    } else {
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    }
                });
        } else {
            setNameError(true);
            setNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
    };

    return (
        <Wrapper title={I18n.t('PRODUCT_GROUP_EDIT')}>
            <Table
                midSize
                loading={loader}
                title={I18n.t('GROUP_INFO')}
                save={() => save()}
                saveButtonText={I18n.t('SAVE_CHANGES')}
                rows={[
                    {
                        title: I18n.t('ADDED'),
                        value: addedDate,
                    },
                    {
                        title: I18n.t('LAST_CHANGE'),
                        value: changedDate,
                    },
                    {
                        title: I18n.t('TABLE_GROUP_NAME'),
                        value: name,
                        onChange: (val: any) => setName(val),
                        input: true,
                        mandatory: true,
                        error: nameError,
                        errorText: nameErrorText,
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        select: true,
                        array: [
                            {
                                id: 0,
                                name: 'Active',
                            },
                            {
                                id: 1,
                                name: 'Inactive',
                            },
                        ],
                    },
                ]}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default ProductGroupDetail;
