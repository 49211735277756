import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import { useNavigate, useSearchParams } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import MailTemplate from '../../../components/mail-template';
import Snackbar from '../../../components/snackbar';
import Helpers from '../../../helpers/functions';

/**
 *
 * Allows the user to send an email to the factory with the order data. This is one of the steps in the flow for a factory order in order to be completed.
 */

const TubeMail: FC = (props) => {
    const [emailText, setEmailText] = useState<any>('');
    const [keys, setKeys] = useState<any>([]);

    const [emailFrom, setEmailFrom] = useState('');
    const [emailFromName, setEmailFromName] = useState('');
    const [emailFromError, setEmailFromError] = useState(false);
    const [emailFromErrorText, setEmailFromErrorText] = useState('');

    const [emailTo, setEmailTo] = useState('');
    const [emailToName, setEmailToName] = useState('');
    const [emailToError, setEmailToError] = useState(false);
    const [emailToErrorText, setEmailToErrorText] = useState('');

    const [attachment, setAttachment] = useState('');
    const [orderData, setOrderData] = useState<any>();
    const [orderID, setOrderID] = useState('');
    const [searchParams, setSearchParams] = useSearchParams();
    const navigate = useNavigate();
    const snackBar = useRef();

    const [loader, setLoader] = useState(false);

    const Validators = Helpers.instance;

    useEffect(() => {
        if (searchParams.get('order') !== undefined && searchParams.get('order') !== null) {
            loadOrder();
        } else {
            navigate('/orders/factories');
        }
    }, []);

    const loadOrder = () => {
        let orderId = searchParams.get('order');
        //Loads the selected order to fill the email fields
        let factoryOrder = DirectusManager.shared.directus.items('factory_order');
        if (orderId !== null) {
            factoryOrder
                .readOne(orderId, { fields: ['*.*.*.*'] })
                .then((data: any) => {
                    if (data !== undefined && data !== null) {
                        setEmailFrom(data.contact_id.contact_email ?? 'Invalid');
                        setEmailFromName(data.contact_id.contact_name ?? 'Invalid');
                        setAttachment(data.order_number + '.pdf');
                        setOrderData(data);
                        setEmailTo(data.factory?.email);
                        setEmailToName(data.factory?.name);

                        keys.push({
                            key: '[naam contactpersoon]',
                            value: data.contact_id.contact_name,
                        });
                        keys.push({
                            key: '[telefoon contactpersoon]',
                            value: data.contact_id.phone_number,
                        });
                        keys.push({
                            key: '[factory]',
                            value: data.factory.name,
                        });
                        setKeys(keys);

                        if (data.order_number !== null) {
                            setOrderID(data.order_number);
                            let text = I18n.t('EMAIL_FACTORIES');
                            let replaced = text.replace('ORDER_NR', data.order_number);
                            let visualText = replaced.replaceAll('<br>', '\n');
                            setEmailText(visualText);
                        }
                    }
                })
                .catch((error) => {
                    console.log(error);
                });
        }
    };

    const checkFields = () => {
        if (emailTo == '') {
            setEmailToError(true);
            setEmailToErrorText(I18n.t('ERROR_REQUIRED'));
            return;
        } else {
            setEmailToError(false);
            emailTo.split(',').forEach((email: string) => {
                if (!Validators.validateEmail(email.trim())) {
                    setEmailToError(true);
                    setEmailToErrorText(I18n.t('EMAIL_INVALID'));
                    return;
                }
            });
        }

        if (emailFrom == '') {
            setEmailFromError(true);
            setEmailFromErrorText(I18n.t('ERROR_REQUIRED'));
            return;
        } else {
            setEmailFromError(false);
            if (!Validators.validateEmail(emailFrom)) {
                setEmailFromError(true);
                setEmailFromErrorText(I18n.t('EMAIL_INVALID'));
                return;
            }
        }

        sendEmail();
    };

    const sendEmail = () => {
        setLoader(true);
        var text = emailText.replaceAll('\n', '<br>');

        if (keys.length > 0) {
            for (var i = 0; i < keys.length; i++) {
                var val = keys[i];
                text = text.replace(val.key, val.value);
            }
        }

        // The flow to send an email it's based on the following steps:
        // 1. Create PDF export
        // 2. Create email body
        // 3. Send email using the email send endpoint through the backend
        if (orderID !== '') {
            const requestOptions = {
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                body: JSON.stringify({
                    order_number: orderID,
                    auth_token: DirectusManager.shared.directus.auth.token,
                }),
            };
            fetch(DirectusManager.shared.baseUrl + '/pdf-generator', requestOptions)
                .then((response) => {
                    return response.json();
                })
                .then((response) => {
                    if (response.data !== undefined && response.data.filename !== undefined) {
                        var pdfUrl = response.data.url.split('/');
                        pdfUrl = pdfUrl[pdfUrl.length - 1];
                        var pdfName = response.data.filename.split('/');
                        pdfName = pdfName[pdfName.length - 1];

                        let body = JSON.stringify({
                            emailTo: emailTo,
                            emailToName: emailToName,
                            emailFrom: emailFrom,
                            emailFromName: emailFromName,
                            emailAttach: 'pdfs/' + pdfName,
                            emailAttachName: pdfName,
                            emailContent: '<html>' + text + '</html>',
                        });

                        const requestOptions = {
                            method: 'POST',
                            headers: { 'Content-Type': 'application/json' },
                            body: body,
                        };
                        fetch(DirectusManager.shared.baseUrl + '/emailing/send', requestOptions)
                            .then((response) => {
                                markEmailSent();
                                setLoader(false);
                            })
                            .catch((error) => {
                                setLoader(false);
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                            });
                    } else {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    }
                })
                .catch((error) => {
                    setLoader(false);
                    console.log(error);
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                });
        } else {
            setLoader(false);
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
        }
    };

    const markEmailSent = () => {
        let factoryOrder = DirectusManager.shared.directus.items('factory_order');
        let orderId = searchParams.get('order');
        if (orderId !== null) {
            // Status 6 it's 'order_to_supplier' in the CMS
            factoryOrder.updateOne(orderId, { order_email_sent: true, status: 6 }).then((data: any) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ORDER_SENT'));

                setTimeout(() => {
                    navigate('/orders/factories/' + orderId);
                }, 1000);
            });
        }
    };

    return (
        <Wrapper title={I18n.t('ORDERS')}>
            <MailTemplate
                title={I18n.t('FACTORY_MAIL_TITLE') + ' ' + orderID}
                emailText={emailText}
                setEmailText={(value) => setEmailText(value)}
                orderID={orderID}
                emailFrom={emailFrom}
                emailFromError={emailFromError}
                emailFromErrorText={emailFromErrorText}
                emailFromChange={(value) => setEmailFrom(value)}
                emailTo={emailTo}
                emailToChange={(value) => setEmailTo(value)}
                emailToError={emailToError}
                emailToErrorText={emailToErrorText}
                data={orderData}
                attachment={attachment}
                keys={keys}
                sendEmail={() => checkFields()}
                loader={loader}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default TubeMail;
