import React, { FC, useState, useEffect, useRef } from 'react';
import { useTable } from 'react-table';
import I18n from '../../../helpers/i18n';
import Helpers from '../../../helpers/functions';
import { DndProvider, useDrag, useDrop, createDndContext } from 'react-dnd';
import update from 'immutability-helper';
import {HTML5Backend} from 'react-dnd-html5-backend';
import DragHandleIcon from '@mui/icons-material/DragHandle';
interface Props {
    columns: any,
    data: any,
    loading?: boolean,
    newSorting: (array: any, changes: boolean) => void
}

const STable : FC<Props> = (props) => {
    const [columns, setColumns] = useState<any>([]);
    const [data, setData] = useState([]);
    const RNDContext = createDndContext(HTML5Backend as any);
    const manager = useRef(RNDContext);
    const [changesMade, setChangesMade] = useState(false);

    useEffect(() => {
        fetchData();
    }, []);

    const fetchData = () => {
        setData(props.data);
        setColumns(props.columns);
    };

    function SortingTable({columns, data} : {columns: any, data: any}){
        const [records, setRecords] = useState<any>(data);

        useEffect(() => {
            props.newSorting(records, changesMade);
        }, [records]);

        const getRowId = React.useCallback(row => {
            return row.id;
        }, [])

        const {
          getTableProps,
          getTableBodyProps,
          headerGroups,
          rows,
          prepareRow,
        } = useTable(
            {
                columns,
                data: records,
                getRowId
            }
        );

        const moveRow = (dragIndex: number, hoverIndex: number) => {
            const dragRecord: any = records[dragIndex];
            setRecords(
                update(records, {
                    $splice: [
                    [dragIndex, 1],
                    [hoverIndex, 0, dragRecord],
                    ],
                })
            );
            setChangesMade(true);
        }
       
        return (
          <DndProvider manager={manager.current.dragDropManager}>
                <table {...getTableProps()} className="table draggable overview">
                    <thead>
                        {headerGroups.map(headerGroup => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                <th className='th-sort'></th>
                                {headerGroup.headers.map(column => (
                                    <th {...column.getHeaderProps()}>
                                        <div className='d-flex justify-content-between'>
                                            {column.render('Header')}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        <tr>
                            {!props.loading && data.length < 1 &&
                                <td colSpan={10000}>
                                    <span className="d-flex justify-content-center align-items-center empty-tr">
                                        {I18n.t('TABLE_EMPTY')}
                                    </span>
                                </td>
                            }
                        </tr>
                        {rows.map((row : any, index: number) => {
                            prepareRow(row);
                            return (
                                <Row
                                    index={index}
                                    row={row}
                                    moveRow={moveRow}
                                    {...row.getRowProps()}
                                />
                            )
                        })}
                    </tbody>
                </table>
            </DndProvider>
        )
    };

    const DND_ITEM_TYPE = 'row';

    const Row = ({row, index, moveRow} : {row: any, index: any, moveRow: any}) => {
        const dropRef : any = useRef<HTMLInputElement>(null);
        const dragRef : any = React.useRef(null);

        const [, drop] = useDrop({
            accept: DND_ITEM_TYPE,
            hover(item : any, monitor) {
                if (!dropRef.current) {
                    return;
                }
                const dragIndex = item.index;
                const hoverIndex = index;
                if (dragIndex === hoverIndex) {
                    return;
                }

                const hoverBoundingRect : any = dropRef?.current?.getBoundingClientRect();
                const hoverMiddleY =
                    (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
                const clientOffset : any = monitor?.getClientOffset();
                const hoverClientY = clientOffset.y - hoverBoundingRect.top;

                if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
                    return;
                }
                if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
                    return;
                }
                
                if(dragIndex !== undefined){
                    moveRow(dragIndex, hoverIndex);
                }
                item.index = hoverIndex;
            },
        })

        const [{ isDragging }, drag, preview] = useDrag({
            item: { type: DND_ITEM_TYPE, index },
            collect: monitor => ({
                isDragging: monitor.isDragging(),
            }),
        });

        const opacity = isDragging ? 0 : 1;

        preview(drop(dropRef));
        drag(dragRef);

        return (
            <tr ref={dropRef} style={{ opacity }}>
                <td ref={dragRef} className='draggable'>
                    <DragHandleIcon/>
                </td>
                {row.cells.map((cell : any) => {
                    return <td {...cell.getCellProps()}>
                        {cell.render('Cell')}
                    </td>
                })}
            </tr>
        )
    };

    return (
        !props.loading ?
            <SortingTable
                columns={columns}
                data={data}
            />
        : null
    );
}
export default STable;