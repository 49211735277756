import React from 'react';
import ReactDOM from 'react-dom';
import './styles/style.scss';
import Navigation from './navigation';
import reportWebVitals from './reportWebVitals';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

// Sentry.init({
//     dsn: 'https://68a03ab5fb50499ea9e5ae42658b782d@o1280586.ingest.sentry.io/6492735',
//     integrations: [new BrowserTracing()],
//
//     // Set tracesSampleRate to 1.0 to capture 100%
//     // of transactions for performance monitoring.
//     // We recommend adjusting this value in production
//     tracesSampleRate: 1.0,
// });

ReactDOM.render(
    <React.StrictMode>
        <Navigation />
    </React.StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
