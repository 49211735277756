import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import SimpleTable from '../../../components/tables/overview-table-simple';
import DirectusManager from '../../../helpers/directusManager';
import { format } from 'date-fns';

const SalesOverview: FC = (props) => {
    const [dataFinances, setDataFinances] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const financesManager = DirectusManager.shared.directus.items('finances');

        var finances = await financesManager
            .readMany({
                limit: -1,
                fields: ['id', 'period', 'final_products', 'semi_final_products', 'promo_products'],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));

        finances = finances?.sort((x: any, y: any) => (x.date_created > y.date_create ? 1 : -1));
        finances = finances?.map((x: any) => {
            return {
                id: x.id,
                col1: x.period,
                col2: Math.round(x.final_products * 100) / 100,
                col3: Math.round(x.semi_final_products * 100) / 100,
                col4: Math.round(x.promo_products !== 'NaN' ? x.promo_products : 0 * 100) / 100,
            };
        });
        setDataFinances(finances);
    };

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <SimpleTable
                title={I18n.t('FINANCE_TITLE')}
                type="insights-finance-products"
                columns={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            type: 'name',
                            Link: false,
                        },
                        {
                            Header: I18n.t('FINANCES_FINALPRODUCTS'),
                            accessor: 'col2',
                            type: 'price',
                            Link: false,
                        },
                        {
                            Header: I18n.t('FINANCES_SEMIFINALPRODUCTS'),
                            accessor: 'col3',
                            type: 'price',
                            Link: false,
                        },
                        {
                            Header: I18n.t('FINANCES_PROMO'),
                            accessor: 'col4',
                            type: 'price',
                            Link: false,
                        },
                    ],
                }}
                data={dataFinances}
            />
            <div className="pb-4" />
        </Wrapper>
    );
};

export default SalesOverview;
