import { FC, useEffect, useState } from 'react';
import I18n from '../../../../../helpers/i18n';
import Table from '../../../../../components/tables/detail-rows-single-col';
import DirectusManager from '../../../../../helpers/directusManager';
import QRCode from 'react-qr-code';

const TWOFA: FC = (props) => {
    const [loader, setLoader] = useState(false);
    const [twoFAEnabled, setTwoFAEnabled] = useState(false);

    const [pass, setPass] = useState('');
    const [passError, setPassError] = useState(false);
    const [passErrorText, setPassErrorText] = useState('');

    const [secret, setSecret] = useState('');
    const [otpAuthUrl, setOtpAuthUrl] = useState('');

    const [otp, setOtp] = useState('');
    const [otpError, setOtpError] = useState(false);
    const [otpErrorText, setOtpErrorText] = useState('');

    useEffect(() => {
        setLoader(true);
        DirectusManager.shared.directus.users.me
            .read()
            .then((res) => {
                if (res.tfa_secret !== null) {
                    setTwoFAEnabled(true);
                } else {
                    setTwoFAEnabled(false);
                }
            })
            .catch((err) => {
                console.log('err', err);
            })
            .finally(() => {
                setLoader(false);
            });
    }, []);

    const generateSecret = async () => {
        setLoader(true);
        setPassError(false);
        setPassErrorText('');

        DirectusManager.shared.directus.users.me.tfa
            .generate(pass)
            .then((res) => {
                if (res.secret && res.otpauth_url) {
                    setSecret(res.secret);
                    setOtpAuthUrl(res.otpauth_url);
                } else {
                    setPassError(true);
                    setPassErrorText(I18n.t('LOGIN_UNKNOWN_ERROR'));
                }
            })
            .catch((err) => {
                setPassError(true);
                if (err.response.status === 401) {
                    setPassErrorText(I18n.t('2FA_ERROR_PASS'));
                } else {
                    setPassErrorText(I18n.t('LOGIN_UNKNOWN_ERROR'));
                }
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const enable2FA = async () => {
        setLoader(true);
        setOtpError(false);
        setOtpErrorText('');

        DirectusManager.shared.directus.users.me.tfa
            .enable(secret, otp)
            .then((res) => {
                setPass('');
                setSecret('');
                setOtpAuthUrl('');
                setOtp('');
                setTwoFAEnabled(true);
            })
            .catch((err) => {
                setOtpError(true);
                if (err.response.status === 403) {
                    setOtpErrorText(I18n.t('2FA_ERROR'));
                } else {
                    setOtpErrorText(I18n.t('LOGIN_UNKNOWN_ERROR'));
                }
            })
            .finally(() => {
                setLoader(false);
            });
    };

    const disable2FA = () => {
        setLoader(true);
        setOtpError(false);
        setOtpErrorText('');

        DirectusManager.shared.directus.users.me.tfa
            .disable(otp)
            .then((res) => {
                setPass('');
                setSecret('');
                setOtpAuthUrl('');
                setOtp('');
                setTwoFAEnabled(false);
            })
            .catch((err) => {
                setOtpError(true);
                if (err.response.status === 403) {
                    setOtpErrorText(I18n.t('2FA_ERROR'));
                } else {
                    setOtpErrorText(I18n.t('LOGIN_UNKNOWN_ERROR'));
                }
            })
            .finally(() => {
                setLoader(false);
            });
    };

    return (
        <>
            {twoFAEnabled ? (
                <Table
                    midSize
                    title={I18n.t('2FA_INFO')}
                    save={disable2FA}
                    loading={loader}
                    saveButtonText={I18n.t('2FA_DISABLE_2FA')}
                    rows={[
                        {
                            title: I18n.t('2FA_STATUS'),
                            customComponent: (
                                <p>{twoFAEnabled ? I18n.t('2FA_STATUS_ENABLED') : I18n.t('2FA_STATUS_DISABLED')}</p>
                            ),
                        },
                        {
                            title: I18n.t('2FA_OTP'),
                            value: otp,
                            onChange: (val: any) => setOtp(val),
                            input: true,
                            secure: true,
                            mandatory: true,
                            error: otpError,
                            errorText: otpErrorText,
                        },
                    ]}
                />
            ) : (
                <Table
                    midSize
                    title={I18n.t('2FA_INFO')}
                    save={otpAuthUrl === '' ? generateSecret : enable2FA}
                    loading={loader}
                    saveButtonText={otpAuthUrl === '' ? I18n.t('2FA_BUTTON_GENERATE_QR') : I18n.t('2FA_ENABLE_2FA')}
                    rows={[
                        {
                            title: I18n.t('2FA_STATUS'),
                            customComponent: (
                                <p>{twoFAEnabled ? I18n.t('2FA_STATUS_ENABLED') : I18n.t('2FA_STATUS_DISABLED')}</p>
                            ),
                        },
                        {
                            title: I18n.t('FORM_PASSWORD'),
                            value: pass,
                            onChange: (val: any) => setPass(val),
                            input: true,
                            secure: true,
                            mandatory: true,
                            error: passError,
                            errorText: passErrorText,
                        },
                        otpAuthUrl !== ''
                            ? {
                                  title: I18n.t('2FA_SCAN_QR_CODE'),
                                  customComponent: <QRCode size={128} value={otpAuthUrl} />,
                              }
                            : { condition: false },
                        otpAuthUrl !== ''
                            ? {
                                  title: I18n.t('2FA_OTP'),
                                  value: otp,
                                  onChange: (val: any) => setOtp(val),
                                  input: true,
                                  mandatory: true,
                                  error: otpError,
                                  errorText: otpErrorText,
                              }
                            : { condition: false },
                    ]}
                />
            )}
        </>
    );
};

export default TWOFA;
