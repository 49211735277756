import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Snackbar from '../../../components/snackbar';
import Table from '../../../components/tables/overview-table-simple';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';
import { useParams, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';

const SupplierProducts: FC = (props) => {
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [supplierProducts, setSupplierProducts] = useState<any>([]);
    const [allProducts, setAllProducts] = useState<any>([]);
    const [title, setTitle] = useState('');
    const [lastChange, setLastChange] = useState('');
    const [supplierId, setSupplierId] = useState('');
    const params = useParams<{ id: any }>();
    const [subTitle, setSubTitle] = useState('');

    const snackBar = useRef();

    useEffect(() => {
        getData();
    }, []);

    const getData = () => {
        let splittedUrl = window.location.href.split('/');
        let supplierId = splittedUrl[splittedUrl.length - 2];
        setLoader(true);
        setSupplierId(supplierId);
        const sort: any = ['name'];
        let allProducts = DirectusManager.shared.directus.items('products');
        allProducts
            .readMany({
                fields: ['id', 'name', 'article_code', 'factory_article_code', 'product_group.name'],
                filter: { deleted: false, type: 'final-product' },
                sort: sort,
            })
            .then((data: any) => {
                if (data !== undefined && data !== null) {
                    let allProducts = data.data.map((x: any) => ({
                        id: x.id,
                        col1: x.name,
                        col2: x.article_code,
                        col3: x.factory_article_code,
                        col4: 0,
                        col5: false,
                        product_group: x.product_group.name,
                    }));
                    allProducts = allProducts.sort((x: any, y: any) => (x.col1 < y.col1 ? 1 : -1));
                    loadSupplierProducts(allProducts, supplierId);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });

        DirectusManager.shared.directus
            .items('supplier')
            .readMany({ limit: -1, filter: { supplier_id: supplierId } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null && data.data.length == 1) {
                    var supplier: any = data.data[0];
                    var supplierName = supplier.company;
                    setTitle(supplierName);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });
    };

    const loadSupplierProducts = (allProducts: any, supplierId: any) => {
        let products = DirectusManager.shared.directus.items('supplier_products');
        products
            .readMany({ filter: { supplier_id: supplierId, deleted: false } })
            .then((data: any) => {
                if (data !== undefined && data !== null) {
                    for (let supplierProduct of data.data) {
                        let index = allProducts.findIndex((el: any) => el.id === supplierProduct.product_id);
                        if (index !== -1) {
                            allProducts[index].col4 = supplierProduct.purchase_price;
                            allProducts[index].col5 = true;
                        }
                        if (lastChange === '' && supplierProduct.date_updated != null) {
                            setLastChange(supplierProduct.date_updated);
                        }
                    }
                    var categorized: any = [];
                    var lastCategory = '';
                    allProducts = allProducts.sort((x: any, y: any) => (x.product_group > y.product_group ? 1 : -1));
                    for (var i = 0; i < allProducts.length; i++) {
                        var product = allProducts[i];
                        if (lastCategory == '' || lastCategory != product.product_group) {
                            categorized.push({ col1: product.product_group, group: true });
                            lastCategory = product.product_group;
                        }

                        categorized.push(product);
                    }

                    setData(categorized);
                    setSupplierProducts(data.data);
                    setLoader(false);
                } else {
                    setData(allProducts);
                    setLoader(false);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });
    };

    const save = () => {
        setLoader(true);
        var preUpdate = data.filter((x: any) => x.group == undefined);
        var totalToUpdate = 0;
        var totalUpdated = 0;
        for (let product of preUpdate) {
            if (product.col5 == true) {
                var exists = supplierProducts.filter((x: any) => x.product_id == product.id);
                if (exists.length == 0) {
                    totalToUpdate += 1;
                } else {
                    totalToUpdate += 1;
                }
            } else {
                var exists = supplierProducts.filter((x: any) => x.product_id == product.id);
                if (exists.length > 0) {
                    totalToUpdate += 1;
                }
            }
        }

        for (let product of preUpdate) {
            if (product.col5 == true) {
                var exists = supplierProducts.filter((x: any) => x.product_id == product.id);
                //It doesnt exist, create the relation
                if (exists.length == 0) {
                    var products = DirectusManager.shared.directus.items('supplier_products');
                    var purchasePrice = product.col4;
                    if (isNaN(purchasePrice) == true) {
                        purchasePrice = purchasePrice.replace(',', '.');
                    }
                    products
                        .createOne({
                            purchase_price: purchasePrice,
                            supplier_id: supplierId,
                            delete: false,
                            product_id: product.id,
                        })
                        .then((data) => {
                            totalUpdated += 1;
                            if (totalUpdated === totalToUpdate) {
                                showDoneReload();
                            }
                        })
                        .catch((error) => {
                            Sentry.captureException(error);
                            totalUpdated += 1;
                            if (totalUpdated === totalToUpdate) {
                                showDoneReload();
                            }
                        });
                } else {
                    var products = DirectusManager.shared.directus.items('supplier_products');
                    var supplierProductId = exists[0].supplier_product_id;
                    var purchasePrice = product.col4;
                    if (isNaN(purchasePrice) == true) {
                        purchasePrice = purchasePrice.replace(',', '.');
                    }
                    products
                        .updateOne(supplierProductId, { purchase_price: purchasePrice })
                        .then((data) => {
                            totalUpdated += 1;
                            if (totalUpdated === totalToUpdate) {
                                showDoneReload();
                            }
                        })
                        .catch((error) => {
                            Sentry.captureException(error);
                            totalUpdated += 1;
                            if (totalUpdated === totalToUpdate) {
                                showDoneReload();
                            }
                        });
                }
            } else {
                var exists = supplierProducts.filter((x: any) => x.product_id == product.id);
                if (exists.length > 0) {
                    var productToDelete = exists[0];
                    var products = DirectusManager.shared.directus.items('supplier_products');
                    var supplierProductId = productToDelete.supplier_product_id;
                    products
                        .updateOne(supplierProductId, { deleted: true })
                        .then((data) => {
                            totalUpdated += 1;
                            if (totalUpdated === totalToUpdate) {
                                showDoneReload();
                            }
                        })
                        .catch((error) => {
                            Sentry.captureException(error);
                            totalUpdated += 1;
                            if (totalUpdated === totalToUpdate) {
                                showDoneReload();
                            }
                        });
                }
            }
        }
    };

    const showDoneReload = () => {
        // @ts-ignore:next-line
        snackBar?.current?.show(I18n.t('SUPPLIER_PRODUCTS_UPDATED'));
        getData();
    };

    const changeAmountProduct = (amount: number, index: number) => {
        data[index].col4 = amount;
        setData(data);
    };

    const changeProduct = (checked: boolean, index: number) => {
        var newData: any = [].concat(data);
        newData[index].col5 = checked;
        setData(newData);
    };

    return (
        <Wrapper title={I18n.t('SUPPLIER_PRODUCTS') + ': ' + title}>
            <div className="page-subtitle">
                <span className="key">
                    {I18n.t('LAST_CHANGE') +
                        ': ' +
                        (lastChange != '' ? moment(lastChange).format('DD-MM-YYYY HH:mm') : '-')}
                </span>
                <span className="value">{subTitle}</span>
            </div>
            <Table
                loading={loader}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            type: 'name',
                            Link: false,
                        },
                        {
                            Header: I18n.t('ARTICLE_CODE'),
                            accessor: 'col2',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('FACTORY_CODE'),
                            accessor: 'col3',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('PURCHASE_PRICE'),
                            accessor: 'col4',
                            type: 'input-price',
                            Link: false,
                        },
                        {
                            Header: I18n.t('AVAILABLE'),
                            accessor: 'col5',
                            type: 'check',
                            Link: false,
                        },
                    ],
                }}
                data={data}
                onAmountChange={(amount: number, index: number) => changeAmountProduct(amount, index)}
                onCheckboxChange={(checked: boolean, index: number) => changeProduct(checked, index)}
                save={() => save()}
                saveButtonText={I18n.t('SAVE_PRODUCTS')}
                canDisableInput={true}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default SupplierProducts;
