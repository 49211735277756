import { Directus } from '@directus/sdk';

/**
 *
 * The manager handles the connection between the backend/CMS and the front-end.
 * This is done by using the Directus SDK, which simplifies the communication a lot by
 * using the default methods available in the Directus SDK.
 * The flag production has to be changed wether you are testing with the local environment or production.
 */
export default class DirectusManager {
    static shared: DirectusManager = new DirectusManager();

    production = false;

    baseUrl = process.env.REACT_APP_DIRECTUS_URL as string;

    directus = new Directus(this.baseUrl, { auth: { autoRefresh: true } });

    login(credentials: any) {
        return this.directus.auth.login(credentials);
    }

    logout() {
        return this.directus.auth.logout();
    }

    isLoggedIn() {
        return this.directus.auth.token != null;
    }
}
