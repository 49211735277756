import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import Snackbar from '../../components/snackbar';
import Table from '../../components/tables/detail-rows-double-col';
import DirectusManager from '../../helpers/directusManager';
import { useParams } from 'react-router-dom';
import moment from 'moment';
import { Link, useNavigate } from 'react-router-dom';
import * as Sentry from '@sentry/react';

/**
 *
 * The product edit view allows users to edit an existing product in the system. There's a few fields that are mandatory for creation, being those: articleCode, articleName, articleGroup, ean, priceSupplier, priceRetail, factory and factoryCode
 */

const ProductEdit: FC = (props) => {
    const [loader, setLoader] = useState(false);
    const params = useParams<{ id: any }>();
    const snackBar = useRef();

    const [articleGroups, setArticleGroups] = useState<any>([{}]);
    const [factories, setFactories] = useState<any>([{}]);
    const [statuses, setStatuses] = useState<any>([]);

    //leftrows
    const [addedDate, setAddedDate] = useState('');
    const [changedDate, setChangedDate] = useState('');
    const [changedMisiDate, setChangedMisiDate] = useState('');
    const [misiStock, setMisiStock] = useState('');

    //leftrows
    const [articleCode, setArticleCode] = useState('');
    const [articleCodeError, setArticleCodeError] = useState(false);
    const [articleCodeErrorText, setArticleCodeErrorText] = useState('');

    const [articleName, setArticleName] = useState('');
    const [articleNameError, setArticleNameError] = useState(false);
    const [articleNameErrorText, setArticleNameErrorText] = useState('');

    const [articleGroup, setArticleGroup] = useState(0);
    const [ean, setEan] = useState('');
    const [eanError, setEanError] = useState(false);
    const [eanErrorText, setEanErrorText] = useState('');

    const [eanMasterbox, setEanMasterbox] = useState('');
    const [priceSupplier, setPriceSupplier] = useState<any>(0);
    const [priceSupplierError, setPriceSupplierError] = useState(false);
    const [priceSupplierErrorText, setPriceSupplierErrorText] = useState('');
    const [priceRetail, setPriceRetail] = useState<any>(0);
    const [priceRetailError, setPriceRetailError] = useState(false);
    const [priceRetailErrorText, setPriceRetailErrorText] = useState('');
    const [priceFactory, setPriceFactory] = useState<any>(0);
    const [priceFactoryError, setPriceFactoryError] = useState(false);
    const [priceFactoryErrorText, setPriceFactoryErrorText] = useState('');
    const [factory, setFactory] = useState(0);

    const [factoryCode, setFactoryCode] = useState('');
    const [factoryCodeError, setFactoryCodeError] = useState(false);
    const [factoryCodeErrorText, setFactoryCodeErrorText] = useState('');

    const [minimumAmount, setMinimumAmount] = useState(0);
    const [lowStockNotice, setLowStockNotice] = useState(0);
    const [status, setStatus] = useState(0);
    const [type, setType] = useState('');
    const [types, setTypes] = useState<any>([]);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState<{ id: string; name: string }[]>([]);

    //rightrows
    const [layers, setLayers] = useState('');
    const [material, setMaterial] = useState('');
    const [capType, setCapType] = useState('');
    const [orifice, setOrifice] = useState('');
    const [sizes, setSizes] = useState('');
    const [packagingCodeMT, setPackagingCodeMT] = useState('');
    const [packagingCodeMTError, setPackagingCodeMTError] = useState(false);
    const [packagingCodeMTErrorText, setPackagingCodeMTErrorText] = useState('');
    const [eanMasterboxMT, setEanMasterboxMT] = useState('');
    const [amountMasterbox, setAmountMasterbox] = useState(0);
    const [extra, setExtra] = useState<any>(null);
    const [fileToUpload, setFileToUpload] = useState<any>(null);

    useEffect(() => {
        let sortGroup: any = ['name'];
        var productgroups = DirectusManager.shared.directus.items('product_group');
        productgroups
            .readMany({ limit: -1, sort: sortGroup, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.group_id,
                        name: x.name,
                    }));
                    setArticleGroups(mapped);
                }
            })
            .catch((error) => {
                setArticleGroups([]);
            });
        let sortFactories: any = ['name'];
        var factories = DirectusManager.shared.directus.items('factories');
        factories
            .readMany({ limit: -1, sort: sortFactories, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.id,
                        name: x.name,
                    }));
                    setFactories(mapped);
                }
            })
            .catch((error) => {
                setFactories([]);
            });
        setStatuses([
            {
                id: 0,
                name: 'Active',
            },
            {
                id: 1,
                name: 'Inactive',
            },
        ]);

        setTypes([
            {
                id: 'final-product',
                name: I18n.t('TYPE_END'),
            },
            {
                id: 'semi-finished',
                name: I18n.t('TYPE_HALF'),
            },
        ]);

        DirectusManager.shared.directus.items('countries')
            .readMany({ limit: -1, filter: {}})
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.uuid,
                        name: x.name,
                    }));
                    setCountries(mapped);
                }
            })
            .catch((error) => {
                setCountries([]);
            });

        setLoader(true);
        loadProduct();
    }, []);

    const loadProduct = () => {
        let product = DirectusManager.shared.directus.items('products');

        product
            .readOne(params.id, { fields: ['*.*.*'] })
            .then((data: any) => {
                if (data !== undefined && data !== null) {
                    if (data.date_created) {
                        setAddedDate(moment(data.date_created).format('DD-MM-YYYY HH:mm'));
                    }
                    if (data.date_updated) {
                        setChangedDate(moment(data.date_updated).format('DD-MM-YYYY HH:mm'));
                    }
                    if (data.stock_date_updated) {
                        setChangedMisiDate(moment(data.stock_date_updated).format('DD-MM-YYYY HH:mm'));
                    }
                    if (data.stock !== null && data.stock !== undefined) {
                        setMisiStock(data.stock);
                    }
                    if (data.article_code) {
                        setArticleCode(data.article_code);
                    }
                    if (data.name) {
                        setArticleName(data.name);
                    }
                    if (data.product_group) {
                        setArticleGroup(data.product_group.group_id);
                    }
                    if (data.EAN) {
                        setEan(data.EAN);
                    }
                    if (data.EAN_box) {
                        setEanMasterbox(data.EAN_box);
                    }
                    if (data.price_supplier) {
                        setPriceSupplier(data.price_supplier);
                    }
                    if (data.price_retail) {
                        setPriceRetail(data.price_retail);
                    }
                    if (data.price_factory) {
                        setPriceFactory(data.price_factory);
                    }
                    if (data.minimum_order_amount) {
                        setMinimumAmount(data.minimum_order_amount);
                    }
                    if (data.low_on_stock_amount) {
                        setLowStockNotice(data.low_on_stock_amount);
                    }
                    if (data.status) {
                        setStatus(data.status == 'active' ? 0 : 1);
                    }
                    if (data.factory) {
                        setFactory(data.factory.id);
                    }
                    if (data.factory_article_code) {
                        setFactoryCode(data.factory_article_code);
                    }
                    if (data.layers) {
                        setLayers(data.layers);
                    }
                    if (data.material) {
                        setMaterial(data.material);
                    }
                    if (data.cap_type) {
                        setCapType(data.cap_type);
                    }
                    if (data.orifice) {
                        setOrifice(data.orifice);
                    }
                    if (data.sizes) {
                        setSizes(data.sizes);
                    }
                    if (data.packaging_code_MT) {
                        setPackagingCodeMT(data.packaging_code_MT);
                    }
                    if (data.EAN_masterbox_MT) {
                        setEanMasterboxMT(data.EAN_masterbox_MT);
                    }
                    if (data.EAN_masterbox) {
                        setEanMasterbox(data.EAN_masterbox);
                    }
                    if (data.amount_masterbox) {
                        setAmountMasterbox(data.amount_masterbox);
                    }
                    if (data.box_amount) {
                        setAmountMasterbox(data.box_amount);
                    }
                    if (data.extra) {
                        setExtra(data.extra);
                    }
                    if (data.type) {
                        setType(data.type);
                    }
                    if (data.country) {
                        setCountry(data.country.uuid);
                    }

                    setLoader(false);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });
    };

    const save = () => {
        setArticleCodeError(false);
        setArticleNameError(false);
        setFactoryCodeError(false);
        setEanError(false);
        setPackagingCodeMTError(false);
        setPriceRetailError(false);
        setPriceSupplierError(false);
        setPriceFactoryError(false);

        if (articleCode == '') {
            setArticleCodeError(true);
            setArticleCodeErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (articleName == '') {
            setArticleNameError(true);
            setArticleNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (factoryCode == '') {
            setFactoryCodeError(true);
            setFactoryCodeErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (ean == '') {
            setEanError(true);
            setEanErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (priceRetail == '') {
            setPriceRetailError(true);
            setPriceRetailErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (priceSupplier == '') {
            setPriceSupplierError(true);
            setPriceSupplierErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (priceFactory == '') {
            setPriceFactoryError(true);
            setPriceFactoryErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if (
            articleCode !== '' &&
            articleName !== '' &&
            factoryCode !== '' &&
            ean !== '' &&
            priceRetail !== '' &&
            priceSupplier !== '' &&
            priceFactory !== ''
        ) {
            var updateBody: any = {
                article_code: articleCode,
                name: articleName,
                product_group: articleGroup,
                EAN: ean,
                EAN_masterbox: eanMasterbox,
                price_supplier: priceSupplier,
                price_retail: priceRetail,
                price_factory: priceFactory,
                factory: factory,
                factory_article_code: factoryCode,
                minimum_order_amount: minimumAmount,
                low_on_stock_amount: lowStockNotice,
                status: status == 0 ? 'active' : 'inactive',
                layers: layers,
                material: material,
                cap_type: capType,
                orifice: orifice,
                EAN_masterbox_MT: eanMasterboxMT,
                amount_masterbox: amountMasterbox,
                type: type,
                country: country,
            };
            if (packagingCodeMT !== null && packagingCodeMT !== undefined) {
                updateBody.packaging_code_MT = packagingCodeMT;
            }
            if (sizes !== '') {
                updateBody.sizes = sizes;
            }
            var product = DirectusManager.shared.directus.items('products');
            product
                .updateOne(params.id, updateBody)
                .then((data) => {
                    if (fileToUpload !== null) {
                        uploadFile(params.id);
                    } else {
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('PRODUCT_UPDATED'));
                    }
                })
                .catch((error: any) => {
                    Sentry.captureException(error);
                    console.log(error);
                    if (
                        error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field == 'article_code'
                    ) {
                        setArticleCodeError(true);
                        setArticleCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_ARTICLE_CODE'));
                    } else if (
                        error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field == 'factory_article_code'
                    ) {
                        setFactoryCodeError(true);
                        setFactoryCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_FACTORY_CODE'));
                    } else if (
                        error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field == '"EAN"'
                    ) {
                        setEanError(true);
                        setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_EAN'));
                    } else if (
                        error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field == 'packaging_code_MT'
                    ) {
                        setEanError(true);
                        setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_PACKAGING_CODE'));
                    } else {
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    }
                });
        }
    };

    const uploadFile = (productId: string) => {
        if (productId === undefined) {
            return;
        }
        const form = new FormData();
        form.append('file', fileToUpload);

        DirectusManager.shared.directus.files
            .createOne(form)
            .then((response) => {
                if (response !== undefined && response !== null && response.id !== undefined) {
                    var product = DirectusManager.shared.directus.items('products');
                    product
                        .updateOne(params.id, {
                            extra: response.id,
                        })
                        .then((data: any) => {
                            loadProduct();
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('PRODUCT_UPDATED'));
                        })
                        .catch((error: any) => {
                            Sentry.captureException(error);
                            console.log(error);
                            if (
                                error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field == 'article_code'
                            ) {
                                setArticleCodeError(true);
                                setArticleCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_ARTICLE_CODE'));
                            } else if (
                                error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field == 'factory_article_code'
                            ) {
                                setFactoryCodeError(true);
                                setFactoryCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_FACTORY_CODE'));
                            } else if (
                                error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field == '"EAN"'
                            ) {
                                setEanError(true);
                                setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_EAN'));
                            } else if (
                                error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field == 'packaging_code_MT'
                            ) {
                                setEanError(true);
                                setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_PACKAGING_CODE'));
                            } else {
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                            }
                        });
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const fileSelected = (file: any) => {
        setFileToUpload(file);
    };

    const deleteFile = () => {
        if (extra !== null) {
            DirectusManager.shared.directus.files
                .deleteOne(extra.id)
                .then((data) => {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('PRODUCT_UPDATED'));
                    setExtra(null);
                })
                .catch((error: any) => {
                    Sentry.captureException(error);
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                });
        }
    };

    return (
        <Wrapper title={I18n.t('EDIT_PRODUCT')}>
            <Table
                title={I18n.t('PRODUCT') + ' ' + articleCode}
                save={() => save()}
                largeSize
                loading={loader}
                saveButtonText={I18n.t('SAVE_CHANGES')}
                fileSelected={(file: any) => fileSelected(file)}
                deleteFile={deleteFile}
                leftRows={[
                    {
                        title: I18n.t('ADDED'),
                        value: addedDate,
                    },
                    {
                        title: I18n.t('CHANGED'),
                        value: changedDate,
                    },
                    {
                        title: I18n.t('CHANGED_MISI'),
                        value: changedMisiDate,
                    },
                    {
                        title: I18n.t('STOCK_MISI'),
                        value: misiStock,
                    },
                    {},
                    {
                        title: I18n.t('ARTICLE_CODE'),
                        value: articleCode,
                        onChange: (val: any) => setArticleCode(val),
                        input: true,
                        mandatory: true,
                        error: articleCodeError,
                        errorText: articleCodeErrorText,
                    },
                    {
                        title: I18n.t('ARTICLE_NAME'),
                        value: articleName,
                        onChange: (val: any) => setArticleName(val),
                        input: true,
                        mandatory: true,
                        error: articleNameError,
                        errorText: articleNameErrorText,
                    },
                    {
                        title: I18n.t('ARTICLE_GROUP'),
                        value: articleGroup,
                        onChange: (val: any) => setArticleGroup(val),
                        array: articleGroups,
                        select: true,
                        mandatory: true,
                        emptyMessage: (
                            <span className="empty-select">
                                {I18n.t('EMPTY_SELECT')}{' '}
                                <Link to="/settings/product-groups">{I18n.t('SETTINGS').toLowerCase()}</Link>.
                            </span>
                        ),
                    },
                    {
                        title: I18n.t('EAN'),
                        value: ean,
                        onChange: (val: any) => setEan(val),
                        input: true,
                        mandatory: true,
                        error: eanError,
                        errorText: eanErrorText,
                    },
                    {
                        title: I18n.t('EAN_MASTERBOX'),
                        value: eanMasterbox,
                        onChange: (val: any) => setEanMasterbox(val),
                        input: true,
                    },
                    {
                        title: I18n.t('PRICE_SUPPLIERS'),
                        value: '€ ' + priceSupplier,
                        onChange: (val: any) => setPriceSupplier(val.replaceAll('€ ', '').replace(/[^\d.-]/g, '')),
                        input: true,
                        price: true,
                        number: false,
                        mandatory: true,
                        error: priceSupplierError,
                        errorText: priceSupplierErrorText,
                    },
                    {
                        title: I18n.t('PRICE_RETAIL'),
                        value: '€ ' + priceRetail,
                        onChange: (val: any) => setPriceRetail(val.replaceAll('€ ', '').replace(/[^\d.-]/g, '')),
                        input: true,
                        price: true,
                        number: false,
                        mandatory: true,
                        error: priceRetailError,
                        errorText: priceRetailErrorText,
                    },
                    {
                        title: I18n.t('PRICE_FACTORY'),
                        value: '€ ' + priceFactory,
                        onChange: (val: any) => setPriceFactory(val.replaceAll('€ ', '').replace(/[^\d.-]/g, '')),
                        input: true,
                        price: true,
                        number: false,
                        mandatory: true,
                        error: priceFactoryError,
                        errorText: priceFactoryErrorText,
                    },
                    {},
                    {
                        title: I18n.t('TABLE_FACTORY'),
                        value: factory,
                        onChange: (val: any) => setFactory(val),
                        array: factories,
                        select: true,
                        mandatory: true,
                        emptyMessage: (
                            <span className="empty-select">
                                {I18n.t('EMPTY_SELECT')}{' '}
                                <Link to="/settings/factories">{I18n.t('SETTINGS').toLowerCase()}</Link>.
                            </span>
                        ),
                    },
                    {
                        title: I18n.t('FACTORY_CODE'),
                        value: factoryCode,
                        onChange: (val: any) => setFactoryCode(val),
                        input: true,
                    },
                    {},
                    {
                        title: I18n.t('MINIMUM_ORDER_QUANTITY'),
                        value: minimumAmount,
                        onChange: (val: any) => setMinimumAmount(val),
                        input: true,
                        number: true,
                    },
                    {
                        title: I18n.t('LOW_ON_STOCK_NOTICE'),
                        value: lowStockNotice,
                        onChange: (val: any) => setLowStockNotice(val),
                        input: true,
                        number: true,
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        array: statuses,
                        select: true,
                    },
                    {
                        title: I18n.t('TABLE_TYPE'),
                        value: type,
                        onChange: (val: any) => setType(val),
                        array: types,
                        select: true,
                    },
                    {
                        title: I18n.t('TABLE_COUNTRY'),
                        value: country,
                        onChange: (val: any) => setCountry(val),
                        array: countries,
                        select: true,
                    },
                ]}
                rightRows={[
                    {
                        title: I18n.t('LAYERS'),
                        value: layers,
                        onChange: (val: any) => setLayers(val),
                        input: true,
                    },
                    {
                        title: I18n.t('MATERIAL'),
                        value: material,
                        onChange: (val: any) => setMaterial(val),
                        input: true,
                    },
                    {
                        title: I18n.t('CAP_TYPE'),
                        value: capType,
                        onChange: (val: any) => setCapType(val),
                        input: true,
                    },
                    {
                        title: I18n.t('ORIFICE'),
                        value: orifice,
                        onChange: (val: any) => setOrifice(val),
                        input: true,
                    },
                    {
                        title: I18n.t('SIZES'),
                        value: sizes,
                        onChange: (val: any) => setSizes(val),
                        input: true,
                    },
                    {},
                    {
                        title: I18n.t('PACKAGING_CODE_MT'),
                        value: packagingCodeMT,
                        onChange: (val: any) => setPackagingCodeMT(val),
                        input: true,
                        error: packagingCodeMTError,
                        errorText: packagingCodeMTErrorText,
                        mandatory: false,
                    },
                    {
                        title: I18n.t('EAN_MASTERBOX_MT'),
                        value: eanMasterboxMT,
                        onChange: (val: any) => setEanMasterboxMT(val),
                        input: true,
                    },
                    {
                        title: I18n.t('AMOUNT_MASTERBOX'),
                        value: amountMasterbox,
                        onChange: (val: any) => setAmountMasterbox(val),
                        input: true,
                        number: true,
                    },
                    {},
                    {
                        title: I18n.t('EXTRA_INFO'),
                        value: extra,
                        upload: true,
                        fileName: extra?.filename_download ?? '',
                    },
                ]}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default ProductEdit;
