import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/order-new';
import { useNavigate, useLocation } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import Snackbar from '../../../components/snackbar';
import Alert from '../../../components/alert';
import moment from 'moment';
import * as Sentry from '@sentry/react';

/**
 *
 * New order allows the customer to create a new supplier order by defining the desired delivery date, the supplier that will process this order, contact person and the products to be ordered.
 * Every time the supplier it's changed, the products will be reloaded as well since this is a strong relation inbetween.
 * An order has multiple steps inbetween until you can consider it completed(can be seen as status)
 *
 * 1. Open
 * 2. Order to Misi (by pressing on the gear icon)
 * 3. Confirmed received amounts (by setting the amount)
 * 4. Completed
 */

const NewOrder: FC = (props) => {
    const navigate = useNavigate();
    const [loader, setLoader] = useState(false);
    const snackBar = useRef();

    const [suppliers, setSuppliers] = useState<any>([]);
    const [selectedSupplier, setSelectedSupplier] = useState<any>(1);
    const [contacts, setContacts] = useState<any>([]);
    const [selectedContact, setSelectedContact] = useState<any>(1);

    const [allProducts, setAllProducts] = useState<any>([]); //all data
    const [availableProducts, setAvailableProducts] = useState<any>([]); //available only after adding
    const [productsAdded, setProductsAdded] = useState<any>([]); //selected/added products
    const [showSupplierChange, setShowSupplierChange] = useState<boolean>(false);
    const [temporalSelectedSupplier, setTemporalSelectedSupplier] = useState<any>(-1);
    const [desiredDeliveryDate, setDesiredDeliveryDate] = useState<any>(null);

    useEffect(() => {
        setLoader(true);
        let sortContacts: any = ['contact_name'];
        var contacts = DirectusManager.shared.directus.items('contact_person');
        contacts
            .readMany({ limit: -1, sort: sortContacts, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        name: x.contact_name,
                        id: x.contact_id,
                    }));
                    setContacts(mapped);
                    setSelectedContact(mapped[0].id);
                }
            })
            .catch((error) => {});

        let sortSupplier: any = ['company'];
        var suppliers = DirectusManager.shared.directus.items('supplier');
        suppliers
            .readMany({ limit: -1, sort: sortSupplier, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        name: x.company,
                        id: x.supplier_id,
                    }));
                    setSuppliers(mapped);
                    setSelectedSupplier(mapped[0].id);
                    loadProducts(mapped[0].id);
                }
            })
            .catch((error) => {});
    }, []);

    useEffect(() => {
        loadProducts(selectedSupplier);
    }, [selectedSupplier]);

    const loadProducts = (supplier: number) => {
        var products = DirectusManager.shared.directus.items('supplier_products');
        products
            .readMany({
                limit: -1,
                filter: { deleted: false, supplier_id: supplier },
                fields: [
                    'product_id.id',
                    'product_id.name',
                    'product_id.article_code',
                    'product_id.packaging_code_MT',
                    'product_id.minimum_order_amount',
                    'product_id.country.*',
                ],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    var mapped: any[] = [];

                    for (let product of data.data) {
                        var x: any = product;
                        if (x.product_id !== undefined && x.product_id !== null) {
                            mapped.push({
                                id: x.product_id.id,
                                name: x.product_id.name + ' - ' + x.product_id.article_code + (x.product_id.country?.code ? ' (' + x.product_id.country?.code + ')' : ''),
                                country: x.product_id.country?.code ? x.product_id.country?.code : '-',
                                article_code: x.product_id.article_code,
                                packaging_code: x.product_id.packaging_code_MT,
                                min_amount: 1,
                            });
                        }
                    }

                    mapped = mapped.sort((x: any, y: any) => (x.name > y.name ? 1 : -1));
                    setAvailableProducts(mapped);
                    setAllProducts(mapped);
                    setLoader(false);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });
    };

    const saveOrder = (order: any) => {
        if (productsAdded.length === 0) {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('PRODUCT_REQUIRED'));
            return;
        }

        if (desiredDeliveryDate === null) {
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('DATE_REQUIRED'));
            return;
        }

        var formattedDate = moment(desiredDeliveryDate).format('YYYY-MM-DD');
        let orderData = {
            supplier_id: parseInt(selectedSupplier),
            contact_id: parseInt(selectedContact),
            deleted: false,
            status: 2,
            desired_delivery_date: new Date(formattedDate),
        };

        //Status 2 it's OPEN in the CMS
        var factoryOrder = DirectusManager.shared.directus.items('supplier_orders');
        factoryOrder
            .createOne(orderData)
            .then((data: any) => {
                if (data) {
                    if (productsAdded !== undefined && productsAdded.length > 0) {
                        saveProductsInOrder(data.supplier_orders_id);
                    } else {
                        navigate('/orders/suppliers', { state: 'new' });
                    }
                }
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const saveProductsInOrder = (orderId: number) => {
        let productToSave = productsAdded.pop();

        var type = 'supplier_order_products';
        var orderProducts = DirectusManager.shared.directus.items(type);
        orderProducts
            .createOne({
                supplier_order_id: orderId,
                amount: productToSave.col4,
                static_product_name: productToSave.col1,
                product_id: productToSave.id,
                deleted: false,
            })
            .then((data) => {
                if (productsAdded === undefined || productsAdded.length == 0) {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                    navigate('/orders/suppliers', { state: 'new' });
                } else {
                    saveProductsInOrder(orderId);
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const updateProducts = (newArray: any, deletedProduct?: any) => {
        setProductsAdded(newArray);

        if (deletedProduct) {
            for (var a = 0; a < allProducts.length; a++) {
                if (allProducts[a].id == deletedProduct.id) {
                    var updatedArray: any = [].concat(availableProducts);
                    updatedArray.push(allProducts[a]);
                    updatedArray.sort((x: any, y: any) => (x.name > y.name ? 1 : -1));
                    setAvailableProducts(updatedArray);
                }
            }
        } else {
            for (var n = 0; n < newArray.length; n++) {
                var newProduct = newArray[n];

                var index = availableProducts.findIndex((el: any) => el.id === newProduct.id);

                if (index !== -1) {
                    var updatedArray: any = [].concat(availableProducts);
                    updatedArray.splice(index, 1);
                    setAvailableProducts(updatedArray);
                }
            }
        }
    };

    function changeSupplier(id: number) {
        setSelectedSupplier(id);
        setProductsAdded([]);
    }

    const confirmSupplierChange = () => {
        setShowSupplierChange(false);
        setSelectedSupplier(temporalSelectedSupplier);
        setProductsAdded([]);
    };

    const dismissSupplierChange = () => {
        setTemporalSelectedSupplier(-1);
        setShowSupplierChange(false);
    };

    return (
        <Wrapper title={I18n.t('NEW_ORDER')}>
            <Table
                title={I18n.t('SUPPLIER_ORDER')}
                type="suppliers"
                contacts={contacts}
                selectedContact={selectedContact}
                setSelectedContact={(id: number) => setSelectedContact(id)}
                suppliers={suppliers}
                selectedSupplier={selectedSupplier}
                setSelectedSupplier={(id: number) => changeSupplier(id)}
                availableProducts={availableProducts}
                productsAdded={productsAdded}
                updateProducts={(array, deletedProduct) => updateProducts(array, deletedProduct)}
                setDesiredDeliveryDate={(date: any) => setDesiredDeliveryDate(date)}
                data={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            Type: 'name',
                        },
                        {
                            Header: I18n.t('ARTICLE_CODE'),
                            accessor: 'col2',
                            Type: 'code',
                        },
                        {
                            Header: I18n.t('FACTORY_CODE'),
                            accessor: 'col3',
                            Type: 'code',
                        },
                        {
                            Header: I18n.t('AMOUNT'),
                            accessor: 'col4',
                            Type: 'input',
                        },
                    ],
                }}
                saveOrder={(order) => saveOrder(order)}
            />
            <Alert
                visible={showSupplierChange}
                onConfirm={confirmSupplierChange}
                onCancel={dismissSupplierChange}
                title={I18n.t('CHANGE_SUPPLIER_TITLE')}
                text={I18n.t('CHANGE_SUPPLIER_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_CHANGE')}
                cancelText={I18n.t('ALERT_CANCEL_CHANGE')}
                defaultStyle
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default NewOrder;
