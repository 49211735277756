import React, { FC, useEffect } from 'react';
import I18n from '../../../../helpers/i18n';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import Tooltip from '../../../../components/tooltip';
import Helpers from '../../../../helpers/functions';
import moment from 'moment';
import DeleteIcon from '@mui/icons-material/Delete';
import Inventory2Icon from '@mui/icons-material/Inventory2';
import DoDisturbIcon from '@mui/icons-material/DoDisturb';
import DirectusManager from '../../../../helpers/directusManager';
import DatePicker from '../../../date-picker';

const Validators = Helpers.instance;
interface Props {
    data: any;
    tubes?: boolean;
    factory?: boolean;
    supplier?: boolean;

    showDeleteModal: () => void;
    archiveOrder: () => void;
    closeOrder: () => void;
    reloadOrder: () => void;
}

const GeneralInfo: FC<Props> = (props) => {
    const openPDF = (data: any) => {
        console.log(data);
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                order_number: data.order_number,
                auth_token: DirectusManager.shared.directus.auth.token,
            }),
        };
        fetch(DirectusManager.shared.baseUrl + '/pdf-generator', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                if (response.data !== undefined && response.data.url !== undefined) {
                    window.open(
                        response.data.url + '?access_token=' + DirectusManager.shared.directus.auth.token
                    );
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const getTotalAmount = () => {
        var amount = 0;
        if (props.data !== undefined && props.data.to_product_id !== undefined) {
            props.data.to_product_id.map((p: any) => (amount += p.amount));
        }
        if (props.data !== undefined && props.data.factory_order_id !== undefined) {
            props.data.fo_product_id.map((p: any) => (amount += p.amount));
        }
        if (props.data !== undefined && props.data.supplier_orders_id !== undefined) {
            props.data.so_product_id.map((p: any) => (amount += p.amount));
        }
        return Validators.amountDisplay(amount);
    };

    const getDateTime = (date: any) => {
        if (date == null) {
            return '-';
        }
        var parseDate = moment(date).format('DD-MM-YYYY HH:mm');
        return parseDate;
    };

    const getDate = (date: any) => {
        if (date == null) {
            return '-';
        }
        var parseDate = moment(date).format('DD-MM-YYYY');
        return parseDate;
    };

    const deleteOrder = () => {
        props.showDeleteModal();
    };

    const archiveOrder = () => {
        props.archiveOrder();
    };

    const closeOrder = () => {
        props.closeOrder();
    };

    const getStatusName = () => {
        if (props.data?.status?.name) {
            return I18n.t('STATUS_' + props.data.status.name.toUpperCase());
        } else {
            return '-';
        }
    };

    const checkIfHasProducts = () => {
        var hasProducts = false;
        if (props.tubes && props.data?.to_product_id?.length > 0) {
            hasProducts = true;
        } else if (props.factory && props.data?.fo_product_id?.length > 0) {
            hasProducts = true;
        } else if (props.supplier && props.data?.so_product_id?.length > 0) {
            hasProducts = true;
        }
        return hasProducts;
    };

    const canChangeDesiredDate = () => {
        var canChange = false;
        var status = props.data?.status?.name;

        if (status === 'open') {
            canChange = true;
        } else {
            canChange = false;
        }
        return canChange;
    };

    const changeDesiredDate = (date: any) => {
        var formatDate = moment(date).format('YYYY-MM-DD');

        if (props.tubes) {
            var id = props.data.tube_order_id;
            var order = DirectusManager.shared.directus.items('tubes_order');
            order
                .updateOne(id, { desired_delivery_date: new Date(formatDate) })
                .then((data) => {
                    props.reloadOrder();
                })
                .catch((error: any) => {});
        } else if (props.factory) {
            var id = props.data.factory_order_id;
            var order = DirectusManager.shared.directus.items('tubes_order');
            order
                .updateOne(id, { desired_delivery_date: new Date(formatDate) })
                .then((data) => {
                    props.reloadOrder();
                })
                .catch((error: any) => {});
        } else if (props.supplier) {
            var id = props.data.supplier_orders_id;
            var order = DirectusManager.shared.directus.items('supplier_orders');
            order
                .updateOne(id, { desired_delivery_date: new Date(formatDate) })
                .then((data) => {
                    props.reloadOrder();
                })
                .catch((error: any) => {});
        }
    };

    const canChangeExpectedDate = () => {
        var canChange = false;
        var status = props.data?.status?.name;

        if (status === 'completed') {
            canChange = false;
        } else {
            canChange = true;
        }
        return canChange;
    };

    const changeExpectedDate = (date: any) => {
        var formatDate = moment(date).format('YYYY-MM-DD');

        if (props.tubes) {
            var id = props.data.tube_order_id;
            var order = DirectusManager.shared.directus.items('tubes_order');
            order
                .updateOne(id, { expected_delivery_date: new Date(formatDate) })
                .then((data) => {
                    props.reloadOrder();
                })
                .catch((error: any) => {});
        } else if (props.factory) {
            var id = props.data.factory_order_id;
            var order = DirectusManager.shared.directus.items('factory_order');
            order
                .updateOne(id, { expected_delivery_date: new Date(formatDate) })
                .then((data) => {
                    props.reloadOrder();
                })
                .catch((error: any) => {
                    alert(error);
                });
        }
    };

    const getDesiredDate = () => {
        if (props.data.desired_delivery_date) {
            return new Date(props.data.desired_delivery_date);
        }

        return undefined;
    };

    const getExpectedDate = () => {
        if (props.data.expected_delivery_date) {
            return new Date(props.data.expected_delivery_date);
        }

        return undefined;
    };

    return (
        <div className="table-wrapper detail">
            <div className="table-header filled d-flex justify-content-between align-items-center">
                <span className="title">{I18n.t('GENERAL_INFO')}</span>
            </div>
            <table className="table">
                <thead></thead>
                <tbody>
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_ORDER_ID')}</td>
                        <td>
                            <div className="d-flex align-items-center">
                                {props.data.order_number}
                                {props.data.order_number && checkIfHasProducts() ? (
                                    <Tooltip text={I18n.t('VIEW_PDF')}>
                                        <PictureAsPdfIcon
                                            className="download-pdf"
                                            onClick={() => openPDF(props.data)}
                                        />
                                    </Tooltip>
                                ) : (
                                    <Tooltip text={I18n.t('VIEW_PDF_DISABLED')}>
                                        <PictureAsPdfIcon className="download-pdf disabled" />
                                    </Tooltip>
                                )}
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_ORDER_DATE')}</td>
                        <td>{getDateTime(props.data.date_created)}</td>
                    </tr>

                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_DESIRED_DELIVERY')}</td>
                        {canChangeDesiredDate() ? (
                            <td className="detail-date-picker">
                                <DatePicker
                                    showSingleValue={
                                        props.data.desired_delivery_date !== ''
                                            ? moment(props.data.desired_delivery_date).format('DD-MM-YYYY')
                                            : ''
                                    }
                                    filterDateStart={props.data.desired_delivery_date}
                                    changedDateStart={(date: any) => changeDesiredDate(date)}
                                    minDate={new Date()}
                                    shownDate={getDesiredDate()}
                                />
                            </td>
                        ) : (
                            <td>{getDate(props.data.desired_delivery_date)}</td>
                        )}
                    </tr>
                    {(props.tubes || props.factory) && (
                        <tr>
                            <td className="td-heading">{I18n.t('TABLE_EXPECTED_DELIVERY')}</td>
                            {canChangeExpectedDate() ? (
                                <td className="detail-date-picker">
                                    <DatePicker
                                        showSingleValue={
                                            props.data.expected_delivery_date !== null
                                                ? moment(props.data.expected_delivery_date).format('DD-MM-YYYY')
                                                : false
                                        }
                                        filterDateStart={
                                            props.data.expected_delivery_date !== null
                                                ? props.data.expected_delivery_date
                                                : false
                                        }
                                        changedDateStart={(date: any) => changeExpectedDate(date)}
                                        minDate={new Date()}
                                        shownDate={getExpectedDate()}
                                    />
                                </td>
                            ) : (
                                <td>{getDate(props.data.expected_delivery_date)}</td>
                            )}
                        </tr>
                    )}
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_ORDER_AMOUNT')}</td>
                        <td>{getTotalAmount()}</td>
                    </tr>
                    {props.data?.factory?.name && (
                        <tr>
                            <td className="td-heading">{I18n.t('TABLE_FACTORY')}</td>
                            <td>{props.data?.factory?.name}</td>
                        </tr>
                    )}
                    {props.supplier && (
                        <tr>
                            <td className="td-heading">{I18n.t('TABLE_SUPPLIER')}</td>
                            <td>{props.data.supplier_id?.company}</td>
                        </tr>
                    )}
                    {props.tubes && (
                        <tr>
                            <td className="td-heading">{I18n.t('TABLE_DELIVERY_ADDRESS')}</td>
                            <td>{'Vlasblomweg 11, 1521 PW, Wormerveer'}</td>
                        </tr>
                    )}
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_ORDER_CONTACTPERSON')}</td>
                        <td>{props.data.contact_id != null ? props.data.contact_id.contact_name : '-'}</td>
                    </tr>
                    {props.data.factories && props.tubes === undefined && (
                        <tr>
                            <td className="td-heading">{I18n.t('TABLE_FACTORIES')}</td>
                            <td>{props.data.factories}</td>
                        </tr>
                    )}
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_STATUS')}</td>
                        <td>
                            <div className="d-flex align-items-center justify-content-between">
                                <span className="status-text">{getStatusName()}</span>
                                <div className="d-flex align-items-center">
                                    {props.data.status?.name !== 'archived' && props.data.status?.name !== 'cancelled' && (
                                        <Tooltip text={I18n.t('TOOLTIP_ARCHIVE_ORDER')}>
                                            <Inventory2Icon
                                                className="archive-icon"
                                                onClick={() => archiveOrder()}
                                                fontSize="medium"
                                            />
                                        </Tooltip>
                                    )}
                                    {props.data.status?.name !== 'cancelled' &&
                                        props.data.status?.name !== 'archived' &&
                                        props.data.status?.name !== 'completed' && (
                                            <Tooltip text={I18n.t('TOOLTIP_CLOSE_ORDER')}>
                                                <DoDisturbIcon
                                                    className="close-icon"
                                                    onClick={() => closeOrder()}
                                                    fontSize="medium"
                                                />
                                            </Tooltip>
                                        )}
                                    <Tooltip text={I18n.t('TOOLTIP_DELETE_ORDER')}>
                                        <DeleteIcon
                                            className="delete-icon"
                                            onClick={() => deleteOrder()}
                                            fontSize="medium"
                                        />
                                    </Tooltip>
                                </div>
                            </div>
                        </td>
                    </tr>
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_LAST_CHANGE')}</td>
                        <td>{getDate(props.data.date_updated)}</td>
                    </tr>
                </tbody>
            </table>
        </div>
    );
};
export default GeneralInfo;
