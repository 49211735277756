import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import SimpleTable from '../../../components/tables/overview-table-simple';
import DirectusManager from '../../../helpers/directusManager';
import { format } from 'date-fns';

const SalesOverview: FC = (props) => {
    const [dataImports, setDataImports] = useState<any>([]);
    const [dataProducts, setDataProducts] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const salesManager = DirectusManager.shared.directus.items('sales');
        const salesInfoManager = DirectusManager.shared.directus.items('sales_info');

        const sales = await salesManager
            .readMany({
                limit: -1,
                fields: ['week', 'channel.id', 'channel.name', 'date_created', 'sale', 'volume', 'sale_id', 'id'],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));
        const salesInfo = await salesInfoManager
            .readMany({ limit: -1 })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));

        setSalesImports(sales);
        setSalesProducts(sales, salesInfo);
    };

    const setSalesImports = (sales: any) => {
        // Keys of all elements
        const keys = sales.map((sale: any) => `${sale.week}***${sale.channel.name}***${sale.channel.id}`);

        // Remove duplicated keys
        const unique = keys.filter((item: any, index: any) => {
            return keys.indexOf(item) === index;
        });

        // Group elements by boths keys
        var grouped = unique.map((uniq: string) => {
            const [week, channel, channelId] = uniq.split('***');

            const salesFilter: any = sales.filter((sale: any) => {
                return sale.week === week && sale.channel.id === +channelId;
            });

            // Accumulators of the volumes and sales in the group
            const volume = salesFilter.reduce((partialSum: number, a: any) => {
                var volume = a.volume;
                if (typeof volume === 'string') {
                    volume = parseInt(volume);
                }
                const checkValue = volume !== null && volume !== undefined ? +volume : 0;
                return partialSum + checkValue;
            }, 0);

            const sale = salesFilter.reduce((partialSum: number, a: any) => {
                var sale = a.sale;
                if (typeof sale === 'string') {
                    sale = parseInt(sale);
                }
                const checkValue = sale !== null && sale !== undefined ? +sale : 0;
                return partialSum + checkValue;
            }, 0);

            return {
                id: `${week}&${channelId}`,
                col1: week,
                col2: channel,
                col3: Math.round(volume * 100) / 100,
                col4: Math.round(sale * 100) / 100,
                col5: format(new Date(salesFilter[0].date_created), 'dd-MM-yyyy HH:mm'),
            };
        });

        grouped = grouped.sort((x: any, y: any) => (x.col1 < y.col1 ? 1 : -1));
        setDataImports(grouped);
    };

    const setSalesProducts = (sales: any, salesInfo: any) => {
        const mapped = salesInfo.map((saleInfo: any) => {
            const salesFilter: any = sales.filter((sale: any) => {
                return sale.id === saleInfo.id;
            });

            const volume = salesFilter.reduce((partialSum: number, a: any) => {
                var volume = a.volume;
                if (typeof volume === 'string') {
                    volume = parseInt(volume);
                }
                const checkValue = volume !== null && volume !== undefined ? +volume : 0;
                return partialSum + checkValue;
            }, 0);
            const sale = salesFilter.reduce((partialSum: number, a: any) => {
                var sale = a.sale;
                if (typeof sale === 'string') {
                    sale = parseInt(sale);
                }
                const checkValue = sale !== null && sale !== undefined ? +sale : 0;
                return partialSum + checkValue;
            }, 0);

            return {
                col0: saleInfo.id,
                col1: saleInfo.item_desc,
                col3: Math.round(volume * 100) / 100,
                col4: Math.round(sale * 100) / 100,
            };
        });

        setDataProducts(mapped);
    };

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <Table
                title={I18n.t('SALES_OVERVIEW_PER_IMPORT')}
                showActions={false}
                type="insights-sales-imports"
                allowNew={true}
                simpleView={false}
                newTitle={I18n.t('NEW_IMPORT')}
                pageCount={1}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('TABLE_IMPORT'),
                            accessor: 'col1',
                            type: 'filename',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_CHANNEL'),
                            accessor: 'col2',
                            type: 'channel',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_VOLUME'),
                            accessor: 'col3',
                            type: 'volume',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_SALES'),
                            accessor: 'col4',
                            type: 'price',
                            Link: false,
                        },
                        {
                            Header: I18n.t('ADDED'),
                            accessor: 'col5',
                            type: 'added',
                            Link: false,
                        },
                    ],
                }}
                data={dataImports}
            />
            <SimpleTable
                title={I18n.t('SALES_OVERVIEW_PRODUCTS')}
                type="insights-sales-products"
                columns={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            type: 'name',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_VOLUME'),
                            accessor: 'col3',
                            type: 'volume',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_SALES'),
                            accessor: 'col4',
                            type: 'price',
                            Link: false,
                        },
                    ],
                }}
                data={dataProducts}
            />
            <div className="pb-4" />
        </Wrapper>
    );
};

export default SalesOverview;
