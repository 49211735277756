import React, { useState, FC, useEffect } from 'react';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import { DateRangePicker } from 'react-date-range';
import Selectfield from '../select';
import moment from 'moment';
import i18n from 'i18next';
import { enGB, nl } from 'date-fns/locale';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';

interface Props {
    range?: boolean;
    filterDateStart?: any;
    filterDateEnd?: any;
    changedDateStart?: (start: any) => void;
    changedDateEnd?: (start: any, end: any) => void;
    filterDateText?: string;
    fromFilter?: boolean;
    showSingleValue?: any;
    minDate?: any;
    shownDate?: any;
}

const DatePicker: FC<Props> = (props) => {
    const [showDateRange, setShowDateRange] = useState(false);
    const [dateRangeValue, setDateRangeValue] = useState<any>('');

    const [ranges, setRanges] = useState<any>({
        startDate: new Date(),
        endDate: new Date(),
        key: 'selection',
    });

    let activeMonth: string = moment().format('MM');

    useEffect(() => {
        activeMonth = moment(ranges.startDate).format('MM');
    }, [ranges]);

    const changeDateRange = (newRanges: any) => {
        setDateRangeValue(newRanges);
        if (props.range) {
            if (props.changedDateStart) {
                props.changedDateStart(newRanges.selection.startDate);
            }
            if (props.changedDateEnd) {
                props.changedDateEnd(newRanges.selection.startDate, newRanges.selection.endDate);
            }
            setRanges({
                startDate: newRanges.selection.startDate,
                endDate: newRanges.selection.endDate,
                key: 'selection',
            });
        } else {
            if (props.changedDateStart) {
                props.changedDateStart(newRanges.selection.startDate);
            }

            var currentRange = ranges;
            if (moment(newRanges.selection.endDate).isAfter(moment(currentRange.endDate))) {
                setRanges({
                    startDate: newRanges.selection.endDate,
                    endDate: newRanges.selection.endDate,
                    key: 'selection',
                });
            } else {
                setRanges({
                    startDate: newRanges.selection.startDate,
                    endDate: newRanges.selection.startDate,
                    key: 'selection',
                });
            }

            setShowDateRange(false);
        }
    };

    const formatDate = () => {
        var date = '';

        if (props.filterDateStart == '') {
            date = '';
        } else if (props.filterDateEnd == props.filterDateStart) {
            date = moment(props.filterDateStart).format('DD-MM-YYYY');
        } else {
            date =
                moment(props.filterDateStart).format('DD-MM-YYYY') +
                ' - ' +
                moment(props.filterDateEnd).format('DD-MM-YYYY');
        }
        return date;
    };

    const resetDateFilter = () => {
        setDateRangeValue('');

        setRanges({
            startDate: new Date(),
            endDate: new Date(),
            key: 'selection',
        });

        if (props.range) {
            if (props.changedDateStart) {
                props.changedDateStart(null);
            }
            if (props.changedDateEnd) {
                props.changedDateEnd(null, null);
            }
        } else {
            if (props.changedDateStart) {
                props.changedDateStart(false);
            }
        }
    };

    function changedVisibleMonth(date: Date) {
        activeMonth = moment(date).format('MM');
    }

    function customDayContent(day: Date) {
        if (i18n.language == 'nl') {
            moment.locale('nl');
        }
        var dayFormatted = moment(day).format('DD/MM/YYYY');
        var renderedDay = moment(dayFormatted, 'DD/MM/YYYY').format('D');
        var isMonday = moment(dayFormatted, 'DD/MM/YYYY').isoWeekday();
        var renderedWeek;

        var differentMonth = false;
        var today = false;
        var selected = false;

        if (isMonday == 1) {
            renderedWeek = moment(dayFormatted, 'DD/MM/YYYY').isoWeek();
        }
        if (moment().isSame(day, 'day')) {
            today = true;
        }
        if (
            moment(day).isSame(ranges.startDate, 'day') ||
            (moment(day).isSameOrAfter(ranges.startDate) && moment(day).isSameOrBefore(ranges.endDate))
        ) {
            selected = true;
        }
        if (moment(day).format('MM') !== activeMonth) {
            differentMonth = true;
        }

        return (
            <div className="cal-days">
                {renderedWeek && <div className="weekday">{renderedWeek}</div>}
                <div
                    className={
                        selected ? 'selected-day' : today ? 'today-active' : differentMonth ? 'inactive-day' : 'day'
                    }
                >
                    {renderedDay}
                    <span className="today-line"></span>
                </div>
            </div>
        );
    }

    return (
        <div>
            <div className="date-range-picker">
                <Selectfield
                    clickable
                    disabled
                    value={''}
                    label={
                        props.showSingleValue
                            ? props.showSingleValue
                            : props.filterDateStart == ''
                            ? props.filterDateText
                            : formatDate()
                    }
                    onClick={() => setShowDateRange(!showDateRange)}
                    resetFilter={() => resetDateFilter()}
                    canReset={props.fromFilter && dateRangeValue !== '' ? true : false}
                    dateSingle={props.fromFilter ? false : true}
                    dateRange={props.fromFilter ? true : false}
                />
                {showDateRange && (
                    <div className={props.fromFilter ? 'calendar-picker align-center' : 'calendar-picker'}>
                        <DateRangePicker
                            ranges={[ranges]}
                            onChange={changeDateRange}
                            showMonthAndYearPickers={false}
                            rangeColors={['#6eb6b6']}
                            showDateDisplay={false}
                            editableDateInputs={false}
                            minDate={props.minDate}
                            shownDate={props.shownDate}
                            locale={i18n.language == 'nl' ? nl : enGB}
                            showPreview={props.range ? true : false}
                            dayContentRenderer={customDayContent}
                            onShownDateChange={(date) => changedVisibleMonth(date)}
                        />
                    </div>
                )}
            </div>
        </div>
    );
};
export default DatePicker;
