import React, { FC, useState, useEffect } from 'react';
import { useTable } from 'react-table';
import I18n from '../../../helpers/i18n';
import Button from '../../button';
import moment from 'moment';
import Select from '../../select';
import InputField from '../../input';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import AddIcon from '@mui/icons-material/Add';
import SimpleTable from '../detail-rows-single-col';
import Tooltip from '../../tooltip';
import DirectusManager from '../../../helpers/directusManager';
import Alert from '../../alert';
import Helpers from '../../../helpers/functions';

const Validators = Helpers.instance;

interface Props {
    data: any;
    type: string;
    title: string;
    //if contacts
    contacts?: any;
    selectedContact?: number;
    setSelectedContact?: (id: number) => void;
    //if factories
    factories?: any;
    selectedFactory?: number;
    setSelectedFactory?: (id: number, name: string) => void;
    //if suppliers
    suppliers?: any;
    selectedSupplier?: number;
    setSelectedSupplier?: (id: number) => void;
    //if products
    availableProducts?: any;
    productsAdded?: any;
    updateProducts?: (array: any, deletedProduct?: any) => void;
    availableSuppliers?: any;

    setDesiredDeliveryDate?: (date: any) => void;
    //save order
    saveOrder: (data: any) => void;
}

const Table: FC<Props> = (props) => {
    const columns = React.useMemo(() => props.data.headings, []) as any;
    const [data, setData] = useState<any>([]);
    const [orderID, setOrderID] = useState<string>('');
    const [dropdownSelectedProductDefault, setDropdownSelectedProductDefault] = useState<any>('none');
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [deleteRow, setDeleteRow] = useState<any>();

    let dropdownSelectedProduct = 0;
    let [countryFilter, setCountryFilter] = useState<string>();
    const [loader, setLoader] = useState(false);

    const [desiredDeliveryDate, setDesiredDeliveryDate] = useState(false);

    useEffect(() => {
        setLoader(true);
        let type = '';
        switch (props.type) {
            case 'tubes':
                type = 'tubes_order';
                break;
            case 'factories':
                type = 'factory_order';
                break;
            case 'suppliers':
                type = 'supplier_orders';
                break;
            default:
                break;
        }

        if (type === '') {
            return;
        }
        var availableOrders = DirectusManager.shared.directus.items(type);
        let sort: any = ['-date_created'];
        availableOrders
            .readMany({ fields: ['*.*.*.*'], limit: 10, sort: sort })
            .then((orders: any) => {
                if (orders.data !== undefined && orders.data.length > 0) {
                    if (props.type === 'tubes') {
                        var splittedId = orders.data[0].order_number.split('-');
                        splittedId[splittedId.length - 1] = orders.data[0].tube_order_id + 1;
                        setOrderID(splittedId.join('-'));
                    } else if (props.type === 'factories') {
                        var splittedId = orders.data[0].order_number.split('-');
                        splittedId[splittedId.length - 1] = orders.data[0].factory_order_id + 1;
                        setOrderID(splittedId.join('-'));
                    } else if (props.type === 'suppliers') {
                        var splittedId = orders.data[0].order_number.split('-');
                        splittedId[1] = orders.data[0].supplier_orders_id + 1;
                        setOrderID(splittedId.join('-'));
                    }
                } else {
                    if (props.type === 'tubes') {
                        let year = moment().format('YYYY');
                        setOrderID('PR-MT-' + year + '-XX');
                    } else if (props.type === 'factories') {
                        let year = moment().format('YYYY');
                        setOrderID('F-' + year + '-XX');
                    } else if (props.type === 'suppliers') {
                        let year = moment().format('YYYY');
                        setOrderID('S-XX-' + year);
                    }
                }
                setLoader(false);
            })
            .catch((error) => {
                setLoader(false);
                console.log(error.message);
            });
    }, []);

    const getCurrentDate = () => {
        var date = moment().format('DD-MM-YYYY');
        return date;
    };

    const addProduct = () => {
        var products = props.availableProducts;
        var newArray = props.productsAdded.slice(0);
        var product = {};

        for (var i = 0; i < products.length; i++) {
            if (products[i].id == dropdownSelectedProduct) {
                if (props.factories) {
                    product = {
                        id: products[i].id,
                        col1: products[i].name,
                        col2: products[i].article_code,
                        col3: products[i].packaging_code,
                        col4: -1,
                        col6:
                            products[i].min_amount == null || products[i].min_amount == undefined
                                ? 1
                                : products[i].min_amount,
                        min_amount:
                            products[i].min_amount == null || products[i].min_amount == undefined
                                ? 1
                                : products[i].min_amount,
                    };
                } else if (props.suppliers) {
                    product = {
                        id: products[i].id,
                        col1: products[i].name,
                        col2: products[i].article_code,
                        col3: products[i].packaging_code,
                        col4:
                            products[i].min_amount == null || products[i].min_amount == undefined
                                ? 1
                                : products[i].min_amount,
                        min_amount:
                            products[i].min_amount == null || products[i].min_amount == undefined
                                ? 1
                                : products[i].min_amount,
                        misi_stock: 0,
                        productrun_amount: 0,
                        orders_amount: 0,
                    };
                    addSupplierProduct(product, newArray);
                    return;
                } else {
                    product = {
                        id: products[i].id,
                        col1: products[i].name,
                        col2: products[i].article_code,
                        col3: products[i].packaging_code,
                        col4:
                            products[i].min_amount == null || products[i].min_amount == undefined
                                ? 1
                                : products[i].min_amount,
                        min_amount:
                            products[i].min_amount == null || products[i].min_amount == undefined
                                ? 1
                                : products[i].min_amount,
                    };
                }
                newArray.push(product);
                if (props.updateProducts) {
                    props.updateProducts(newArray);
                }
            }
        }
    };

    const addSupplierProduct = (product: any, newArray: any) => {
        const requestOptions = {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                product_id: product.id,
            }),
        };
        fetch(DirectusManager.shared.baseUrl + '/stock-checker', requestOptions)
            .then((response) => {
                return response.json();
            })
            .then((response) => {
                product.misi_stock = response.data.misi_stock;
                product.productrun_amount = response.data.productrun;
                product.orders_amount = response.data.orders_amount;

                newArray.push(product);
                if (props.updateProducts) {
                    props.updateProducts(newArray);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const updateAmount = (amount: number, id: any) => {
        var productsAdded: any = [].concat(props.productsAdded);

        if (amount < productsAdded[id].min_amount && props.factories) {
            productsAdded[id].col6 = productsAdded[id].min_amount;
            return;
        }
        if (props.factories) {
            productsAdded[id].col6 = amount;
        } else {
            productsAdded[id].col4 = amount;
        }

        if (props.updateProducts) {
            props.updateProducts(productsAdded);
        }
    };

    const updateSupplier = (supplier: string, id: any) => {
        var productsAdded = props.productsAdded;

        productsAdded[id].supplier = supplier;
        productsAdded[id].col4 = parseInt(supplier);
        if (props.updateProducts) {
            props.updateProducts(productsAdded);
        }
    };

    const showConfirmModalDelete = (rowkey: any) => {
        setDeleteRow(rowkey);
        setShowDeleteModal(true);
    };

    const deleteProduct = () => {
        setShowDeleteModal(false);

        const index = deleteRow.charAt(deleteRow.length - 1);
        var deletedProduct = props.productsAdded[index];
        var newArray = props.productsAdded.slice(0);
        newArray.splice(index, 1);
        if (props.updateProducts) {
            props.updateProducts(newArray, deletedProduct);
        }
    };

    const saveOrder = () => {
        var order = {};
        props.saveOrder(order);
    };

    const getWidth = (type: any) => {
        var style = 'minWidth';
        if (type && type == 'name') {
            style = style + ' med';
        } else if (type && type == 'code') {
            style = style + ' small';
        } else if (type && type == 'input') {
            style = style + ' amount';
        }
        return style;
    };

    function ProductTable({ columns, data }: { columns: any; data: any }) {
        const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
            columns,
            data,
        });

        useEffect(() => {
            setData(props.productsAdded);
        }, [props.productsAdded]);

        return (
            <table {...getTableProps()} className="table sub-table">
                <thead>
                {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column: any) => (
                            <>
                                <th {...column.getHeaderProps()} className={getWidth(column.render('Type'))}>
                                    {column.render('Header')}
                                </th>
                            </>
                        ))}
                        <th className="actions"></th>
                    </tr>
                ))}
                </thead>
                <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                    prepareRow(row);
                    var rowkey = row.getRowProps().key;
                    return (
                        <tr {...row.getRowProps()}>
                            {row.cells.map((cell: any) => {
                                if (cell.render('Type') == 'input') {
                                    return (
                                        <td {...cell.getCellProps()} width={150}>
                                            <InputField
                                                defaultValue={cell.value}
                                                disableActiveLabel
                                                minValue={cell.row.original?.min_amount ?? 0}
                                                onChange={(value: any) => updateAmount(value, row.index)}
                                            />
                                            {cell.row?.original?.min_amount !== 0 && props.type !== 'suppliers' && (
                                                <span className="min-amount">
                                                        {I18n.t('MIN_AMOUNT') + ' ' + cell?.row?.original?.min_amount}
                                                    </span>
                                            )}
                                            {props.type == 'suppliers' && (
                                                <div>
                                                        <span className="supplier-amount">
                                                            {I18n.t('MIN_MISI') +
                                                                ' ' +
                                                                Validators.amountDisplay(
                                                                    cell?.row?.original?.misi_stock ?? 0
                                                                )}
                                                        </span>
                                                    <span className="supplier-amount">
                                                            {I18n.t('MIN_PRODUCTRUN') +
                                                                ' ' +
                                                                Validators.amountDisplay(
                                                                    cell?.row?.original?.productrun_amount ?? 0
                                                                )}
                                                        </span>
                                                    <span className="supplier-amount">
                                                            {I18n.t('MIN_ORDERS') +
                                                                ' ' +
                                                                Validators.amountDisplay(
                                                                    cell?.row?.original?.orders_amount ?? 0
                                                                )}
                                                        </span>
                                                </div>
                                            )}
                                        </td>
                                    );
                                } else if (cell.render('Type') == 'select') {
                                    return (
                                        <td {...cell.getCellProps()} className="minp">
                                            <Select
                                                value={cell.value}
                                                defaultValue={cell.value}
                                                label={I18n.t('TABLE_SELECT')}
                                                array={props.availableSuppliers}
                                                onChange={(value: any) => updateSupplier(value, row.index)}
                                            />
                                        </td>
                                    );
                                } else {
                                    return (
                                        <td {...cell.getCellProps()} className={getWidth(cell.render('Type'))}>
                                            {cell.render('Cell')}
                                        </td>
                                    );
                                }
                            })}
                            <td className="actions">
                                <div
                                    className="d-flex align-items-center justify-content-center"
                                    onClick={() => showConfirmModalDelete(rowkey)}
                                >
                                    <Tooltip text={I18n.t('DELETE_ROW')}>
                                        <RemoveCircleIcon/>
                                    </Tooltip>
                                </div>
                            </td>
                        </tr>
                    );
                })}
                {props.availableProducts && (
                    <tr>
                        <td colSpan={props.type == 'factories' ? 4 : 3} className="minp">
                            <Select
                                value={countryFilter}
                                useValue={true}
                                array={props.availableProducts.map((product: {country: string}) => {
                                    return {
                                        id: product.country,
                                        name: product.country,
                                    };
                                }).filter((value: {id: string, name: string}, index: number, self: {id: string, name: string}[]) => self.map(entry => entry.id).indexOf(value.id) === index)}
                                label={I18n.t('FILTER_BY_COUNTRY')}
                                onChange={(value: any) => {setCountryFilter(value !== 'reset' ? value : undefined);}}
                            />
                            <Select
                                value={dropdownSelectedProductDefault}
                                array={props.availableProducts.filter((product: any) => {
                                    return countryFilter ? product.country === countryFilter : true;
                                })}
                                label={I18n.t('CHOOSE_PRODUCT')}
                                onChange={(value: any) => (dropdownSelectedProduct = value)}
                            />
                        </td>
                        <td colSpan={2} className="minp btn">
                            <Button
                                altStyle
                                icon={<AddIcon/>}
                                title={I18n.t('ADD_PRODUCT')}
                                onPress={() => addProduct()}
                            />
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        );
    }

    return (
        <>
            <SimpleTable
                largeSize
                title={props.title}
                save={() => saveOrder()}
                hideMandatory
                saveButtonText={I18n.t('SAVE_ORDER')}
                disabledButton={props.productsAdded.length > 0 ? false : true}
                rows={[
                    {
                        title: I18n.t('TABLE_ORDER_ID'),
                        value: orderID,
                    },
                    {
                        title: I18n.t('TABLE_ORDER_DATE'),
                        value: getCurrentDate(),
                    },
                    {
                        condition: true,
                        title: I18n.t('TABLE_DESIRED_DELIVERY'),
                        value: desiredDeliveryDate,
                        datePicker: true,
                        onChange: (newDate: any) => {
                            setDesiredDeliveryDate(newDate);
                            if (props.setDesiredDeliveryDate !== undefined) {
                                props.setDesiredDeliveryDate(newDate);
                            }
                        },
                    },
                    {
                        condition: props.factories && props.selectedFactory ? true : false,
                        title: I18n.t('ORDER_FACTORY'),
                        value: props.selectedFactory,
                        select: true,
                        array: props.factories ? props.factories : [],
                        onChange: (value: any) => {
                            if (props.setSelectedFactory) {
                                let index = props.factories.findIndex((el: any) => el.id == value);
                                props.setSelectedFactory(value, props.factories[index].name);
                            }
                        },
                    },
                    {
                        condition: props.suppliers && props.selectedSupplier ? true : false,
                        title: I18n.t('ORDER_SUPPLIER'),
                        value: props.selectedSupplier,
                        useValue: true,
                        defaultValue: props.selectedSupplier,
                        select: true,
                        array: props.suppliers ? props.suppliers : [],
                        onChange: (value: any) => props.setSelectedSupplier && props.setSelectedSupplier(value),
                    },
                    {
                        condition: props.contacts && props.setSelectedContact ? true : false,
                        title: I18n.t('ORDER_CONTACT_PERSON'),
                        value: props.selectedContact,
                        select: true,
                        array: props.contacts ? props.contacts : [],
                        onChange: (value: any) => props.setSelectedContact && props.setSelectedContact(value),
                    },
                    {
                        title: I18n.t('PRODUCTS'),
                        verAlignTop: true,
                        customComponent: <ProductTable columns={columns} data={data}/>,
                    },
                ]}
            />
            <Alert
                visible={showDeleteModal}
                onConfirm={() => deleteProduct()}
                onCancel={() => setShowDeleteModal(false)}
                title={I18n.t('DELETE_PRODUCT')}
                text={I18n.t('DELETE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_DELETE')}
                cancelText={I18n.t('ALERT_CANCEL_DELETE')}
                warning
            />
        </>
    );
};
export default Table;
