
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/detail-rows-single-col';
import { useNavigate } from "react-router-dom";
import DirectusManager from '../../../helpers/directusManager';
import Snackbar from '../../../components/snackbar';

const NewProductGroup : FC = (props) => {
    const navigate = useNavigate();
    const [name, setName] = useState('');
    const [nameError, setNameError] = useState(false);
    const [nameErrorText, setNameErrorText] = useState('');
    const [status, setStatus] = useState(0);
    const snackBar = useRef();

    const save = () => {
        setNameError(false);
        if(name !== ''){
            var productgroups = DirectusManager.shared.directus.items('product_group');
            productgroups.createOne({
                name: name,
                status: status == 0 ? 'active' : 'inactive'
            }).then(data => {
                navigate('/settings/product-groups', {state: 'new'});
            }).catch((error: any) => {
                if(error?.errors[0]?.extensions?.code == 'RECORD_NOT_UNIQUE'){
                    setNameError(true);
                    setNameErrorText(I18n.t('ERROR_NOT_UNIQUE_PRODUCT_GROUP'));
                } else {
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                }
            });
        } else {
            setNameError(true);
            setNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
    }

    return (
        <Wrapper
            title={I18n.t("NEW_PRODUCT_GROUP")}
        >
             <Table
                midSize
                title={I18n.t('GROUP_INFO')}
                save={() => save()}
                saveButtonText={I18n.t('ADD_GROUP')}
                rows={[
                    {
                        title: I18n.t('TABLE_GROUP_NAME'),
                        value: name,
                        onChange: (val: any) => setName(val),
                        input: true,
                        mandatory: true,
                        error: nameError,
                        errorText: nameErrorText
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        select: true,
                        array: [
                            {
                                id: 0,
                                name: 'Active'
                            },
                            {
                                id: 1,
                                name: 'Inactive'
                            }
                        ]
                    },
                ]}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default NewProductGroup;