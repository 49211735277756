import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import { useParams, useNavigate } from 'react-router-dom';
import Table from '../../../components/tables/order-detail';
import DirectusManager from '../../../helpers/directusManager';
import * as Sentry from '@sentry/react';

const TubeOrderDetail: FC = (props) => {
    const navigate = useNavigate();
    const params = useParams<{ id: any }>();
    const [data, setData] = useState<any>({});

    useEffect(() => {
        reloadOrder();
    }, []);

    const reloadOrder = () => {
        let orders = DirectusManager.shared.directus.items('factory_order');
        orders
            .readOne(params.id, { fields: ['*.*.*.*'] })
            .then((order) => {
                setData(order);
            })
            .catch((error) => {
                Sentry.captureException(error);
                console.log(error.message);
            });
    };

    const confirmDelete = (id: number) => {
        //Soft delete when user confirms a delete of the order
        var factoryOrders = DirectusManager.shared.directus.items('factory_order');
        factoryOrders
            .updateOne(id, { deleted: true })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmArchive = (id: number) => {
        //We change the status to the ID 3, which is archived(check status table to see all the possible id's on the CMS)
        var factoryOrders = DirectusManager.shared.directus.items('factory_order');
        factoryOrders
            .updateOne(id, { status: 3 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const confirmClose = (id: number) => {
        //We change the status to the ID 4, which is closed(check status table to see all the possible id's on the CMS)
        var factoryOrders = DirectusManager.shared.directus.items('factory_order');
        factoryOrders
            .updateOne(id, { status: 4 })
            .then((data) => {
                returnMain();
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                console.log(error);
            });
    };

    const returnMain = () => {
        navigate('/orders/factories');
    };

    return (
        <Wrapper title={I18n.t('FACTORY_ORDER') + ' ' + (data.order_number ? data.order_number : '')}>
            <Table
                data={data}
                factory={true}
                confirmDelete={(id: number) => confirmDelete(id)}
                confirmArchive={(id: number) => confirmArchive(id)}
                confirmClose={(id: number) => confirmClose(id)}
                reloadOrder={() => reloadOrder()}
            />
        </Wrapper>
    );
};

export default TubeOrderDetail;
