import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-simple';
import { Link } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';

const ContactOverview: FC = (props) => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const productsManager = DirectusManager.shared.directus.items('products');
        const productStockManager = DirectusManager.shared.directus.items('product_stock');
        const sort: any = ['name'];
        const products: any = await productsManager
            .readMany({
                limit: -1,
                fields: ['id', 'article_code', 'date_updated', 'name', 'stock', 'stock_date_updated'],
                sort: sort,
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));
        const productStock: any = await productStockManager
            .readMany({ limit: -1, fields: ['article_code.id', 'location', 'stock'] })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));

        const mapped = products.map((prod: any) => {
            const stocks = productStock.filter((x: any) => x.article_code.id === prod.id);
            let stock = 0;
            let location = '-';

            if (stocks.length > 0) {
                stock = stocks.reduce((partialSum: number, a: any) => {
                    const checkValue = a.stock !== null ? +a.stock : 0;
                    return partialSum + checkValue;
                }, 0);

                var locations = stocks.map((st: any) => st.location);
                var unique = locations.reduce(function (a: any, b: any) {
                    if (a.indexOf(b) < 0) a.push(b);
                    return a;
                }, []);
                location = unique.join(', ');
            }

            return {
                id: prod.article_code,
                col1: prod.name,
                col2: prod.article_code,
                col3: prod.stock,
                col4: location,
                col5: prod.date_updated ? moment(prod.date_updated).format('DD-MM-YYYY HH:mm') : '-',
                col6: prod.stock_date_updated ? moment(prod.stock_date_updated).format('DD-MM-YYYY HH:mm') : '-',
            };
        });

        setData(mapped);
    };

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <Table
                title={I18n.t('STOCK_PRODUCTS_OVERVIEW')}
                // showActions={false}
                type="insights-product"
                // allowNew={false}
                // simpleView={true}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            type: 'name',
                            Link: true,
                        },
                        {
                            Header: I18n.t('ARTICLE_CODE'),
                            accessor: 'col2',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('STOCK'),
                            accessor: 'col3',
                            type: 'stock',
                            Link: false,
                        },
                        {
                            Header: I18n.t('CHANGED'),
                            accessor: 'col5',
                            type: 'date',
                            Link: false,
                        },
                        {
                            Header: I18n.t('CHANGED_MISI'),
                            accessor: 'col6',
                            type: 'date',
                            Link: false,
                        },
                    ],
                }}
                data={data}
            />
        </Wrapper>
    );
};

export default ContactOverview;
