import React, { FC, useState, useRef, useCallback } from 'react';
// @ts-ignore
import debounce from 'lodash.debounce';

interface Props {
    onChange?: (value: any) => void;
    onBlur?: (event: any) => void;
    value?: any;
    defaultValue?: any;
    secure?: boolean;
    email?: boolean;
    error?: boolean;
    errorText?: string;
    label?: string;
    maxLength?: number;
    disabled?: boolean;
    numeric?: any;
    pattern?: any;
    autocomplete?: any;
    successIconDelayed?: any;
    validField?: boolean;
    disableActiveLabel?: boolean;
    //icon component
    icon?: any;
    price?: boolean;
    minValue?: number;
}
const InputField: FC<Props> = (props) => {
    const [changeTriggered, setChangeTriggered] = useState(false);
    const inputRef = useRef<any>();

    const getStyle = () => {
        var style = 'input';

        if (props.disabled) {
            style = style + ' disabled';
        }

        if (props.icon) {
            style = style + ' icon-spacing';
        }

        if (props.disableActiveLabel) {
            style = style + ' no-top-label';
        }

        return style;
    };

    const onChange = (event: any) => {
        setChangeTriggered(true);
        if (props.onChange && props.minValue === undefined) {
            props.onChange(event.target.value);
        } else if (props.onChange && props.minValue !== undefined) {
            debouncedChangeHandler(event.target.value);
        }
    };

    const changeHandler = (value: any) => {
        if (props.minValue && value < props.minValue) {
            if (props.onChange) {
                props?.onChange(props.minValue);
            }
            return;
        } else {
            if (props.onChange) {
                props?.onChange(value);
            }
        }
    };

    const debouncedChangeHandler = useCallback(debounce(changeHandler, 700), []);

    const onBlur = (event: any) => {
        if (props.minValue && event.target.value < props.minValue) {
            if (props.onChange) {
                props?.onChange(props.minValue);
            }
            inputRef.current.value = props.minValue;
            return;
        } else {
            if (props.onChange) {
                props?.onChange(event.target.value);
            }
        }
        if (props.onBlur) {
            props.onBlur(event.target.value);
        }
    };

    return (
        <div className="input-field">
            <div className={props.error ? 'input-wrapper error' : 'input-wrapper'}>
                <input
                    ref={inputRef}
                    type={props.numeric ? 'number' : props.secure ? 'password' : props.email ? 'email' : 'text'}
                    value={props.value}
                    min={props.minValue}
                    defaultValue={props.defaultValue}
                    className={getStyle()}
                    placeholder=" "
                    onBlur={onBlur}
                    onChange={onChange}
                    maxLength={props.maxLength ? props.maxLength : 500}
                    disabled={props.disabled}
                    autoComplete={props.autocomplete ? props.autocomplete : ''}
                />
                <label>{props.label}</label>
            </div>

            {props.icon && <div className="icon">{props.icon}</div>}
        </div>
    );
};
export default InputField;
