import { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';
import Snackbar from '../../../components/snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
import Helpers from '../../../helpers/functions';

/**
 *
 * Factory orders overview it's a list of all the factory orders where a user can do certain actions like sorting, searching, creating, editing and deleting.
 */

const FactoryOrders: FC = (props) => {
    const Validators = Helpers.instance;
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [activeFilter, setActiveFilter] = useState<any>(null);
    const [activeQuery, setActiveQuery] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const snackBar = useRef();

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    useEffect(() => {
        getData();

        if (location?.state == 'new') {
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_ORDER_ADDED'));
        }
    }, []);

    const getSupplierNames = (products: any) => {
        var suppliers: any = '';
        if (products !== undefined) {
            products.map((p: any) => {
                if (p.product_supplier !== null && p.product_supplier !== undefined) {
                    suppliers += p.product_supplier?.company + ',';
                }
            });
        }

        if (suppliers.length > 0 && suppliers.substring(suppliers.length - 1) == ',') {
            suppliers = suppliers.substring(0, suppliers.length - 1);
            suppliers = suppliers.split(',');
        }

        if (suppliers.length > 0) {
            var result: any = [];
            suppliers.forEach(function (item: string) {
                if (result.indexOf(item) < 0) {
                    result.push(item);
                }
            });
            suppliers = result.join(', ');
        }

        if (suppliers.length == 0) {
            suppliers = '-';
        }

        return suppliers;
    };

    const getData = (filters?: any, query?: string, page?: number) => {
        var filter: any = { deleted: false };
        var sort: any = ['-date_created'];
        var fields: any = [
            'factory_order_id',
            'factory.name',
            'order_number',
            'date_created',
            'status.name',
            'desired_delivery_date',
            'expected_delivery_date',
            'date_updated',
            'fo_product_id.amount',
            'fo_product_id.product_id.name',
            'fo_product_id.product_id.article_code',
        ];

        if (filters) {
            filter = filters;
            filter['deleted'] = false;
        }
        if (query) {
            filter = Validators.searchStringFilter('order_number', query);
            filter['deleted'] = false;
        }

        var factoryOrders = DirectusManager.shared.directus.items('factory_order');
        factoryOrders
            .readMany({
                fields: fields,
                meta: 'filter_count',
                filter,
                sort: sort,
                limit: 20,
                page: page ?? 1,
            })
            .then((orders) => {
                //static_product_name
                if (orders !== undefined && orders.data !== undefined && orders.data !== null) {
                    let mapped = orders.data.map((x: any) => {
                        var totalAmount = 0;
                        x.fo_product_id.map((p: any) => (totalAmount += p.amount));

                        const productNameAndArticleNr = x.fo_product_id.map((p: {product_id: {name: string, article_code: string}}) => p.product_id.name + ' (' + p.product_id.article_code + ')').join(', ');

                        return {
                            id: x.factory_order_id,
                            col0: x.factory_order_id,
                            col1: x.order_number ?? '',
                            col2: x.factory?.name,
                            col3: totalAmount,
                            col4: getSupplierNames(x.fo_product_id),
                            col5: moment(x.date_created).format('DD-MM-YYYY'),
                            col6: I18n.t('STATUS_' + x.status.name.toUpperCase()),
                            col7: x.expected_delivery_date
                                ? moment(x.expected_delivery_date).format('DD-MM-YYYY')
                                : '-',
                            col8: x.desired_delivery_date
                                ? moment(x.desired_delivery_date).format('DD-MM-YYYY')
                                : '-',
                            col9: x.date_updated ? moment(x.date_updated).format('DD-MM-YYYY HH:mm') : '-',
                            col10: productNameAndArticleNr,
                        };
                    });

                    setData(mapped);
                    if (orders.meta?.filter_count !== undefined) {
                        var totalPages = Math.ceil(orders.meta?.filter_count / 20);
                        setPageCount(totalPages);
                    }
                }
            })
            .catch((error) => {
                console.log(error.message);
            });
    };

    const filterOn = (type: string, value: any) => {
        if (type == 'status') {
            var dateCreated = null;
            if (activeFilter !== null && activeFilter.date_created !== undefined) {
                dateCreated = activeFilter.date_created;
            }

            let newFilter: any = null;
            if (dateCreated) {
                newFilter = {
                    date_created: dateCreated,
                };
            }

            if (value == 'reset') {
                setActiveFilter(newFilter);
                getData(newFilter);
            } else {
                if (newFilter === null) {
                    newFilter = {};
                }
                newFilter['status'] = { id: value };
                setActiveFilter(newFilter);
                getData(newFilter);
            }
        }
        if (type == 'date') {
            if (value == '') {
                let newFilter: any = null;
                if (activeFilter !== null && activeFilter.status !== undefined) {
                    newFilter = { status: activeFilter.status };
                }
                getData(newFilter);
                setActiveFilter(newFilter);
            } else {
                var dates = value.split(' - ');
                var dateFrom = dates[0];
                var dateTo = dates[1];
                var status = null;
                if (activeFilter !== null && activeFilter.status !== null) {
                    status = activeFilter.status;
                }
                let newFilter: any = {
                    date_created: {
                        _between: [dateFrom, dateTo],
                    },
                };
                if (status) {
                    newFilter['status'] = status;
                }

                setActiveFilter(newFilter);
                getData(newFilter);
            }
        }
    };

    const filterText = (value: any) => {
        setActiveQuery(value);
        if (activeFilter) {
            getData(activeFilter, value);
        } else {
            getData(null, value);
        }
    };

    const changePage = (page: number) => {
        getData(activeFilter, activeQuery, page + 1);
        setCurrentPage(page + 1);
    };

    const confirmDelete = (id: number) => {
        var factoryOrders = DirectusManager.shared.directus.items('factory_order');
        factoryOrders
            .updateOne(id, { deleted: true })
            .then((data) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ITEM_DELETED'));
                getData();
            })
            .catch((error: any) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const orderDateSort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if (moment(rowA.original.col4, 'DD-MM-YYYY').isBefore(moment(rowB.original.col4, 'DD-MM-YYYY'))) {
            return 1;
        } else {
            return -1;
        }
    };

    const expectedDeliverySort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if (moment(rowA.original.col7, 'DD-MM-YYYY').isBefore(moment(rowB.original.col7, 'DD-MM-YYYY'))) {
            return 1;
        } else {
            return -1;
        }
    };

    const desiredDeliverySort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if (moment(rowA.original.col8, 'DD-MM-YYYY').isBefore(moment(rowB.original.col8, 'DD-MM-YYYY'))) {
            return 1;
        } else {
            return -1;
        }
    };

    const updatedSort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if (moment(rowA.original.col9, 'DD-MM-YYYY HH:mm').isBefore(moment(rowB.original.col9, 'DD-MM-YYYY HH:mm'))) {
            return 1;
        } else {
            return -1;
        }
    };

    return (
        <Wrapper title={I18n.t('ORDERS')}>
            <Table
                title={I18n.t('ORDER_OVERVIEW_PURCHASE_ORDERS')}
                showActions={true}
                type="orders-factories"
                allowNew={true}
                loading={loader}
                newTitle={I18n.t('ADD_NEW_ORDER')}
                filterOn={(type: string, val: string) => filterOn(type, val)}
                filterText={(val: string) => filterText(val)}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('TABLE_ORDER_ID'),
                            accessor: 'col1',
                            type: 'id',
                            Link: true,
                        },
                        {
                            Header: I18n.t('TABLE_ORDER_FACTORY'),
                            accessor: 'col2',
                            type: 'factory',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_PRODUCT'),
                            accessor: 'col10',
                            type: 'text',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_AMOUNT'),
                            accessor: 'col3',
                            type: 'amount',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_ORDER_DATE'),
                            accessor: 'col5',
                            type: 'date',
                            Link: false,
                            sortType: orderDateSort,
                        },
                        {
                            Header: I18n.t('TABLE_STATUS'),
                            accessor: 'col6',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_EXPECTED_DELIVERY'),
                            accessor: 'col7',
                            type: 'date',
                            Link: false,
                            sortType: expectedDeliverySort,
                        },
                        {
                            Header: I18n.t('TABLE_DESIRED_DELIVERY'),
                            accessor: 'col8',
                            type: 'date',
                            Link: false,
                            sortType: desiredDeliverySort,
                        },
                        {
                            Header: I18n.t('TABLE_CHANGED'),
                            accessor: 'col9',
                            type: 'changed',
                            Link: false,
                            sortType: updatedSort,
                        },
                    ],
                }}
                data={data}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
                confirmDelete={(id: number) => confirmDelete(id)}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default FactoryOrders;
