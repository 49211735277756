import React, { FC, useState, useEffect } from 'react';
import {
    useTable,
    useSortBy,
    useFilters,
    useGlobalFilter,
    useAsyncDebounce,
    usePagination,
} from 'react-table';
import I18n from '../../../helpers/i18n';
import { Link, useNavigate } from 'react-router-dom';
import Loader from '../../loader';
import Helpers from '../../../helpers/functions';
import InputField from '../../input';
import Button from '../../button';
import CheckBox from '../../checkbox';

const Validators = Helpers.instance;

interface Props {
    columns: any;
    data: any;
    title?: string;
    save?: () => void;
    saveButtonText?: string;
    type?: string;
    loading?: boolean;
    onAmountChange?: (amount: number, index: number) => void;
    onCheckboxChange?: (checked: boolean, index: number) => void;
    disabledButton?: boolean;
    canDisableInput?: boolean;
}

const Table: FC<Props> = (props) => {
    const navigate = useNavigate();
    const columns = React.useMemo(() => props.columns.headings, []) as any;

    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchData = () => {
        var allData = props.data;
        setData(allData);
        setLoading(false);
    };

    useEffect(() => {
        setLoading(true);
        fetchData();
    }, []);

    const getClassName = (type: any) => {
        var style = '';

        if (type == 'check') {
            style = 'miniWidth';
        }

        if (
            type == 'status' ||
            type == 'amount' ||
            type == 'stock' ||
            type == 'price'
        ) {
            style = 'smWidth';
        }

        if (
            type == 'date' ||
            type == 'changed' ||
            type == 'code' ||
            type == 'input-price'
        ) {
            style = 'midWidth';
        }

        if(type == 'name'){
            style = 'largeWidth';
        }
        if(props.type === 'insights-sales-products' && type === 'price') {
            style = 'largeWidth';
        }

        return style;
    };
    

    const pathURL = (id: any, originalId: any) => {
        var type = props.type;
        var url;

        if (type == 'insights-sales-products') {
            let order: any = data[id];
            url = '/insights/sales/product/' + (order?.col0 ?? id);
        } else if (type == 'insights-product'){
            url = '/insights/stock-product/' + originalId;
        } else if (type == 'insights-tubes'){
            url = '/insights/stock-tubes/' + originalId;
        }

        return url;
    };

    function Table({
        columns,
        data,
        fetchData,
        loading,
    }: {
        columns: any;
        data: any;
        fetchData: any;
        loading: boolean;
    }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            rows,
        } = useTable({
            columns,
            data,
        });

        useEffect(() => {
            setData(props.data);
        }, [props.data]);

        return (
            <div
                className={`table-wrapper data-display ${
                    columns.length > 3 ? 'extra-large' : ''
                }`}
            >
                {props.title && (
                    <div className="table-header d-flex justify-content-between align-items-center">
                        <span className="title">{props.title}</span>
                    </div>
                )}
                <table {...getTableProps()} className="table">
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th
                                        {...column.getHeaderProps()}
                                        className={getClassName(
                                            column.render('type')
                                        )}
                                    >
                                        <div className="d-flex justify-content-between">
                                            {column.render('Header')}
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        <tr>
                            {loading ? (
                                <td colSpan={10000}>
                                    <div className="d-flex justify-content-center align-items-center empty-tr">
                                        <Loader show={true} />
                                    </div>
                                </td>
                            ) : (
                                !loading &&
                                data.length < 1 && (
                                    <td colSpan={10000}>
                                        <span className="d-flex justify-content-center align-items-center empty-tr">
                                            {I18n.t('TABLE_EMPTY')}
                                        </span>
                                    </td>
                                )
                            )}
                        </tr>
                        {rows.map((row: any, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps()}>
                                    {row.cells.map((cell: any) => {
                                        var type = cell.render('type');
                                        if (
                                            cell.row.original.group !==
                                                undefined &&
                                            cell.row.original.group == true
                                        ) {
                                            if (cell.column.id !== 'col1') {
                                                return null;
                                            }
                                            return (
                                                <td
                                                    colSpan={row.cells.length}
                                                    key={cell.column.id}
                                                    className="inner-table-group-heading"
                                                >
                                                    {cell.value}
                                                </td>
                                            );
                                        } else {
                                            if (cell.render('Link')) {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={getClassName(
                                                            type
                                                        )}
                                                    >
                                                        <Link
                                                            to={{
                                                                pathname:
                                                                    pathURL(
                                                                        cell.row
                                                                            .id
                                                                    , cell.row.original.id),
                                                            }}
                                                        >
                                                            {cell.render(
                                                                'Cell'
                                                            )}
                                                        </Link>
                                                    </td>
                                                );
                                            } else if (
                                                type == 'input' ||
                                                type == 'input-price'
                                            ) {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className="td-inner"
                                                    >
                                                        <InputField
                                                            disableActiveLabel
                                                            defaultValue={
                                                                cell.value
                                                            }
                                                            onChange={(
                                                                value: any
                                                            ) => {
                                                                if (
                                                                    props.onAmountChange
                                                                ) {
                                                                    props.onAmountChange(
                                                                        value,
                                                                        cell.row
                                                                            .id
                                                                    );
                                                                }
                                                            }}
                                                            onBlur={(
                                                                value: any
                                                            ) => {
                                                                if (
                                                                    props.onAmountChange
                                                                ) {
                                                                    props.onAmountChange(
                                                                        value,
                                                                        cell.row
                                                                            .id
                                                                    );
                                                                }
                                                            }}
                                                            disabled={
                                                                props.canDisableInput &&
                                                                cell.row
                                                                    .original
                                                                    .col5 ==
                                                                    false
                                                            }
                                                        />
                                                    </td>
                                                );
                                            } else if (type == 'check') {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={getClassName(
                                                            type
                                                        )}
                                                    >
                                                        {cell.value}
                                                        <CheckBox
                                                            defaultValue={
                                                                cell.value
                                                            }
                                                            onChange={(
                                                                checked: boolean
                                                            ) => {
                                                                if (
                                                                    props.onCheckboxChange
                                                                ) {
                                                                    props.onCheckboxChange(
                                                                        checked,
                                                                        cell.row
                                                                            .id
                                                                    );
                                                                }
                                                            }}
                                                        />
                                                    </td>
                                                );
                                            } else {
                                                return (
                                                    <td
                                                        {...cell.getCellProps()}
                                                        className={getClassName(
                                                            type
                                                        )}
                                                    >
                                                        {type == 'amount' ||
                                                        type == 'stock' ||
                                                        type == 'volume'
                                                            ? Validators.amountDisplay(
                                                                  cell.value
                                                              )
                                                            : type == 'price'
                                                            ? Validators.priceDisplay(
                                                                  cell.value
                                                              )
                                                            : cell.render(
                                                                  'Cell'
                                                              )}
                                                    </td>
                                                );
                                            }
                                        }
                                    })}
                                </tr>
                            );
                        })}
                    </tbody>
                </table>
                {props.saveButtonText && props.save && (
                    <div className="d-flex justify-content-end save-order-row">
                        <Button
                            defaultStyle
                            disabled={props.disabledButton ? true : false}
                            title={props.saveButtonText}
                            onPress={() => props.save && props.save()}
                        />
                    </div>
                )}
            </div>
        );
    }

    return !props.loading ? (
        <div className='table-bottom'>
            <Table
                columns={columns}
                data={data}
                fetchData={fetchData}
                loading={loading}
            />
        </div>
    ) : null;
};
export default Table;
