import I18n from './i18n';

export default class Validators {
    static instance: Validators = new Validators();

    validateString(text: string): [boolean, string] {
        const only_whitespaces = /^\s+$/;
        if (text === '' || only_whitespaces.test(text)) {
            return [true, I18n.t('FORM_ERROR_REQUIRED')];
        }
        return [text === '' ? true : false, I18n.t('FORM_ERROR_REQUIRED')];
    }

    validateEmail(email: string) {
        var emailValid = false;

        if (email !== '') {
            let reg = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            emailValid = reg.test(email) === true;
        }

        return emailValid;
    }

    amountDisplay(amount: any) {
        if (amount === undefined || amount === null) {
            return '';
        }
        var renderToString = amount.toString();
        var amountDisplay: any;

        if (renderToString.length <= 3) {
            amountDisplay = amount;
        }

        if (renderToString.length > 3) {
            amountDisplay = renderToString.slice(0, -3) + '.' + renderToString.slice(-3);
        }

        if (renderToString.length > 7) {
            amountDisplay = amountDisplay.slice(0, -7) + '.' + amountDisplay.slice(-7);
        }

        return amountDisplay;
    }

    priceDisplay(amount: any) {
        const formatted = amount.toLocaleString('de-DE', {
            minimumFractionDigits: 2,
        });
        return '€ ' + formatted;
    }

    camelize(str: string) {
        return str.charAt(0).toUpperCase() + str.slice(1);
    }

    camelCase(str: string) {
        return str.replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index === 0 ? word.toUpperCase() : word.toUpperCase();
        });
    }

    upperCase(str: string) {
        return str.toUpperCase();
    }

    lowerCase(str: string) {
        return str.toLowerCase();
    }

    searchStringFilter(field: string, string: string) {
        var query: any = {};

        query = {
            _or: [
                {
                    [field]: {
                        _contains: string,
                    },
                },
                {
                    [field]: {
                        _contains: this.camelize(string),
                    },
                },
                {
                    [field]: {
                        _contains: this.camelCase(string),
                    },
                },
                {
                    [field]: {
                        _contains: this.upperCase(string),
                    },
                },
                {
                    [field]: {
                        _contains: this.lowerCase(string),
                    },
                },
            ],
        };

        return query;
    }
}
