import React, { useState, FC, useEffect } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-simple';
import { useParams } from 'react-router-dom';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';

const ContactOverview: FC = (props) => {
    const { id } = useParams();
    const [title, setTitle] = useState('');
    const [locationStockData, setLocationStockData] = useState<any>([]);
    const [movementsData, setMovementsData] = useState<any>([]);

    useEffect(() => {
        const tubesStockManager = DirectusManager.shared.directus.items('tubes_stock');
        tubesStockManager
            .readMany({
                filter: {
                    product_id: { _eq: id },
                },
                limit: -1,
                fields: ['location', 'product_id.article_code', 'stock', 'date_created', 'product_id.name'],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    const products: any = data.data;

                    var locations = removeDuplicates(products, 'location');
                    locations = locations.sort((x: any, y: any) => (x.location > y.location ? 1 : -1));
                    var stockPerLocation = [];
                    for (let location of locations) {
                        let totalLocation = 0;
                        for (let product of products) {
                            if (product.location === location.location && product.stock !== undefined) {
                                totalLocation += product.stock;
                            }
                        }
                        stockPerLocation.push({ col1: location.location, col2: totalLocation });
                    }
                    setTitle(`${products[0].product_id.article_code} - ${products[0].product_id.name}`);
                    setLocationStockData(stockPerLocation);

                    const mappedMovements = products.map((prod: any) => {
                        var date_created = prod.date_created;
                        if (date_created === null) {
                            date_created = prod.product_id.date_created;
                        }
                        return {
                            col1: prod.location,
                            col2: prod.stock,
                            col3: date_created !== null ? moment(date_created).format('DD-MM-YYYY') : '-',
                        };
                    });
                    setMovementsData(mappedMovements);
                }
            })
            .catch((error) => console.log(error));
    }, []);

    function removeDuplicates(originalArray: any, prop: any) {
        var newArray = [];
        var lookupObject: any = {};

        for (var i in originalArray) {
            lookupObject[originalArray[i][prop]] = originalArray[i];
        }

        for (i in lookupObject) {
            newArray.push(lookupObject[i]);
        }
        return newArray;
    }

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <Table
                title={I18n.t('STOCK_PER_LOCATION') + ' ' + title}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('LOCATION'),
                            accessor: 'col1',
                            type: 'location',
                            Link: false,
                        },
                        {
                            Header: I18n.t('STOCK'),
                            accessor: 'col2',
                            type: 'stock',
                            Link: false,
                        },
                    ],
                }}
                data={locationStockData}
            />
            <div className="mt-5" />
            <Table
                title={I18n.t('STOCK_MOVEMENTS')}
                columns={{
                    headings: [
                        {
                            Header: I18n.t('LOCATION'),
                            accessor: 'col1',
                            type: 'location',
                            Link: false,
                        },
                        {
                            Header: I18n.t('STOCK'),
                            accessor: 'col2',
                            type: 'stock',
                            Link: false,
                        },
                        {
                            Header: I18n.t('ADDED'),
                            accessor: 'col3',
                            type: 'date',
                            Link: false,
                        },
                    ],
                }}
                data={movementsData}
            />
        </Wrapper>
    );
};

export default ContactOverview;
