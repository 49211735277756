import React, { FC, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Snackbar from '../../../components/snackbar';
import TWOFA from './components/2FA';
import ContactInformation from './components/contactInformation';

const Configuration: FC = (props) => {
    const snackBar = useRef();

    return (
        <Wrapper title={I18n.t('SETTINGS')}>
            <ContactInformation />
            <TWOFA />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default Configuration;
