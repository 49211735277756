import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-simple';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';

const ContactOverview: FC = (props) => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        getData();
    }, []);

    const getData = async () => {
        const productsManager = DirectusManager.shared.directus.items('products');
        const tubesStockManager = DirectusManager.shared.directus.items('tubes_stock');
        const sort: any = ['name'];
        const products: any = await productsManager
            .readMany({ limit: -1, fields: ['id, name', 'article_code', 'date_updated'], sort: sort })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));
        const tubesStock: any = await tubesStockManager
            .readMany({ limit: -1, fields: ['stock', 'product_id.id', 'location'] })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    return data.data;
                }
            })
            .catch((error) => console.log(error));

        var mapped = products.map((prod: any) => {
            const stocks = tubesStock.filter((x: any) => x.product_id.id === prod.id);
            let stock = 0;
            let location = '-';

            if (stocks.length > 0) {
                stock = stocks.reduce((partialSum: number, a: any) => {
                    const checkValue = a.stock !== null ? +a.stock : 0;
                    return partialSum + checkValue;
                }, 0);

                const locations = stocks.map((st: any) => st.location);
                location = locations.join(', ');
            }

            return {
                id: prod.id,
                col1: prod.name,
                col2: prod.article_code,
                col3: stock,
                col4: prod.date_updated ? moment(prod.date_updated).format('DD-MM-YYYY HH:mm') : '-',
            };
        });

        mapped = mapped.filter((x: any) => x.col3 > 0);

        setData(mapped);
    };

    return (
        <Wrapper title={I18n.t('INSIGHTS')}>
            <Table
                title={I18n.t('STOCK_TUBES_OVERVIEW')}
                type="insights-tubes"
                columns={{
                    headings: [
                        {
                            Header: I18n.t('PRODUCT'),
                            accessor: 'col1',
                            type: 'name',
                            Link: true,
                        },
                        {
                            Header: I18n.t('ARTICLE_CODE'),
                            accessor: 'col2',
                            type: 'code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('STOCK'),
                            accessor: 'col3',
                            type: 'stock',
                            Link: false,
                        },
                        {
                            Header: I18n.t('ADDED'),
                            accessor: 'col4',
                            type: 'date',
                            Link: false,
                        },
                    ],
                }}
                data={data}
            />
        </Wrapper>
    );
};

export default ContactOverview;
