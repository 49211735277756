import React, { useState, FC, useEffect, forwardRef, useImperativeHandle } from 'react';

interface Props {
    text: string,
    bottom?: boolean,
    innerTable?: boolean
}

const Tooltip : FC <Props> = (props, ref) => {
    const [visible, setVisible] = useState(false);

    useEffect(() => {
    }, []);

    return (
        <div className='tooltip-wrapper' onMouseEnter={() => setVisible(true)} onMouseLeave={() => setVisible(false)}>
            <div 
                className={`tooltip ${visible ? "show" : ""} 
                    ${props.bottom ? "bottom" : ""} 
                    ${props.innerTable ? "padding-fix" : ""}`}>
                {props.text}
            </div>
            {props.children}
        </div>
    );
}
export default Tooltip;