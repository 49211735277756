import React, {FC, useState, useEffect, useRef} from 'react';
import {useTable, useSortBy, usePagination} from 'react-table';
import I18n from '../../../helpers/i18n';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import {Link, useNavigate} from 'react-router-dom';
import AddIcon from '@mui/icons-material/Add';
import Button from '../../button';
import Filters from './filters';
import Loader from '../../loader';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import Helpers from '../../../helpers/functions';
import SanitizerIcon from '@mui/icons-material/Sanitizer';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Tooltip from '../../tooltip';
import Alert from '../../alert';
import moment from 'moment';
import SortingTable from '../sortable-overview-table';
import RelationsManager from '../../../helpers/relationsManager';
import Snackbar from '../../../components/snackbar';

const Validators = Helpers.instance;

interface Props {
    title: string;
    columns: any;
    data: any;
    showActions: boolean;
    allowNew: boolean;
    type: string;
    newTitle?: string;
    simpleView?: boolean;
    loading?: boolean;
    pageCount?: number;
    currentPage?: number;
    confirmDelete?: (id: number) => void;
    filterOn?: (type: string, val: string) => void;
    filterText?: (val: string) => void;
    changePage?: (page: number) => void;
    allowSorting?: boolean;
    newSorting?: (array: any) => void;
}

const Table: FC<Props> = (props) => {
    const navigate = useNavigate();
    const columns = React.useMemo(() => props.columns.headings, []) as any;
    const [data, setData] = useState([]);
    const snackBar = useRef();

    const [sortingData, setSortingData] = useState([]);
    const [sortingEnabled, setSortingEnabled] = useState(false);

    const [pageCount, setPageCount] = useState(0);
    const [pageSize] = useState(10);

    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showUnableToDeleteModal, setShowUnableToDeleteModal] = useState(false);
    const [unableSpecified, setUnableSpecified] = useState('');
    const [deleteID, setDeleteID] = useState<any>(null);

    const [filterSearch, setFilterSearch] = useState<any>(null);
    const [filterDateStart, setFilterDateStart] = useState<any>('');
    const [filterDateEnd, setFilterDateEnd] = useState<any>('');
    const [filterStatus, setFilterStatus] = useState('');
    const [filterFactory, setFilterFactory] = useState('');
    const [filterCountry, setFilterCountry] = useState('');
    const [filterSupplier, setFilterSupplier] = useState('');

    var newSortingOrder: any = [];
    var sortingChangesMade = false;

    useEffect(() => {
        const timeOutId = setTimeout(() => {
            if (props.filterText && filterSearch !== null) {
                props.filterText(filterSearch);
            }
        }, 500);
        return () => clearTimeout(timeOutId);
    }, [filterSearch]);

    const filterOnSearchTerm = (searchValue: any) => {
        setFilterSearch(searchValue);
    };

    const filterOnDate = (start: any, end?: string) => {
        if (end) {
            if (end == null) {
                setFilterDateStart('');
                setFilterDateEnd('');
                if (props.filterOn) {
                    props.filterOn('date', '');
                }
            } else {
                setFilterDateEnd(end);
                if (props.filterOn) {
                    var sFdate = moment(start).format('YYYY-MM-DD 00:00:00');
                    var fDate = moment(end).format('YYYY-MM-DD 23:59:59');
                    props.filterOn('date', sFdate + ' - ' + fDate);
                }
            }
        } else {
            if (start == null) {
                setFilterDateStart('');
                if (props.filterOn) {
                    props.filterOn('date', '');
                }
            } else {
                setFilterDateStart(start);
                if (props.filterOn) {
                    var sFdate = moment(start).format('YYYY-MM-DD 00:00:00');
                    var fDate = moment(start).format('YYYY-MM-DD 23:59:59');
                    props.filterOn('date', sFdate + ' - ' + fDate);
                }
            }
        }
    };

    const filterOnFactory = (factory_id: any) => {
        if (props.filterOn) {
            props.filterOn('factory', factory_id);
        }
    };

    const filterOnCountry = (country: any) => {
        if (props.filterOn) {
            props.filterOn('country', country);
        }
    }

    const filterOnSupplier = (supplier_id: any) => {
        if (props.filterOn) {
            props.filterOn('supplier', supplier_id);
        }
    };

    const filterOnStatus = (id: string) => {
        if (props.filterOn) {
            props.filterOn('status', id);
        }
    };

    const filterOnProductType = (id: string) => {
        if (props.filterOn) {
            props.filterOn('product_type', id);
        }
    };

    const showDelete = (id: number) => {
        setDeleteID(id);

        var functionName: any;
        if (props.type == 'product-groups') {
            functionName = RelationsManager.shared.productGroups(id);
        } else if (props.type == 'suppliers') {
            functionName = RelationsManager.shared.suppliers(id);
        } else if (props.type == 'factories') {
            functionName = RelationsManager.shared.factories(id);
        } else if (props.type == 'contacts') {
            functionName = RelationsManager.shared.contacts(id);
        } else if (props.type == 'products') {
            functionName = RelationsManager.shared.products(id);
        } else {
            setShowDeleteModal(true);
        }

        if (functionName === undefined) {
            return;
        }

        functionName
            .then((data: any) => {
                if (
                    data[0].length > 0 ||
                    (data[2] !== undefined && data[2].length > 0) ||
                    (data[3] !== undefined && data[3].length > 0)
                ) {
                    setUnableSpecified(data[1]);
                    setShowUnableToDeleteModal(true);
                } else {
                    setShowDeleteModal(true);
                }
            })
            .catch((error: any) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const newOrder = (type: string) => {
        if (type === 'orders-tubes') {
            navigate('/orders/tubes/new');
        } else if (type === 'orders-factories') {
            navigate('/orders/factories/new');
        } else if (type === 'orders-suppliers') {
            navigate('/orders/suppliers/new');
        } else if (type === 'contacts') {
            navigate('/settings/contacts/new');
        } else if (type === 'product-groups') {
            navigate('/settings/product-groups/new');
        } else if (type === 'suppliers') {
            navigate('/settings/suppliers/new');
        } else if (type === 'products') {
            navigate('/products/new');
        } else if (type === 'insights-sales-imports') {
            navigate('/insights/sales/new-import');
        } else if (type === 'factories') {
            navigate('/settings/factories/new');
        } else if (type === 'countries') {
            navigate('/settings/countries/new');
        }
    };

    const fetchData = (pageIndex: number, pageSize: number) => {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        var allData = props.data;
        setData(allData.slice(startRow, endRow) as any);
        if (allData.length > 0 && pageSize > 0) {
            setPageCount(Math.ceil(allData.length / pageSize));
        }
    };

    useEffect(() => {
        fetchData(0, pageSize);
    }, []);

    const toggleSort = () => {
        if (sortingEnabled) {
            if (props.newSorting && sortingChangesMade) {
                props.newSorting(newSortingOrder);
            }
            setSortingEnabled(false);
            fetchData(0, pageSize);
            setSortingData(props.data);
        } else {
            setSortingEnabled(true);
            setSortingData(props.data);
        }
    };

    const filters = () => {
        var type = props.type;

        var searchFilter = false;
        var searchPlaceholder = '';

        var searchFilter = false;
        var dateFilter = false;
        var statusFilter = false;
        var factoryFilter = false;
        var countryFilter = false;
        var supplierFilter = false;

        var datefilterText = '';

        if (type == 'orders-tubes' || type == 'orders-factories' || type == 'orders-suppliers') {
            searchFilter = true;
            searchPlaceholder = I18n.t('SEARCH_PLACEHOLDER_ORDERS');
            dateFilter = true;
            datefilterText = I18n.t('TABLE_ORDER_DATE');
            statusFilter = true;
        } else if (type == 'contacts' || type == 'product-groups' || type == 'suppliers' || type == 'factories') {
            searchFilter = true;
            searchPlaceholder = I18n.t('SEARCH_PLACEHOLDER');
        } else if (type == 'insights-sales-imports') {
            dateFilter = true;
            datefilterText = I18n.t('TABLE_DATE_RANGE');
            searchFilter = true;
            searchPlaceholder = I18n.t('SEARCH_PLACEHOLDER');
        } else if (type == 'products') {
            searchFilter = true;
            searchPlaceholder = I18n.t('SEARCH_PLACEHOLDER_PRODUCTS');
            factoryFilter = true;
            countryFilter = true;
        }

        if (type === 'orders-suppliers') {
            supplierFilter = true;
        }

        return (
            <Filters
                allowDateFilter={dateFilter}
                filterDateStart={filterDateStart}
                filterDateEnd={filterDateEnd}
                filterDateText={datefilterText}
                changedDateStart={(start) => filterOnDate(start)}
                changedDateEnd={(start, end) => filterOnDate(start, end)}
                allowStatusFilter={statusFilter}
                filterStatus={filterStatus}
                changedStatus={(val) => filterOnStatus(val)}
                allowSearchFilter={searchFilter}
                filterSearch={filterSearch}
                searchPlaceholder={searchPlaceholder}
                changedSearchValue={(val) => filterOnSearchTerm(val)}
                factoryFilter={factoryFilter}
                changedFactory={(val) => filterOnFactory(val)}
                filterFactory={filterFactory}
                countryFilter={countryFilter}
                changedCountry={(val) => filterOnCountry(val)}
                filterCountry={filterCountry}
                supplierFilter={supplierFilter}
                changedSupplier={(val) => filterOnSupplier(val)}
                filterSupplier={filterSupplier}
                changedProductType={(val) => filterOnProductType(val)}
                type={props.type}
                allowSorting={props.allowSorting}
                toggleSort={() => toggleSort()}
                sortingEnabled={sortingEnabled}
            />
        );
    };

    const getClassName = (type: any) => {
        var style = '';

        if (type == 'amount' || type == 'location' || type == 'stock') {
            style = 'smallWidth';
        }

        if (type == 'date' || type == 'changed' || type == 'code' || type == 'added' || type == 'status') {
            style = 'midWidth';
        }

        if (type == 'id') {
            style = 'largeWidth';
        }

        return style;
    };

    const pathURL = (id: number) => {
        var type = props.type;
        var url;

        if (type == 'orders-tubes') {
            let order: any = data[id];
            url = '/orders/tubes/' + (order?.col0 ?? id);
        } else if (type == 'orders-factories') {
            let order: any = data[id];
            url = '/orders/factories/' + (order?.col0 ?? id);
        } else if (type == 'orders-suppliers') {
            let order: any = data[id];
            url = '/orders/suppliers/' + (order?.col0 ?? id);
        } else if (type == 'contacts') {
            url = '/settings/contacts/' + id;
        } else if (type == 'product-groups') {
            url = '/settings/product-groups/' + id;
        } else if (type == 'suppliers') {
            url = '/settings/suppliers/' + id;
        } else if (type == 'countries') {
            url = '/settings/countries/' + id;
        } else if (type == 'products') {
            url = '/products/' + id;
        } else if (type == 'insights-product') {
            url = '/insights/stock-product/' + id;
        } else if (type == 'insights-tubes') {
            url = '/insights/stock-tubes/' + id;
        } else if (type == 'insights-sales-imports') {
            url = '/insights/sales/import/' + id;
        } else if (type == 'factories') {
            url = '/settings/factories/' + id;
        }

        return url;
    };

    function Table({columns, data, pageCount: controlledPageCount}: { columns: any; data: any; pageCount: number }) {
        const {
            getTableProps,
            getTableBodyProps,
            headerGroups,
            prepareRow,
            page,
            canPreviousPage,
            canNextPage,
            pageOptions,
            pageCount,
            gotoPage,
            nextPage,
            previousPage,
            state: {pageIndex, pageSize},
        } = useTable(
            {
                columns,
                data,
                initialState: {pageIndex: 0},
                manualPagination: true,
                pageCount: controlledPageCount,
            },
            useSortBy,
            usePagination
        );

        useEffect(() => {
            setData(props.data);
        }, [props.data, pageIndex, pageSize]);

        useEffect(() => {
            setPageCount(props.pageCount ?? 0);
        }, [props.pageCount]);

        const getPageIndex = () => {
            if (props.currentPage !== undefined) {
                return props.currentPage - 1;
            }

            return pageIndex;
        };

        return (
            <>
                <table {...getTableProps()} className="table overview">
                    <thead>
                    {headerGroups.map((headerGroup) => (
                        <tr {...headerGroup.getHeaderGroupProps()}>
                            {headerGroup.headers.map((column) => (
                                <th
                                    {...column.getHeaderProps(column.getSortByToggleProps())}
                                    className={getClassName(column.render('type'))}
                                >
                                    <div className="d-flex justify-content-between">
                                        {column.render('Header')}
                                        {column.isSorted ? (
                                            column.isSortedDesc ? (
                                                <KeyboardArrowUpIcon/>
                                            ) : (
                                                <KeyboardArrowDownIcon/>
                                            )
                                        ) : (
                                            <div className="empty-sort"/>
                                        )}
                                    </div>
                                </th>
                            ))}
                            {props.showActions && <th className="incl-actions"></th>}
                        </tr>
                    ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                    <tr>
                        {props.loading ? (
                            <td colSpan={10000}>
                                <div className="d-flex justify-content-center align-items-center empty-tr">
                                    <Loader show={true}/>
                                </div>
                            </td>
                        ) : (
                            !props.loading &&
                            data.length < 1 && (
                                <td colSpan={10000}>
                                        <span className="d-flex justify-content-center align-items-center empty-tr">
                                            {I18n.t('TABLE_EMPTY')}
                                        </span>
                                </td>
                            )
                        )}
                    </tr>
                    {page.map((row: any, i) => {
                        prepareRow(row);
                        var itemID: any;
                        if (row && row.original && row.original.id) {
                            itemID = row.original.id;
                        }
                        return (
                            <tr {...row.getRowProps()}>
                                {row.cells.map((cell: any) => {
                                    var type = cell.render('type');
                                    if (cell.render('Link')) {
                                        return (
                                            <td {...cell.getCellProps()} className={getClassName(type)}>
                                                <Link to={{pathname: pathURL(itemID)}}>
                                                    {cell.render('Cell')}
                                                </Link>
                                            </td>
                                        );
                                    } else {
                                        return (
                                            <td {...cell.getCellProps()} className={getClassName(type)}>
                                                {type == 'amount' || type == 'stock' || type == 'volume'
                                                    ? Validators.amountDisplay(cell.value)
                                                    : type == 'price'
                                                        ? Validators.priceDisplay(cell.value)
                                                        : cell.render('Cell')}
                                            </td>
                                        );
                                    }
                                })}
                                {props.showActions && (
                                    <td className="last">
                                        <div className="actions d-flex align-items-center justify-content-center">
                                            {props.type == 'suppliers' && (
                                                <Tooltip text={I18n.t('TOOLTIP_FILTER_SUPPLIER')} innerTable>
                                                    <Link
                                                        to={{
                                                            pathname: '/orders/suppliers/?supplier_id=' + itemID,
                                                        }}
                                                    >
                                                        <FilterAltIcon className="product-icon"/>
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            {props.type == 'suppliers' && (
                                                <Tooltip text={I18n.t('TOOLTIP_PRODUCTS')} innerTable>
                                                    <Link
                                                        to={{
                                                            pathname: '/settings/suppliers/' + itemID + '/products',
                                                        }}
                                                    >
                                                        <SanitizerIcon className="product-icon"/>
                                                    </Link>
                                                </Tooltip>
                                            )}
                                            <Tooltip text={I18n.t('TOOLTIP_EDIT')} innerTable>
                                                <Link to={{pathname: pathURL(itemID)}}>
                                                    <EditIcon className="edit-icon"/>
                                                </Link>
                                            </Tooltip>
                                            <Tooltip text={I18n.t('TOOLTIP_DELETE')} innerTable>
                                                <div onClick={() => showDelete(itemID)}>
                                                    <DeleteIcon/>
                                                </div>
                                            </Tooltip>
                                        </div>
                                    </td>
                                )}
                            </tr>
                        );
                    })}
                    </tbody>
                </table>
                {!props.simpleView && (
                    <div className="table-footer pagination d-flex justify-content-center">
                        <div className="d-flex page-nav-block justify-content-end">
                            <>
                                <button
                                    onClick={() => {
                                        gotoPage(0);
                                        if (props.changePage) {
                                            props.changePage(1);
                                        }
                                    }}
                                    disabled={!canPreviousPage}
                                    className="last"
                                >
                                    <ArrowBackIosNewIcon/>
                                    <ArrowBackIosNewIcon/>
                                </button>
                                <button
                                    onClick={() => {
                                        previousPage();
                                        if (props.changePage) {
                                            props.changePage(getPageIndex() - 1);
                                        }
                                    }}
                                    disabled={!canPreviousPage}
                                >
                                    <ArrowBackIosNewIcon/>
                                </button>
                            </>
                        </div>
                        {pageOptions.length > 0 &&
                            pageOptions.map((item: any, index: number) => {
                                return (
                                    <div
                                        className={item == getPageIndex() ? 'page-block active' : 'page-block'}
                                        key={index}
                                        onClick={() => {
                                            gotoPage(item);
                                            if (props.changePage) {
                                                props.changePage(item);
                                            }
                                        }}
                                    >
                                        {item + 1}
                                    </div>
                                );
                            })}
                        <div className="d-flex page-nav-block">
                            <>
                                <button
                                    onClick={() => {
                                        nextPage();
                                        if (props.changePage) {
                                            props.changePage(getPageIndex() + 1);
                                        }
                                    }}
                                    disabled={!canNextPage}
                                >
                                    <ArrowForwardIosIcon/>
                                </button>
                                {' '}
                                <button
                                    onClick={() => {
                                        gotoPage(pageCount - 1);
                                        if (props.changePage) {
                                            props.changePage(pageCount - 1);
                                        }
                                    }}
                                    disabled={!canNextPage}
                                    className="last"
                                >
                                    <ArrowForwardIosIcon/>
                                    <ArrowForwardIosIcon/>
                                </button>
                            </>
                        </div>
                    </div>
                )}
            </>
        );
    }

    return (
        <div className="table-bottom">
            <div className={`table-wrapper ${props.simpleView ? 'large-size' : ''}`}>
                <div className="table-header d-flex justify-content-between align-items-center">
                    <span className="title">{props.title}</span>
                    <div className="d-flex justify-content-center align-items-center">
                        {filters()}
                        {props.allowNew && (
                            <div className="new-order-button">
                                <Button
                                    defaultStyle
                                    title={props.newTitle ? props.newTitle : ''}
                                    icon={<AddIcon/>}
                                    onPress={() => newOrder(props.type)}
                                />
                            </div>
                        )}
                    </div>
                </div>
                {!props.loading && sortingEnabled ? (
                    <SortingTable
                        columns={columns}
                        data={sortingData}
                        loading={props.loading}
                        newSorting={(array: any, changes: boolean) => {
                            newSortingOrder = array;
                            sortingChangesMade = changes;
                        }}
                    />
                ) : (
                    <Table columns={columns} data={data} pageCount={pageCount}/>
                )}
            </div>
            <Alert
                visible={showDeleteModal}
                onConfirm={() => {
                    setShowDeleteModal(false);
                    props.confirmDelete && props.confirmDelete(deleteID);
                }}
                onCancel={() => setShowDeleteModal(false)}
                title={I18n.t('DELETE_TITLE')}
                text={I18n.t('DELETE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_DELETE')}
                cancelText={I18n.t('ALERT_CANCEL_DELETE')}
                warning
            />
            <Alert
                visible={showUnableToDeleteModal}
                onConfirm={() => setShowUnableToDeleteModal(false)}
                onCancel={() => setShowUnableToDeleteModal(false)}
                title={I18n.t('UNABLE_TO_DELETE_TITLE')}
                text={I18n.t('UNABLE_TO_DELETE_TEXT') + ' ' + unableSpecified}
                confirmText={I18n.t('ALERT_OK')}
                defaultStyle
            />
            <Snackbar ref={snackBar}/>
        </div>
    );
};
export default Table;
