
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import { Link } from "react-router-dom";

const ContactOverview : FC = (props) => {
    const [data, setData] = useState<any>([]);

    useEffect(() => {
        //get data here and put it into consts
    },[]);

    return (
        <Wrapper
            title={I18n.t("INSIGHTS")}
        >
            <Table
                title={I18n.t("PLANNING")}
                showActions={false}
                type="insights-planning"
                allowNew={false}
                simpleView={true}
                columns={{
                    "headings": []
                }}
                data={data}
            />
        </Wrapper>
    );
}

export default ContactOverview;