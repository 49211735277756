import { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';
import Table from '../../components/tables/overview-table-full';
import DirectusManager from '../../helpers/directusManager';
import moment from 'moment';
import Snackbar from '../../components/snackbar';
import { useNavigate, useLocation } from 'react-router-dom';
import Helpers from '../../helpers/functions';
import * as Sentry from '@sentry/react';

/**
 *
 * The product overview it's a list of all the products sorted by name(default) where a user can do certain actions like sorting, searching, creating, editing and deleting.
 */

const ProductOverview: FC = (props) => {
    const Validators = Helpers.instance;
    const [loader, setLoader] = useState(false);
    const [data, setData] = useState<any>([]);
    const [activeFilter, setActiveFilter] = useState<any>(null);
    const [pageCount, setPageCount] = useState<number>(1);
    const [activeQuery, setActiveQuery] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const snackBar = useRef();

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    useEffect(() => {
        getData();
        if (location?.state == 'new') {
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_PRODUCT_ADDED'));
        }
    }, []);

    const getData = async (filters?: any, query?: string, page?: number) => {
        var filter: any = { deleted: false };
        var sort: any = ['name'];
        var fields: any = ['id', 'name', 'article_code', 'factory.name', 'status', 'date_updated', 'country'];
        if (filters) {
            filter = filters;
        }
        if (query) {
            var nameFilter = Validators.searchStringFilter('name', query);
            filter['_or'] = nameFilter['_or'];
        }
        setLoader(true);
        //Load all the products in the system and map them into the structure that is required to be displayed in the table.
        //To improve performance and reduce security issues, only the required data to populate the table is fetched
        var countries = DirectusManager.shared.directus.items('countries');

        let mappedCountries: any = await countries.readMany({ fields: ['uuid', 'name'], limit: -1 }).then((data) => {
            return data.data;
        });

        var products = DirectusManager.shared.directus.items('products');

        products
            .readMany({ filter, fields: fields, meta: 'filter_count', sort: sort, limit: -1, page: page ?? 1 })
            .then((allProducts) => {
                if (allProducts !== undefined && allProducts.data !== undefined && allProducts.data !== null) {
                    let mapped = allProducts.data.map((x: any) => ({
                        id: x.id,
                        col1: x.name,
                        col2: x.article_code,
                        col3: x.factory.name,
                        col4: I18n.t('STATUS_' + x.status.toUpperCase()),
                        col5: mappedCountries.find((y: any) => y.uuid == x.country)?.name ?? '-',
                        col6: x.date_updated ? moment(x.date_updated).format('DD-MM-YYYY HH:mm') : '-',
                    }));
                    setData(mapped);
                    setLoader(false);
                    if (allProducts.meta?.filter_count !== undefined) {
                        var totalPages = Math.ceil(allProducts.meta?.filter_count / 20);
                        setPageCount(1);
                    }
                }
            })
            .catch((error) => {
                Sentry.captureException(error);
                setLoader(false);
            });
    };

    const confirmDelete = (id: number) => {
        var products = DirectusManager.shared.directus.items('products');
        products
            .readOne(id)
            .then((data: any) => {
                var products = DirectusManager.shared.directus.items('products');
                products
                    .deleteOne(id)
                    .then((data) => {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ITEM_DELETED'));
                        getData();
                    })
                    .catch((error: any) => {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    });
            })
            .catch((error: any) => {
                Sentry.captureException(error);
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const filterOn = (type: string, value: any) => {
        if (type == 'factory') {
            if (value == 'reset') {
                setActiveFilter(null);
                getData();
            } else {
                setActiveFilter({ factory: { id: value }, deleted: false });
                getData({ factory: { id: value }, deleted: false });
            }
        } else if (type == 'product_type') {
            if (value == 'reset') {
                setActiveFilter(null);
                getData();
            } else {
                setActiveFilter({ type: value, deleted: false });
                getData({ type: value, deleted: false });
            }
        } else if (type == 'country') {
            if (value == 'reset') {
                setActiveFilter(null);
                getData();
            } else {
                setActiveFilter({ country: { uuid: value }, deleted: false });
                getData({ country: { uuid: value }, deleted: false });
            }
        }
    };

    const filterText = (value: any) => {
        setActiveQuery(value);
        if (activeFilter) {
            getData(activeFilter, value);
        } else {
            getData(null, value);
        }
    };

    const changePage = (page: number) => {
        getData(activeFilter, activeQuery, page + 1);
        setCurrentPage(page + 1);
    };

    const setNewSorting = (array: any) => {
        if (array.length > 0) {
            setData([]);
            setLoader(true);
            var counter = 0;
            var error = false;
            for (var a = 0; a < array.length; a++) {
                counter = counter + 1;
                var products = DirectusManager.shared.directus.items('products');
                products
                    .updateOne(array[a].id, {
                        sort: a,
                    })
                    .then((data) => {
                        if (counter == array.length) {
                            if (error) {
                                setLoader(false);
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                            } else {
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ORDER_IS_UPDATED'));
                                getData();
                            }
                        }
                    })
                    .catch((error: any) => {
                        error = true;
                        if (counter == array.length) {
                            if (error) {
                                setLoader(false);
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                            } else {
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ORDER_IS_UPDATED'));
                                getData();
                            }
                        }
                    });
            }
        }
    };

    return (
        <Wrapper title={I18n.t('PRODUCTS')}>
            <Table
                title={I18n.t('PRODUCT_OVERVIEW_TITLE')}
                showActions={true}
                type="products"
                allowNew={true}
                loading={loader}
                newTitle={I18n.t('NEW_PRODUCT')}
                allowSorting
                columns={{
                    headings: [
                        {
                            Header: I18n.t('TABLE_NAME'),
                            accessor: 'col1',
                            type: 'name',
                            Link: true,
                        },
                        {
                            Header: I18n.t('ARTICLE_CODE'),
                            accessor: 'col2',
                            type: 'article_code',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_FACTORY'),
                            accessor: 'col3',
                            type: 'factory',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_STATUS'),
                            accessor: 'col4',
                            type: 'status',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_COUNTRY'),
                            accessor: 'col5',
                            type: 'country',
                            Link: false,
                        },
                        {
                            Header: I18n.t('TABLE_CHANGED'),
                            accessor: 'col6',
                            type: 'changed',
                            Link: false,
                        },
                    ],
                }}
                data={data}
                confirmDelete={(id: number) => confirmDelete(id)}
                filterOn={(type: string, val: string) => filterOn(type, val)}
                filterText={(val: string) => filterText(val)}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
                newSorting={(array: any) => setNewSorting(array)}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default ProductOverview;
