import React, { FC, useEffect, useState, useRef } from 'react';
import I18n from '../../../helpers/i18n';
import GeneralInfo from './general';
import ProcessInfo from './process';
import ProductInfo from './products';
import StatusInfo from './status';
import Alert from '../../alert';
import Snackbar from '../../../components/snackbar';
import DirectusManager from '../../../helpers/directusManager';
import { useNavigate, useLocation } from 'react-router-dom';
interface Props {
    data: any;
    factory?: boolean;
    supplier?: boolean;
    tubes?: boolean;
    loading?: boolean;
    confirmDelete?: (id: number) => void;
    confirmArchive?: (id: number) => void;
    confirmClose?: (id: number) => void;
    reloadOrder?: () => void;
    createOrderToMisi?: () => void;
}

const DetailTable: FC<Props> = (props) => {
    const snackBar = useRef();
    const [availableSuppliers, setAvailableSuppliers] = useState<any>([]);

    const [allProducts, setAllProducts] = useState<any>([]); //all data
    const [availableProducts, setAvailableProducts] = useState<any>([]); //available only after adding
    const [productsAdded, setProductsAdded] = useState<any>([]); //selected/added products

    const [orderID, setOrderID] = useState(0);
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [showArchiveOrder, setShowArchiveOrder] = useState(false);
    const [showCloseOrder, setShowCloseOrder] = useState(false);

    const [alreadySentMail, setAlreadySentMail] = useState(false);

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    const [loader, setLoader] = useState(false);
    const [supplierLoader, setSupplierLoader] = useState(false);

    useEffect(() => {
        setLoader(true);
        setSupplierLoader(true);
        if (props.tubes) {
            if (props.data !== undefined && props.data.to_product_id !== undefined) {
                let mapped = props.data.to_product_id.map((x: any) => {
                    return {
                        col1: x.static_product_name,
                        col2: x.product_id?.packaging_code_MT,
                        col3: x.amount,
                        id: x.to_products_id,
                        product_id: x.product_id.id,
                        min_amount: x.product_id.minimum_order_amount,
                    };
                });
                if (props.data?.order_email_sent == true) {
                    setAlreadySentMail(true);
                }
                mapped = mapped.sort((x: any, y: any) => (x.col1 > y.col1 ? 1 : -1));
                setLoader(false);
                setSupplierLoader(false);
                setProductsAdded(mapped);
                loadProducts(mapped);
            } else {
                loadProducts();
            }
            setOrderID(props.data.tube_order_id);
        }
        if (props.factory) {
            if (props.data !== undefined && props.data.fo_product_id !== undefined) {
                loadAvailableSuppliers();

                let mapped = props.data.fo_product_id.map((x: any) => {
                    return {
                        col1: x.static_product_name,
                        col2: x.product_id?.article_code,
                        col3: x.product_id?.factory_article_code,
                        col4: '€' + +x.product_id?.price_factory,
                        col5: x.amount,
                        col6: '€' + (x.amount * +x.product_id?.price_factory),
                        product_id: x.product_id.id,
                        min_amount: x.product_id.minimum_order_amount,
                        id: x.fo_products_id,
                    };
                });
                mapped = mapped.sort((x: any, y: any) => (x.col1 > y.col1 ? 1 : -1));
                setLoader(false);
                setProductsAdded(mapped);
                loadProducts(mapped, props.data.factory?.id);
            } else {
                loadProducts();
            }
            setOrderID(props.data.factory_order_id);
        }
        if (props.supplier) {
            if (props.data !== undefined && props.data.so_product_id !== undefined) {
                let mapped = props.data.so_product_id.map((x: any) => {
                    return {
                        col1: x.static_product_name,
                        col2: x.product_id?.article_code ?? '-',
                        col3: x.product_id?.factory_article_code ?? '-',
                        col4: x.amount,
                        product_id: x.product_id.id,
                        min_amount: x.product_id.minimum_order_amount,
                        id: x.so_products_id,
                    };
                });
                mapped = mapped.sort((x: any, y: any) => (x.col1 > y.col1 ? 1 : -1));
                setLoader(false);
                setSupplierLoader(false);
                setProductsAdded(mapped);
                loadProducts(mapped);
            } else {
                loadProducts();
            }
            setOrderID(props.data.supplier_orders_id);
        }
    }, [props.data]);

    useEffect(() => {
        if (location?.state == 'updated') {
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
        }
    }, []);

    const loadProducts = (orderProducts?: any, factoryId?: number) => {
        let sortProducts: any = ['name'];
        var availableProducts = DirectusManager.shared.directus.items('products');
        var filter: any = { deleted: false, status: 'active' };
        if (factoryId) {
            filter['factory'] = { id: factoryId };
        }
        var fields = ['id', 'name', 'packaging_code_MT', 'minimum_order_amount', 'country.*'];
        if (props.factory) {
            fields = ['id', 'name', 'factory_article_code', 'minimum_order_amount', 'article_code', 'country.*', 'price_factory'];
        }
        availableProducts
            .readMany({
                filter: filter,
                sort: sortProducts,
                fields: fields,
                limit: -1,
            })
            .then((products: any) => {
                if (
                    products !== undefined &&
                    products.data !== undefined &&
                    products.data !== null &&
                    products.data.length > 0
                ) {
                    if (props.tubes) {
                        let mapped = products.data.map((x: any) => {
                            return {
                                id: x.id,
                                name: x.name + (x.country?.code ? ' (' + x.country?.code + ')' : ''),
                                packaging_code: x.packaging_code_MT ?? '-',
                                min_amount: x.minimum_order_amount ?? '-',
                            };
                        });
                        setAllProducts(mapped);
                        checkAddedProductsAgainstAvailable(mapped, orderProducts);
                    } else if (props.factory) {
                        let mapped = products.data.map((x: any) => {
                            return {
                                id: x.id,
                                name: x.name + (x.country?.code ? ' (' + x.country?.code + ')' : ''),
                                article_code: x.article_code,
                                factory_code: x.factory_article_code,
                                min_amount: x.minimum_order_amount ?? '-',
                            };
                        });
                        setAllProducts(mapped);
                        checkAddedProductsAgainstAvailable(mapped, orderProducts);
                    } else if (props.supplier) {
                        loadSupplierProducts(orderProducts);
                    }
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    const loadSupplierProducts = (orderProducts?: any) => {
        var supplier = props.data.supplier_id.supplier_id;
        var products = DirectusManager.shared.directus.items('supplier_products');
        products
            .readMany({
                limit: -1,
                filter: { deleted: false, supplier_id: supplier },
                fields: [
                    'product_id.id, product_id.name',
                    'product_id.article_code',
                    'product_id.factory_article_code',
                    'supplier_id.supplier_id',
                    'product_id.minimum_order_amount',
                    'product_id.country.*',
                ],
            })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.product_id.id,
                        name: x.product_id.name + (x.product_id.country?.code ? ' (' + x.product_id.country?.code + ')' : ''),
                        article_code: x.product_id.article_code,
                        factory_code: x.product_id.factory_article_code,
                        supplier: x.supplier_id.supplier_id ?? null,
                        min_amount: x.product_id.minimum_order_amount,
                    }));
                    mapped.sort((x: any, y: any) => (x.name > y.name ? 1 : -1));
                    setAllProducts(mapped);
                    checkAddedProductsAgainstAvailable(mapped, orderProducts);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    };

    const checkAddedProductsAgainstAvailable = (allProducts: any, orderProducts: any) => {
        if (orderProducts.length > 0) {
            var availableProductsArray: any = [].concat(allProducts);

            for (let product of orderProducts) {
                availableProductsArray = availableProductsArray.filter((x: any) => x.id !== product.product_id);
            }
            setAvailableProducts(availableProductsArray);
        } else {
            setAvailableProducts(allProducts);
        }
    };

    const loadAvailableSuppliers = async () => {
        let sortSuppliers: any = ['company'];
        var availableSuppliers = DirectusManager.shared.directus.items('supplier');

        availableSuppliers
            .readMany({
                filter: { status: 'active' },
                sort: sortSuppliers,
                fields: ['supplier_id', 'company'],
                limit: -1,
            })
            .then((suppliers: any) => {
                if (suppliers !== undefined && suppliers.data !== undefined && suppliers.data !== null) {
                    let mapped = suppliers.data.map((x: any) => {
                        return { id: x.supplier_id, name: x.company };
                    });
                    setAvailableSuppliers(mapped);
                    setSupplierLoader(false);
                }
            })
            .catch((error) => {
                setSupplierLoader(false);
                console.log(error.message);
            });
    };

    const addProduct = (newArray: any) => {
        var updatedArray: any = [].concat(availableProducts);
        var selected: any = [].concat(newArray);

        setProductsAdded(selected);

        for (let product of selected) {
            var index = updatedArray.findIndex((el: any) => el.id === product.id);
            if (index !== -1) {
                updatedArray.splice(index, 1);
            }
        }
        setAvailableProducts(updatedArray);
    };

    const deleteProduct = (id: number) => {
        var type = '';

        if (props.tubes) {
            type = 'tubes_order_products';
        } else if (props.factory) {
            type = 'factory_order_products';
        } else if (props.supplier) {
            type = 'supplier_order_products';
        }
        var availP: any = [].concat(availableProducts);
        var allP: any = [].concat(allProducts);
        var addedP: any = [].concat(productsAdded);
        var restoreCode = '';

        for (let addedProduct of addedP) {
            if (addedProduct.id === id) {
                restoreCode = addedProduct.col2;
            }
        }

        for (var a = 0; a < allP.length; a++) {
            if (restoreCode !== '' && allP[a].article_code == restoreCode) {
                availP.push(allP[a]);
                availP.sort((x: any, y: any) => (x.name > y.name ? 1 : -1));
                setAvailableProducts(availP);
            }
        }

        addedP = addedP.filter((x: any) => x.id !== id);
        setProductsAdded(addedP);

        var orderProducts = DirectusManager.shared.directus.items(type);
        orderProducts
            .deleteOne(id)
            .then((data) => {
                if (props.reloadOrder !== undefined) {
                    props.reloadOrder();
                }
            })
            .catch((error) => {
                console.log(error);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    const saveProducts = () => {
        if (props.tubes) {
            var type = 'tubes_order_products';
            var orderId = props.data.tube_order_id;
            var factory = props.data.factories;

            var orderProducts = DirectusManager.shared.directus.items(type);
            for (let product of productsAdded) {
                if (product.isNew) {
                    orderProducts
                        .createOne({
                            tube_order_id: orderId,
                            amount: product.col3,
                            factory: factory,
                            static_product_name: product.col1,
                            product_id: product.id,
                        })
                        .then((data) => {
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                            if (props.reloadOrder !== undefined) {
                                props.reloadOrder();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                        });
                } else {
                    orderProducts
                        .updateOne(product.id, { amount: product.col3 })
                        .then((data) => {
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                            if (props.reloadOrder !== undefined) {
                                props.reloadOrder();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                        });
                }
            }
        }
        if (props.supplier) {
            var type = 'supplier_order_products';
            var orderId = props.data.supplier_orders_id;

            var orderProducts = DirectusManager.shared.directus.items(type);
            for (let product of productsAdded) {
                if (product.isNew) {
                    orderProducts
                        .createOne({
                            supplier_order_id: orderId,
                            amount: product.col4,
                            static_product_name: product.col1,
                            product_id: product.id,
                            deleted: false,
                        })
                        .then((data) => {
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                            if (props.reloadOrder !== undefined) {
                                props.reloadOrder();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                        });
                } else {
                    orderProducts
                        .updateOne(product.id, { amount: product.col4 })
                        .then((data) => {
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                            if (props.reloadOrder !== undefined) {
                                props.reloadOrder();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                        });
                }
            }
        }
        if (props.factory) {
            var type = 'factory_order_products';
            var orderId = props.data.factory_order_id;

            var orderProducts = DirectusManager.shared.directus.items(type);
            for (let product of productsAdded) {
                if (product.isNew) {
                    orderProducts
                        .createOne({
                            factory_order_id: orderId,
                            amount: parseInt(product.col5),
                            product_id: product.id,
                            static_product_name: product.col1,
                            status: 'open',
                            deleted: false,
                        })

                        // supplier: product.col4
                        .then((data) => {
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                            if (props.reloadOrder !== undefined) {
                                props.reloadOrder();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                        });
                } else {
                    let updateData: any = {};
                    if (product.col5 !== undefined) {
                        updateData = { amount: product.col5 };
                    }

                    orderProducts
                        .updateOne(product.id, updateData)
                        .then((data) => {
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('SAVED_PRODUCTS'));
                            if (props.reloadOrder !== undefined) {
                                props.reloadOrder();
                            }
                        })
                        .catch((error) => {
                            console.log(error);
                            // @ts-ignore:next-line
                            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                        });
                }
            }
        }
    };

    const canProductsBeChanged = () => {
        if (props.data?.status?.name == 'open') {
            return true;
        } else {
            return false;
        }
    };

    const saveConfirmedAmount = (amount: number) => {
        //Confirm amounts and set to next step
        //For tubes next step is order_to_misi
        //For factories next step is order_to_supplier
        //For suppliers next step is completed
        var type = '';
        var orderId = 0;
        var params = {};
        if (props.tubes) {
            type = 'tubes_order';
            orderId = props.data.tube_order_id;
            params = { mt_amount_received: amount, status: 7 };
        } else if (props.factory) {
            type = 'factory_order';
            orderId = props.data.factory_order_id;
            params = { misi_amount_sent: amount, status: 2 };
        } else if (props.supplier) {
            type = 'supplier_orders';
            orderId = props.data.supplier_orders_id;
            params = { amount_received: amount, status: 3 };
        }
        if (type === '') {
            return;
        }
        var orders = DirectusManager.shared.directus.items(type);
        orders
            .updateOne(orderId, params)
            .then((data) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('SAVED_ORDER_AMOUNT'));
                if (props.reloadOrder !== undefined) {
                    props.reloadOrder();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const saveReceivedAmount = (amount: number) => {
        //Confirm amounts and set to complete
        var type = '';
        var orderId = 0;
        var params = {};
        if (props.tubes) {
            type = 'tubes_order';
            orderId = props.data.tube_order_id;
            params = { misi_amount_received: amount, status: 3 };
        } else if (props.factory) {
            type = 'factory_order';
            orderId = props.data.factory_order_id;
            params = { supplier_amount_received: amount, status: 2 };
        }
        if (type === '') {
            return;
        }
        var orders = DirectusManager.shared.directus.items(type);
        orders
            .updateOne(orderId, params)
            .then((data) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('SAVED_ORDER_AMOUNT'));
                if (props.reloadOrder !== undefined) {
                    props.reloadOrder();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const saveConfirmedFromAmount = (amount: number) => {
        //Confirm amounts and set to complete
        var type = '';
        var orderId = 0;
        var params = {};
        if (props.factory) {
            type = 'factory_order';
            orderId = props.data.factory_order_id;
            params = { supplier_amount_received: amount, status: 2 };
        } else {
            return;
        }
        var orders = DirectusManager.shared.directus.items(type);
        orders
            .updateOne(orderId, params)
            .then((data) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('SAVED_ORDER_AMOUNT'));
                if (props.reloadOrder !== undefined) {
                    props.reloadOrder();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const confirmOrder = () => {
        //Confirm order and set to confirmed
        var type = '';
        var orderId = 0;
        var params = {};
        if (props.factory) {
            type = 'factory_order';
            orderId = props.data.factory_order_id;
            params = { status: 8 };
        } else if (props.tubes) {
            type = 'tubes_order';
            orderId = props.data.tube_order_id;
            params = { status: 10 };
        } else {
            return;
        }
        var orders = DirectusManager.shared.directus.items(type);
        orders
            .updateOne(orderId, params)
            .then((data) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('SAVED'));
                if (props.reloadOrder !== undefined) {
                    props.reloadOrder();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const orderFactoryToSupplier = () => {
        var orders = DirectusManager.shared.directus.items('supplier_orders');
        orders
            .updateOne(props.data.supplier_orders_id, {
                order_to_supplier: true,
                status: 5,
            })
            .then((data) => {
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ORDER_SENT'));
                if (props.reloadOrder !== undefined) {
                    props.reloadOrder();
                }
            })
            .catch((error: any) => {
                console.log(error);
            });
    };

    const orderToMisi = () => {
        if (props.createOrderToMisi) {
            props.createOrderToMisi();
        }
    };

    return !props.loading && !loader ? (
        <>
            <div className="d-flex page-margins">
                <GeneralInfo
                    data={props.data}
                    factory={props.factory}
                    supplier={props.supplier}
                    tubes={props.tubes}
                    showDeleteModal={() => setShowDeleteModal(true)}
                    closeOrder={() => setShowCloseOrder(true)}
                    archiveOrder={() => setShowArchiveOrder(true)}
                    reloadOrder={() => {
                        if (props.reloadOrder !== undefined) {
                            props.reloadOrder();
                        }
                    }}
                />
                <ProcessInfo
                    data={props.data}
                    saveConfirmedAmount={(amount: number) => saveConfirmedAmount(amount)}
                    saveReceivedAmount={(amount: number) => saveReceivedAmount(amount)}
                    saveConfirmedFromAmount={(amount: number) => saveConfirmedFromAmount(amount)}
                    confirmOrder={() => confirmOrder()}
                    factory={props.factory}
                    supplier={props.supplier}
                    tubes={props.tubes}
                    alreadySentMail={alreadySentMail}
                    orderFactoryToSupplier={orderFactoryToSupplier}
                    orderToMisi={orderToMisi}
                    reloadOrder={() => {
                        if (props.reloadOrder !== undefined) {
                            props.reloadOrder();
                        }
                    }}
                />
            </div>
            <div className="d-flex page-margins pb-5">
                <ProductInfo
                    availableProducts={availableProducts}
                    productsAdded={productsAdded}
                    updateProducts={(array) => addProduct(array)}
                    deleteProduct={(id) => deleteProduct(id)}
                    saveProducts={() => saveProducts()}
                    changeAllowed={canProductsBeChanged()}
                    factory={props.factory}
                    supplier={props.supplier}
                    tubes={props.tubes}
                    availableSuppliers={availableSuppliers}
                />
                {props.tubes ? (
                    <StatusInfo data={props.data} />
                ) : props.factory ? (
                    <div></div>
                ) : (
                    <div className="w-100" />
                )}
            </div>
            <Alert
                visible={showDeleteModal}
                onConfirm={() => {
                    setShowDeleteModal(false);
                    props.confirmDelete && props.confirmDelete(orderID);
                }}
                onCancel={() => setShowDeleteModal(false)}
                title={I18n.t('DELETE_ORDER') + ' ' + props.data?.order_number}
                text={I18n.t('DELETE_TEXT_PRODUCT')}
                confirmText={I18n.t('ALERT_CONFIRM_DELETE')}
                cancelText={I18n.t('ALERT_CANCEL_DELETE')}
                warning
            />
            <Alert
                visible={showCloseOrder}
                onConfirm={() => {
                    setShowCloseOrder(false);
                    props.confirmClose && props.confirmClose(orderID);
                }}
                onCancel={() => setShowCloseOrder(false)}
                title={I18n.t('CLOSE_TITLE') + ' ' + props.data?.order_number}
                text={I18n.t('CLOSE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_CLOSE')}
                cancelText={I18n.t('ALERT_CANCEL_CLOSE')}
                warning
            />
            <Alert
                visible={showArchiveOrder}
                onConfirm={() => {
                    setShowArchiveOrder(false);
                    props.confirmArchive && props.confirmArchive(orderID);
                }}
                onCancel={() => setShowArchiveOrder(false)}
                title={I18n.t('ARCHIVE_TITLE') + ' ' + props.data?.order_number}
                text={I18n.t('ARCHIVE_TEXT')}
                confirmText={I18n.t('ALERT_CONFIRM_ARCHIVE')}
                cancelText={I18n.t('ALERT_CANCEL_ARCHIVE')}
                defaultStyle
            />
            <Snackbar ref={snackBar} />
        </>
    ) : null;
};
export default DetailTable;
