import { FC, useEffect, useState, useRef } from 'react';
import I18n from '../../../../../helpers/i18n';
import Table from '../../../../../components/tables/detail-rows-single-col';
import DirectusManager from '../../../../../helpers/directusManager';
import Snackbar from '../../../../../components/snackbar';

const ContactInformation: FC = (props) => {
    const snackBar = useRef();
    const [loader, setLoader] = useState(false);

    const [tubeEmail, setTubeEmail] = useState('');
    const [tubeEmailError, setTubeEmailError] = useState(false);
    const [tubeEmailErrorText, setTubeEmailErrorText] = useState('');

    const [lowStockEmail, setLowStockEmail] = useState('');
    const [lowStockEmailError, setLowStockEmailError] = useState(false);
    const [lowStockEmailErrorText, setLowStockEmailErrorText] = useState('');

    const [loadedPromoCodes, setLoadedPromoCodes] = useState([]);
    const [promoCodes, setPromoCodes] = useState<string>('');

    useEffect(() => {
        setLoader(true);
        var config = DirectusManager.shared.directus.items('config');
        config
            .readMany({ limit: -1 })
            .then((data: any) => {
                var this_data = data.data[0];
                if (this_data !== undefined && this_data !== null) {
                    if (this_data.email_multitubes) {
                        setTubeEmail(this_data.email_multitubes);
                    }
                    if (this_data.email_low_stock_notice) {
                        setLowStockEmail(this_data.email_low_stock_notice);
                    }
                    setLoader(false);
                }
            })
            .catch((error) => {
                setLoader(false);
            });

        var promoCodes = DirectusManager.shared.directus.items('promo_codes');
        promoCodes
            .readMany({ limit: -1 })
            .then((data: any) => {
                var dataPromoCodes = data.data;
                if (dataPromoCodes.length == 0) {
                    setLoadedPromoCodes([]);
                    setPromoCodes('');
                } else {
                    setLoadedPromoCodes(dataPromoCodes);
                    var promoCodesChain = dataPromoCodes.map((x: any) => x.code).toString();
                    setPromoCodes(promoCodesChain);
                }
            })
            .catch((error) => {
                setLoader(false);
            });
    }, []);

    const save = () => {
        setLowStockEmailError(false);
        setTubeEmailError(false);

        if (lowStockEmail == '') {
            setLowStockEmailError(true);
            setLowStockEmailErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (tubeEmail == '') {
            setTubeEmailError(true);
            setTubeEmailErrorText(I18n.t('ERROR_REQUIRED'));
        }

        if (lowStockEmail !== '' && tubeEmail !== '') {
            var config = DirectusManager.shared.directus.items('config');
            config
                .updateOne(1, {
                    email_low_stock_notice: lowStockEmail,
                    email_multitubes: tubeEmail,
                })
                .then((data) => {
                    if (promoCodes.split(',').length > 0) {
                        updatePromoCodes();
                    } else {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('SETTINGS_SAVED'));
                    }
                })
                .catch((error: any) => {
                    setLoader(false);
                    // @ts-ignore:next-line
                    snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                });
        }
    };

    const updatePromoCodes = () => {
        var promoCodesRequest = DirectusManager.shared.directus.items('promo_codes');
        promoCodesRequest
            .deleteMany(loadedPromoCodes.map((x: any) => x.id))
            .then((data) => {
                var newCodes: any[] = [];
                promoCodes.split(',').forEach((x: any) => {
                    newCodes.push({ code: x, status: 'active' });
                });
                promoCodesRequest
                    .createMany(newCodes)
                    .then((data) => {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('SETTINGS_SAVED'));
                    })
                    .catch((error: any) => {
                        setLoader(false);
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    });
            })
            .catch((error: any) => {
                setLoader(false);
                // @ts-ignore:next-line
                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
            });
    };

    return (
        <div>
            <Table
                midSize
                title={I18n.t('CONTACT_INFO')}
                save={() => save()}
                loading={loader}
                saveButtonText={I18n.t('SAVE_CHANGES')}
                rows={[
                    {
                        title: I18n.t('CONFIG_EMAIL_TUBES'),
                        value: tubeEmail,
                        onChange: (val: any) => setTubeEmail(val),
                        input: true,
                        mandatory: true,
                        error: tubeEmailError,
                        errorText: tubeEmailErrorText,
                        secure: false,
                        autocomplete: false,
                    },
                    {
                        title: I18n.t('CONFIG_EMAIL_LOW_STOCK'),
                        value: lowStockEmail,
                        onChange: (val: any) => setLowStockEmail(val),
                        input: true,
                        mandatory: true,
                        error: lowStockEmailError,
                        errorText: lowStockEmailErrorText,
                        secure: false,
                        autocomplete: false,
                    },
                    {
                        title: I18n.t('CONFIG_PROMOCODES'),
                        value: promoCodes,
                        onChange: (val: any) => setPromoCodes(val),
                        input: true,
                        mandatory: false,
                        secure: false,
                        autocomplete: false,
                    },
                ]}
            />
            <Snackbar ref={snackBar} />
        </div>
    );
};

export default ContactInformation;
