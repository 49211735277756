import React, { FC } from 'react';

interface Props {
}

const Footer : FC<Props> = (props) => {
    return (
        <div>
        </div>
    );
}
export default Footer;