
import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../../components/wrapper';
import I18n from '../../../helpers/i18n';
import Table from '../../../components/tables/overview-table-full';
import DirectusManager from '../../../helpers/directusManager';
import moment from 'moment';
import Snackbar from '../../../components/snackbar';
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import Helpers from '../../../helpers/functions';

const SupplierOrders : FC = (props) => {
    const Validators = Helpers.instance;
    const [data, setData] = useState<any>([]);
    const [pageCount, setPageCount] = useState<number>(1);
    const [activeFilter, setActiveFilter] = useState<any>(null);
    const [activeQuery, setActiveQuery] = useState<any>(null);
    const [activeSupplier, setActiveSupplier] = useState<any>(null);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [loader, setLoader] = useState(false);
    const [searchParams, setSearchParams] = useSearchParams();
    const snackBar = useRef();

    const location = useLocation() as any;
    const navigate = useNavigate() as any;

    useEffect(() => {
        if(searchParams.get("supplier_id")) {
            var supplierId: string = searchParams.get("supplier_id")?.toString() ?? "";
            if(supplierId) {
                setActiveSupplier(parseInt(supplierId));
                getData(null, parseInt(supplierId));
            } else {
                getData();
            }
        } else {
            getData();
        }

        if(location?.state == 'new'){
            navigate('', { state: [] });
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('NEW_ORDER_ADDED'));
        }
        
    },[]);

    const getData = (filters?: any, supplier?: any, query?: string, page?: number) => {
        var filter: any = {deleted: false};
        var sort: any = ['-date_created'];
        var fields: any = ['supplier_orders_id', 'supplier_id.company', 'order_number', 'date_created', 'status.name', 'expected_delivery_date', 'date_updated', 'so_product_id.amount'];

        if(filters){
            filter = filters;
            filter['deleted'] = false;
        }
        if(query) {
            filter = Validators.searchStringFilter('order_number', query);
            filter['deleted'] = false;
        }
        if(supplier) {
            filter['supplier_id'] = supplier;
        }

        var supplierOrders = DirectusManager.shared.directus.items('supplier_orders');
        supplierOrders.readMany({fields: fields, filter, meta: 'filter_count', sort: sort, limit: 20, page: page ?? 1}).then( orders => {
            if(orders !== undefined && orders.data !== undefined && orders.data !== null) {
                let mapped = orders.data.map((x: any) => {
                    var totalAmount = 0;
                    x.so_product_id.map((p: any) => totalAmount += p.amount);

                    return ({
                        id: x.supplier_orders_id,
                        col0: x.supplier_orders_id,
                        col1: x.order_number ?? "",
                        col2: x.supplier_id?.company,
                        col3: totalAmount,
                        col4: moment(x.date_created).format('DD-MM-YYYY'),
                        col5: I18n.t("STATUS_" + x.status.name.toUpperCase()),
                        col6: x.date_updated ? moment(x.date_updated).format('DD-MM-YYYY HH:mm') : "-"
                    });
                });
                setData(mapped);
                if(orders.meta?.filter_count !== undefined) {
                    var totalPages = Math.ceil(orders.meta?.filter_count/20);
                    setPageCount(totalPages);
                }
            }
        }).catch( error => {
            console.log(error.message);
        });
    }

    const filterOn = (type: string, value: any) => {
        if (type == 'status') {
            var dateCreated = null;
            if(activeFilter !== null && activeFilter.date_created !== undefined) {
                dateCreated = activeFilter.date_created;
            }

            let newFilter: any = null;
            if(dateCreated) {
                newFilter = {
                    date_created: dateCreated
                }
            }

            if (value == 'reset') {
                setActiveFilter(newFilter);
                getData(newFilter, activeSupplier);
            } else {
                if(newFilter === null) {
                    newFilter = {};
                }
                newFilter['status'] = { id: value };
                setActiveFilter(newFilter);
                getData(newFilter, activeSupplier);
            }
        }
        if (type == 'date') {
            if (value == '') {
                let newFilter: any = null;
                if(activeFilter !== null && activeFilter.status !== undefined) {
                    newFilter = {status: activeFilter.status};
                }
                getData(newFilter, activeSupplier);
                setActiveFilter(newFilter);
            } else {
                var dates = value.split(' - ');
                var dateFrom = dates[0];
                var dateTo = dates[1];
                var status = null;
                if(activeFilter !== null && activeFilter.status !== null) {
                    status = activeFilter.status;
                }
                let newFilter: any = {
                    date_created: {
                        _between: [dateFrom, dateTo],
                    }
                };
                if(status) {
                    newFilter['status'] = status;
                }
                
                setActiveFilter(newFilter);
                getData(newFilter, activeSupplier);
            }
        }
        if(type == 'supplier') {
            if(value == '' || value == 'reset'){
                getData(activeFilter, null, activeQuery);
                setActiveSupplier(null);
            } else {
                getData(activeFilter, value, activeQuery);
                setActiveSupplier(value);
            }
        }
    }

    const filterText = (value: any) => {
        setActiveQuery(value);
        getData(activeFilter, activeSupplier, value);
    }

    const changePage = (page: number) => {
        getData(activeFilter, activeSupplier, activeQuery, page+1);
        setCurrentPage(page+1);
    }

    const confirmDelete = (id: number) => {
        var supplierOrders = DirectusManager.shared.directus.items('supplier_orders');
        supplierOrders.updateOne(id, {deleted: true}).then(data => {
            setLoader(false);
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ITEM_DELETED'));
            getData();
        }).catch((error: any) => {
            setLoader(false);
            // @ts-ignore:next-line
            snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
        });
    }

    const orderDateSort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if(moment(rowA.original.col4, 'DD-MM-YYYY').isBefore(moment(rowB.original.col4, 'DD-MM-YYYY'))) {
            return 1;
        } else {
            return -1;
        }
    }

    const updatedSort = (rowA: any, rowB: any, columnId: string, desc: boolean) => {
        if(moment(rowA.original.col6, 'DD-MM-YYYY HH:mm').isBefore(moment(rowB.original.col6, 'DD-MM-YYYY HH:mm'))) {
            return 1;
        } else {
            return -1;
        }
    }

    return (
        <Wrapper
            title={I18n.t("ORDERS")}
        >
            <Table
                title={I18n.t("ORDER_OVERVIEW_SUPPLIERS")}
                showActions={true}
                type="orders-suppliers"
                allowNew={true}
                loading={loader}
                newTitle={I18n.t('ADD_NEW_ORDER')}
                filterOn={(type: string, val: string) => filterOn(type, val)}
                filterText={(val: string) => filterText(val)}
                columns={{
                    "headings": [
                        {
                            Header: I18n.t('TABLE_ORDER_ID'),
                            accessor: 'col1',
                            type: 'id',
                            Link: true
                        },
                        {
                            Header: I18n.t('TABLE_ORDER_SUPPLIER'),
                            accessor: 'col2',
                            type: 'supplier',
                            Link: false
                        },
                        {
                            Header: I18n.t('TABLE_AMOUNT'),
                            accessor: 'col3',
                            type: 'amount',
                            Link: false
                        },
                        {
                            Header: I18n.t('TABLE_ORDER_DATE'),
                            accessor: 'col4',
                            type: 'date',
                            Link: false,
                            sortType: orderDateSort
                        },
                        {
                            Header: I18n.t('TABLE_STATUS'),
                            accessor: 'col5',
                            type: 'status',
                            Link: false
                        },
                        {
                            Header: I18n.t('TABLE_CHANGED'),
                            accessor: 'col6',
                            type: 'changed',
                            Link: false,
                            sortType: updatedSort
                        },
                    ]
                }}
                data={data}
                pageCount={pageCount}
                currentPage={currentPage}
                changePage={(page: number) => changePage(page)}
                confirmDelete={(id: number) => confirmDelete(id)}
            />
            <Snackbar ref={snackBar}/>
        </Wrapper>
    );
}

export default SupplierOrders;