import React, { FC, useEffect, useState } from 'react';
import I18n from '../../../../helpers/i18n';
import EmailIcon from '@mui/icons-material/Email';
import Tooltip from '../../../../components/tooltip';
import InputField from '../../../input';
import Button from '../../../button';
import SettingsIcon from '@mui/icons-material/Settings';
import { Link, useNavigate } from 'react-router-dom';
import DatePicker from "../../../date-picker";
import moment from "moment/moment";
import DirectusManager from "../../../../helpers/directusManager";

interface Props {
    data: any;
    saveConfirmedAmount: (amount: number) => void;
    saveReceivedAmount: (amount: number) => void;
    saveConfirmedFromAmount?: (amount: number) => void;
    orderFactoryToSupplier?: () => void;
    orderToMisi?: () => void;
    confirmOrder: () => void;
    tubes?: boolean;
    factory?: boolean;
    supplier?: boolean;
    alreadySentMail: boolean;
    reloadOrder: () => void;
}

const ProcessInfo: FC<Props> = (props) => {
    const navigate = useNavigate();
    const [confirmedAmount, setConfirmedAmount] = useState(0);
    const [confirmedFromAmount, setConfirmedFromAmount] = useState(0); //factory only
    const [receivedAmount, setReceivedAmount] = useState(0);
    const [sendAmount, setSentAmount] = useState(0);
    const [changedReceivedAmount, setChangedReceivedAmount] = useState(false);
    const [changedSentAmount, setChangedSentAmount] = useState(false);
    const [canConfirmOrder, setCanConfirmOrder] = useState(false);

    const getActionText = () => {
        var text = '';

        if (props.tubes) {
            text = 'Multitubes';
        } else if (props.factory && props.data?.factory?.name) {
            text = props.data?.factory?.name;
        } else if (props.supplier) {
            text = 'Misi';
        } else {
            text = '';
        }

        return I18n.t('ORDER_PROCESS_MAIL') + ' ' + text;
    };

    useEffect(() => {
        if (props.data?.misi_amount_received !== undefined) {
            setReceivedAmount(props.data?.misi_amount_received);
        }
        if (props.data?.mt_amount_received !== undefined) {
            setConfirmedAmount(props.data?.mt_amount_received);
            setSentAmount(props.data?.mt_amount_received);
        }
        if (props.data?.amount_received !== undefined) {
            setSentAmount(props.data?.amount_received);
        }

        if (props.factory) {
            setSentAmount(props.data?.misi_amount_sent);
            setConfirmedFromAmount(props.data?.supplier_amount_received);
            if (props.data !== undefined && props.data?.status?.name == 'order_to_factory') {
                setCanConfirmOrder(true);
            }
        }
        if (props.tubes) {
            if (props.data !== undefined && props.data?.status?.name == 'order_to_multitubes') {
                setCanConfirmOrder(true);
            }
        }
    }, [props.data]);

    const emailTo = () => {
        if (props.tubes) {
            navigate('/orders/tubes/mail?order=' + props.data.tube_order_id);
        }
        if (props.factory) {
            navigate('/orders/factories/mail?order=' + props.data.factory_order_id);
        }
    };

    const supplierAction = () => {
        if (props.orderToMisi) {
            props.orderToMisi();
        }
        if (props.orderFactoryToSupplier) {
            props.orderFactoryToSupplier();
        }
    };

    const checkIfHasProducts = () => {
        var hasProducts = false;
        if (props.tubes && props.data?.to_product_id?.length > 0) {
            hasProducts = true;
        } else if (props.factory && props.data?.fo_product_id?.length > 0) {
            hasProducts = true;
        } else if (props.supplier && props.data?.so_product_id?.length > 0) {
            hasProducts = true;
        }
        return hasProducts;
    };

    const checkReceivedDisabled = () => {
        if (props.tubes) {
            return props.data?.status?.name !== 'order_to_misi';
        } else if (props.factory) {
            return props.data?.status?.name !== 'order_to_supplier';
        } else {
            return props.data?.status?.name === 'open' ? true : false;
        }
    };

    const changeConfirmedExpectedDate = (date: any) => {
        var formatDate = moment(date).format('YYYY-MM-DD');

        if (!props.factory) {
            return;
        }

        DirectusManager.shared.directus.items('factory_order')
            .updateOne(props.data.factory_order_id, { confirmed_expected_delivery_date: new Date(formatDate) })
            .then((data) => {
                props.reloadOrder();
            })
            .catch((error: any) => {
                alert(error);
            });
    };

    const getConfirmedExpectedDate = () => {
        if (props.data.confirmed_expected_delivery_date) {
            return new Date(props.data.confirmed_expected_delivery_date);
        }

        return undefined;
    };

    return (
        <div className="table-wrapper detail process">
            <div className="table-header filled d-flex justify-content-between align-items-center">
                <span className="title">{I18n.t('PROCESS_INFO')}</span>
            </div>
            <table className="table">
                <thead></thead>
                <tbody>
                <tr>
                    {(props.factory || props.tubes) && (
                        <td colSpan={2}>
                            <div className="d-flex align-items-center">
                                {getActionText()}
                                {checkIfHasProducts() ? (
                                    props.data?.status?.name == 'archived' ||
                                    props.data?.status?.name == 'cancelled' ? (
                                        <Tooltip text={I18n.t('NEW_MAIL_DISABLED')}>
                                            <EmailIcon className="email-icon disabled"/>
                                        </Tooltip>
                                    ) : props.data.order_email_sent == true ? (
                                        <Tooltip text={I18n.t('NEW_MAIL_ALREADY_SENT')}>
                                            <EmailIcon className="email-icon disabled"/>
                                        </Tooltip>
                                    ) : (
                                        <Tooltip text={I18n.t('NEW_MAIL')}>
                                            <EmailIcon className="email-icon" onClick={() => emailTo()}/>
                                        </Tooltip>
                                    )
                                ) : (
                                    <Tooltip text={I18n.t('EMAIL_NO_PRODUCTS')}>
                                        <EmailIcon className="email-icon disabled"/>
                                    </Tooltip>
                                )}
                            </div>
                        </td>
                    )}
                    {props.supplier && (
                        <td colSpan={2}>
                            <div className="d-flex align-items-center">
                                {getActionText()}
                                {checkIfHasProducts() ? (
                                    <Tooltip text={I18n.t('SUPPLIER_SETTING')}>
                                        {props.data.order_to_supplier == false ||
                                        props.data.order_to_supplier == null ? (
                                            <SettingsIcon
                                                className="settings-icon"
                                                onClick={() => supplierAction()}
                                            />
                                        ) : (
                                            <SettingsIcon className="settings-icon disabled"/>
                                        )}
                                    </Tooltip>
                                ) : (
                                    <Tooltip text={I18n.t('SUPPLIER_SETTING_NO_PRODUCTS')}>
                                        <SettingsIcon className="settings-icon disabled"/>
                                    </Tooltip>
                                )}
                            </div>
                        </td>
                    )}
                </tr>
                {(props.tubes || props.factory) && (
                    <tr>
                        <td>
                            {props.factory
                                ? I18n.t('ORDER_CONFIRMED_BY') + props.data?.factory?.name
                                : I18n.t('ORDER_CONFIRMED_BY') + 'Multitubes'}
                        </td>
                        <td className="td-inner">
                            <Button
                                defaultStyle
                                title={I18n.t('ORDER_CONFIRM')}
                                disabled={canConfirmOrder ? false : true}
                                onPress={() => {
                                    props.confirmOrder();
                                    setCanConfirmOrder(false);
                                }}
                            />
                        </td>
                    </tr>
                )}
                {(props.factory) && (
                    <tr>
                        <td className="td-heading">{I18n.t('TABLE_CONFIRMED_EXPECTED_DELIVERY')}</td>
                        <td className="detail-date-picker">
                            <DatePicker
                                showSingleValue={
                                    props.data.confirmed_expected_delivery_date !== null
                                        ? moment(props.data.confirmed_expected_delivery_date).format('DD-MM-YYYY')
                                        : false
                                }
                                filterDateStart={
                                    props.data.confirmed_expected_delivery_date !== null
                                        ? props.data.confirmed_expected_delivery_date
                                        : false
                                }
                                changedDateStart={(date: any) => changeConfirmedExpectedDate(date)}
                                minDate={new Date()}
                                shownDate={getConfirmedExpectedDate()}
                            />
                        </td>
                    </tr>
                )}
                <div className="empty-space"></div>
                <tr>
                    <td>
                        {props.tubes
                            ? I18n.t('ORDER_TUBES_CONFIRM_AMOUNT')
                            : props.factory
                                ? props.data?.factory?.name
                                    ? I18n.t('ORDER_FACTORY_CONFIRM_AMOUNT') + ' ' + props.data?.factory?.name
                                    : I18n.t('ORDER_FACTORY_CONFIRM_AMOUNT')
                                : props.data?.supplier_id?.company
                                    ? I18n.t('ORDER_SUPPLIER_CONFIRM_AMOUNT') + ' ' + props.data?.supplier_id?.company
                                    : I18n.t('ORDER_SUPPLIER_CONFIRM_AMOUNT')}
                    </td>
                    <td className="td-inner">
                        <div className="d-flex">
                            <InputField
                                disabled={
                                    (props.tubes && props.data?.status?.name !== 'confirmed_by_multitubes') ||
                                    (props.factory && props.data?.status?.name !== 'confirmed_by_factory') ||
                                    (props.supplier && props.data?.status?.name !== 'order_to_misi')
                                        ? true
                                        : false
                                }
                                disableActiveLabel
                                value={sendAmount}
                                onChange={(value: any) => {
                                    setSentAmount(value);
                                    setChangedSentAmount(true);
                                }}
                                numeric={true}
                            />
                            <div className="space"/>
                            <Button
                                defaultStyle
                                title={I18n.t('SAVE')}
                                disabled={changedSentAmount ? false : true}
                                onPress={() => {
                                    props.saveConfirmedAmount(sendAmount);
                                    setChangedSentAmount(false);
                                }}
                            />
                        </div>
                    </td>
                </tr>
                {props.tubes && (
                    <tr>
                        <td>{I18n.t('ORDER_TUBES_RECEIVED_AMOUNT')}</td>
                        <td className="td-inner">
                            <div className="d-flex">
                                <InputField
                                    disabled={checkReceivedDisabled()}
                                    disableActiveLabel
                                    value={receivedAmount}
                                    onChange={(value: any) => {
                                        if (props.data?.status?.name === 'open') {
                                            setReceivedAmount(0);
                                        }
                                        setReceivedAmount(value);
                                        setChangedReceivedAmount(true);
                                    }}
                                    numeric={true}
                                />
                                <div className="space"/>
                                {props.data.order_email_sent == true && (
                                    <Button
                                        defaultStyle
                                        disabled={changedReceivedAmount ? false : true}
                                        title={I18n.t('SAVE')}
                                        onPress={() => {
                                            props.saveReceivedAmount(receivedAmount);
                                            setChangedReceivedAmount(false);
                                        }}
                                    />
                                )}
                            </div>
                        </td>
                    </tr>
                )}
                </tbody>
            </table>
        </div>
    );
};
export default ProcessInfo;
