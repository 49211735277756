import React, { useState, FC, useEffect, useRef } from 'react';
import Wrapper from '../../components/wrapper';
import I18n from '../../helpers/i18n';

import Table from '../../components/tables/detail-rows-double-col';
import DirectusManager from '../../helpers/directusManager';
import Snackbar from '../../components/snackbar';
import { Link, useNavigate } from 'react-router-dom';

/**
 *
 * The product new view allows users to create a new product in the system. There's a few fields that are mandatory for creation, being those: articleCode, articleName, articleGroup, ean, priceSupplier, priceRetail, factory and factoryCode
 */

const ProductNew: FC = (props) => {
    const navigate = useNavigate();
    const snackBar = useRef();

    const [articleGroups, setArticleGroups] = useState<any>([{}]);
    const [factories, setFactories] = useState<any>([{}]);
    const [statuses, setStatuses] = useState<any>([]);

    //leftrows
    const [articleCode, setArticleCode] = useState('');
    const [articleCodeError, setArticleCodeError] = useState(false);
    const [articleCodeErrorText, setArticleCodeErrorText] = useState('');

    const [articleName, setArticleName] = useState('');
    const [articleNameError, setArticleNameError] = useState(false);
    const [articleNameErrorText, setArticleNameErrorText] = useState('');

    const [articleGroup, setArticleGroup] = useState(0);
    const [articleGroupError, setArticleGroupError] = useState(false);
    const [articleGroupErrorText, setArticleGroupErrorText] = useState('');

    const [ean, setEan] = useState('');
    const [eanError, setEanError] = useState(false);
    const [eanErrorText, setEanErrorText] = useState('');

    const [eanMasterbox, setEanMasterbox] = useState('');
    const [priceSupplier, setPriceSupplier] = useState<any>('');
    const [priceSupplierError, setPriceSupplierError] = useState(false);
    const [priceSupplierErrorText, setPriceSupplierErrorText] = useState('');
    const [priceRetail, setPriceRetail] = useState<any>('');
    const [priceRetailError, setPriceRetailError] = useState(false);
    const [priceRetailErrorText, setPriceRetailErrorText] = useState('');
    const [priceFactory, setPriceFactory] = useState<any>('');
    const [priceFactoryError, setPriceFactoryError] = useState(false);
    const [priceFactoryErrorText, setPriceFactoryErrorText] = useState('');
    const [factory, setFactory] = useState(-1);
    const [factoryError, setFactoryError] = useState(false);
    const [factoryErrorText, setFactoryErrorText] = useState('');

    const [factoryCode, setFactoryCode] = useState('');
    const [factoryCodeError, setFactoryCodeError] = useState(false);
    const [factoryCodeErrorText, setFactoryCodeErrorText] = useState('');

    const [minimumAmount, setMinimumAmount] = useState(0);
    const [lowStockNotice, setLowStockNotice] = useState(0);
    const [status, setStatus] = useState(0);
    const [type, setType] = useState('final-product');
    const [types, setTypes] = useState<any>([]);
    const [country, setCountry] = useState('');
    const [countries, setCountries] = useState<{ id: string; name: string; }[]>([]);

    //rightrows
    const [layers, setLayers] = useState('');
    const [material, setMaterial] = useState('');
    const [capType, setCapType] = useState('');
    const [orifice, setOrifice] = useState('');
    const [sizes, setSizes] = useState('');
    const [packagingCodeMT, setPackagingCodeMT] = useState('');
    const [packagingCodeMTError, setPackagingCodeMTError] = useState(false);
    const [packagingCodeMTErrorText, setPackagingCodeMTErrorText] = useState('');

    const [eanMasterboxMT, setEanMasterboxMT] = useState('');
    const [amountMasterbox, setAmountMasterbox] = useState(0);
    const [extra, setExtra] = useState('');
    const [fileToUpload, setFileToUpload] = useState<any>(null);

    useEffect(() => {
        let sortGroup: any = ['name'];

        DirectusManager.shared.directus.items('product_group')
            .readMany({ limit: -1, sort: sortGroup, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.group_id,
                        name: x.name,
                    }));
                    setArticleGroups(mapped);
                }
            })
            .catch((error) => {
                setArticleGroups([]);
            });
        let sortFactories: any = ['name'];

        DirectusManager.shared.directus.items('factories')
            .readMany({ limit: -1, sort: sortFactories, filter: { status: 'active' } })
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.id,
                        name: x.name,
                    }));
                    setFactories(mapped);
                }
            })
            .catch((error) => {
                setFactories([]);
            });

        setStatuses([
            {
                id: 1,
                name: 'Active',
            },
            {
                id: 2,
                name: 'Inactive',
            },
        ]);

        setTypes([
            {
                id: 'final-product',
                name: I18n.t('TYPE_END'),
            },
            {
                id: 'semi-finished',
                name: I18n.t('TYPE_HALF'),
            },
        ]);

        DirectusManager.shared.directus.items('countries')
            .readMany({ limit: -1, filter: {}})
            .then((data) => {
                if (data !== undefined && data.data !== undefined && data.data !== null) {
                    let mapped = data.data.map((x: any) => ({
                        id: x.uuid,
                        name: x.name,
                    }));
                    setCountries(mapped);
                    if (mapped.length > 0) {
                        setCountry(mapped[0].id);
                    }
                }
            })
            .catch((error) => {
                setCountries([]);
            });
    }, []);

    /**
     * On save, we validate wether the mandatory fields are set or not. Since some of them come from dropdowns, they will always have a defined value coming from the CMS.
     *
     */
    const save = () => {
        setArticleCodeError(false);
        setArticleGroupError(false);
        setArticleNameError(false);
        setFactoryError(false);
        setFactoryCodeError(false);
        setEanError(false);
        setPackagingCodeMTError(false);
        setPriceRetailError(false);
        setPriceSupplierError(false);
        setPriceFactoryError(false);

        if (articleCode === '') {
            setArticleCodeError(true);
            setArticleCodeErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (articleName === '') {
            setArticleNameError(true);
            setArticleNameErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (factory === -1 || factory === 0) {
            setFactoryError(true);
            setFactoryErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (factoryCode === '') {
            setFactoryCodeError(true);
            setFactoryCodeErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (ean === '') {
            setEanError(true);
            setEanErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (priceRetail === '') {
            setPriceRetailError(true);
            setPriceRetailErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (priceSupplier === '') {
            setPriceSupplierError(true);
            setPriceSupplierErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (priceFactory === '') {
            setPriceFactoryError(true);
            setPriceFactoryErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (articleGroup === 0) {
            setArticleGroupError(true);
            setArticleGroupErrorText(I18n.t('ERROR_REQUIRED'));
        }
        if (
            articleCode !== '' &&
            articleName !== '' &&
            factoryCode !== '' &&
            ean !== '' &&
            priceRetail !== '' &&
            priceSupplier !== '' &&
            priceFactory !== '' &&
            factory !== 0 &&
            factory !== -1 &&
            articleGroup !== 0
        ) {
            const newProduct: any = {
                article_code: articleCode,
                name: articleName,
                product_group: articleGroup,
                EAN: ean,
                EAN_masterbox: eanMasterbox,
                price_supplier: priceSupplier,
                price_retail: priceRetail,
                price_factory: priceFactory,
                factory: factory,
                factory_article_code: factoryCode,
                minimum_order_amount: minimumAmount,
                low_on_stock_amount: lowStockNotice,
                status: status === 0 ? 'active' : 'inactive',
                layers: layers,
                material: material,
                cap_type: capType,
                orifice: orifice,
                sizes: sizes,
                EAN_masterbox_MT: eanMasterboxMT,
                amount_masterbox: amountMasterbox,
                type: type,
                country: country,
            };

            if (packagingCodeMT !== '') {
                newProduct['packaging_code_MT'] = packagingCodeMT;
            }

            DirectusManager.shared.directus.items('products')
                .createOne(newProduct)
                .then((data: any) => {
                    if (data !== null && fileToUpload !== null) {
                        uploadFile(data.id);
                    } else {
                        navigate('/products', { state: 'new' });
                    }
                })
                .catch((error: any) => {
                    // Show different errors based on the feedback of Directus. Non covered errors will show as generic error
                    if (error === undefined) {
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    } else if (
                        error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field === 'article_code'
                    ) {
                        setArticleCodeError(true);
                        setArticleCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_ARTICLE_CODE'));
                    } else if (
                        error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field === 'factory_article_code'
                    ) {
                        setFactoryCodeError(true);
                        setFactoryCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_FACTORY_CODE'));
                    } else if (
                        error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field === '"EAN"'
                    ) {
                        setEanError(true);
                        setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_EAN'));
                    } else if (
                        error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                        error?.errors[0]?.extensions?.field === 'packaging_code_MT'
                    ) {
                        setEanError(true);
                        setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_PACKAGING_CODE'));
                    } else {
                        // @ts-ignore:next-line
                        snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                    }
                });
        }
    };

    /**
     *
     * @param productId ProductId where the file will be uploaded
     * This allows the customer to attach what on the front-end it's nown as Extra info. This can be any kind of file that is useful to support the product.
     */
    const uploadFile = (productId: string) => {
        if (productId === undefined) {
            return;
        }
        const form = new FormData();
        form.append('file', fileToUpload);

        DirectusManager.shared.directus.files
            .createOne(form)
            .then((response) => {
                if (response !== undefined && response !== null && response.id !== undefined) {
                    DirectusManager.shared.directus.items('products')
                        .updateOne(productId, {
                            extra: response.id,
                        })
                        .then((data) => {
                            navigate('/products', { state: 'new' });
                        })
                        .catch((error: any) => {
                            console.log(error);
                            if (
                                error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field === 'article_code'
                            ) {
                                setArticleCodeError(true);
                                setArticleCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_ARTICLE_CODE'));
                            } else if (
                                error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field === 'factory_article_code'
                            ) {
                                setFactoryCodeError(true);
                                setFactoryCodeErrorText(I18n.t('ERROR_NOT_UNIQUE_FACTORY_CODE'));
                            } else if (
                                error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field === '"EAN"'
                            ) {
                                setEanError(true);
                                setEanErrorText(I18n.t('ERROR_NOT_UNIQUE_EAN'));
                            } else if (
                                error?.errors[0]?.extensions?.code === 'RECORD_NOT_UNIQUE' &&
                                error?.errors[0]?.extensions?.field === 'packaging_code_MT'
                            ) {
                                setPackagingCodeMTError(true);
                                setPackagingCodeMTErrorText(I18n.t('ERROR_NOT_UNIQUE_PACKAGING_CODE'));
                            } else {
                                // @ts-ignore:next-line
                                snackBar?.current?.show(I18n.t('ERROR_UNKNOWN'));
                            }
                        });
                }
            })
            .catch((error) => {
                console.log(error);
            });
    };

    const fileSelected = (file: any) => {
        setFileToUpload(file);
    };

    const deleteFile = () => {
        setFileToUpload(null);
    };

    return (
        <Wrapper title={I18n.t('NEW_PRODUCT')}>
            <Table
                title={I18n.t('NEW_PRODUCT_TITLE')}
                save={() => save()}
                largeSize
                saveButtonText={I18n.t('ADD_PRODUCT')}
                fileSelected={(file: any) => fileSelected(file)}
                deleteFile={deleteFile}
                leftRows={[
                    {
                        title: I18n.t('ARTICLE_CODE'),
                        value: articleCode,
                        onChange: (val: any) => setArticleCode(val),
                        input: true,
                        mandatory: true,
                        error: articleCodeError,
                        errorText: articleCodeErrorText,
                    },
                    {
                        title: I18n.t('ARTICLE_NAME'),
                        value: articleName,
                        onChange: (val: any) => setArticleName(val),
                        input: true,
                        mandatory: true,
                        error: articleNameError,
                        errorText: articleNameErrorText,
                    },
                    {
                        title: I18n.t('ARTICLE_GROUP'),
                        value: articleGroup,
                        onChange: (val: any) => setArticleGroup(val),
                        array: articleGroups,
                        select: true,
                        mandatory: true,
                        label: I18n.t('TABLE_SELECT'),
                        emptyMessage: (
                            <span className="empty-select">
                                {I18n.t('EMPTY_SELECT')}{' '}
                                <Link to="/settings/product-groups">{I18n.t('SETTINGS').toLowerCase()}</Link>.
                            </span>
                        ),
                        error: articleGroupError,
                        errorText: articleGroupErrorText,
                    },
                    {
                        title: I18n.t('EAN'),
                        value: ean,
                        onChange: (val: any) => setEan(val),
                        input: true,
                        mandatory: true,
                        error: eanError,
                        errorText: eanErrorText,
                    },
                    {
                        title: I18n.t('EAN_MASTERBOX'),
                        value: eanMasterbox,
                        onChange: (val: any) => setEanMasterbox(val),
                        input: true,
                    },
                    {
                        title: I18n.t('PRICE_SUPPLIERS'),
                        value: '€ ' + priceSupplier,
                        onChange: (val: any) => setPriceSupplier(val.replaceAll('€ ', '').replace(/[^\d.-]/g, '')),
                        input: true,
                        price: true,
                        number: false,
                        mandatory: true,
                        error: priceSupplierError,
                        errorText: priceSupplierErrorText,
                    },
                    {
                        title: I18n.t('PRICE_RETAIL'),
                        value: '€ ' + priceRetail,
                        onChange: (val: any) => setPriceRetail(val.replaceAll('€ ', '').replace(/[^\d.-]/g, '')),
                        input: true,
                        price: true,
                        number: false,
                        mandatory: true,
                        error: priceRetailError,
                        errorText: priceRetailErrorText,
                    },
                    {
                        title: I18n.t('PRICE_FACTORY'),
                        value: '€ ' + priceFactory,
                        onChange: (val: any) => setPriceFactory(val.replaceAll('€ ', '').replace(/[^\d.-]/g, '')),
                        input: true,
                        price: true,
                        number: false,
                        mandatory: true,
                        error: priceFactoryError,
                        errorText: priceFactoryErrorText,
                    },
                    {},
                    {
                        title: I18n.t('TABLE_FACTORY'),
                        value: factory,
                        onChange: (val: any) => setFactory(val),
                        array: factories,
                        select: true,
                        mandatory: true,
                        label: I18n.t('TABLE_SELECT'),
                        emptyMessage: (
                            <span className="empty-select">
                                {I18n.t('EMPTY_SELECT')}{' '}
                                <Link to="/settings/factories">{I18n.t('SETTINGS').toLowerCase()}</Link>.
                            </span>
                        ),
                        error: factoryError,
                        errorText: factoryErrorText,
                    },
                    {
                        title: I18n.t('FACTORY_CODE'),
                        value: factoryCode,
                        onChange: (val: any) => setFactoryCode(val),
                        input: true,
                        mandatory: true,
                        error: factoryCodeError,
                        errorText: factoryCodeErrorText,
                    },
                    {},
                    {
                        title: I18n.t('MINIMUM_ORDER_QUANTITY'),
                        value: minimumAmount,
                        onChange: (val: any) => setMinimumAmount(val),
                        input: true,
                        number: true,
                    },
                    {
                        title: I18n.t('LOW_ON_STOCK_NOTICE'),
                        value: lowStockNotice,
                        onChange: (val: any) => setLowStockNotice(val),
                        input: true,
                    },
                    {
                        title: I18n.t('TABLE_STATUS'),
                        value: status,
                        onChange: (val: any) => setStatus(val),
                        array: statuses,
                        select: true,
                    },
                    {
                        title: I18n.t('TABLE_TYPE'),
                        value: type,
                        onChange: (val: any) => setType(val),
                        array: types,
                        select: true,
                    },
                    {
                        title: I18n.t('TABLE_COUNTRY'),
                        value: country,
                        onChange: (val: any) => setCountry(val),
                        array: countries,
                        select: true,
                    },
                ]}
                rightRows={[
                    {
                        title: I18n.t('LAYERS'),
                        value: layers,
                        onChange: (val: any) => setLayers(val),
                        input: true,
                    },
                    {
                        title: I18n.t('MATERIAL'),
                        value: material,
                        onChange: (val: any) => setMaterial(val),
                        input: true,
                    },
                    {
                        title: I18n.t('CAP_TYPE'),
                        value: capType,
                        onChange: (val: any) => setCapType(val),
                        input: true,
                    },
                    {
                        title: I18n.t('ORIFICE'),
                        value: orifice,
                        onChange: (val: any) => setOrifice(val),
                        input: true,
                    },
                    {
                        title: I18n.t('SIZES'),
                        value: sizes,
                        onChange: (val: any) => setSizes(val),
                        input: true,
                    },
                    {},
                    {
                        title: I18n.t('PACKAGING_CODE_MT'),
                        value: packagingCodeMT,
                        onChange: (val: any) => setPackagingCodeMT(val),
                        input: true,
                        error: packagingCodeMTError,
                        errorText: packagingCodeMTErrorText,
                        mandatory: false,
                    },
                    {
                        title: I18n.t('EAN_MASTERBOX_MT'),
                        value: eanMasterboxMT,
                        onChange: (val: any) => setEanMasterboxMT(val),
                        input: true,
                    },
                    {
                        title: I18n.t('AMOUNT_MASTERBOX'),
                        value: amountMasterbox,
                        onChange: (val: any) => setAmountMasterbox(val),
                        input: true,
                        number: true,
                    },
                    {},
                    {
                        title: I18n.t('EXTRA_INFO'),
                        value: extra,
                        onChange: (val: any) => setExtra(val),
                        upload: true,
                    },
                    {},
                ]}
            />
            <Snackbar ref={snackBar} />
        </Wrapper>
    );
};

export default ProductNew;
